import React, { useState, useEffect } from 'react'
import useStyles from 'views/Admin/PracticeTerms/styles'
import {
  Box,
  Grid,
  CssBaseline,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import TextEditor from 'components/TextEditor'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPracticeTerms,
  postPracticeTerms,
  updatePracticeTerms,
} from 'api-client/PracticeTermsData/thunks'
import { constants } from 'utils/constants'
import { toast } from 'react-toastify'

const PracticeTerms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.currentUserState)
  const { data, status, isSubmitting } = useSelector(
    (state) => state.practiceTermsDataState
  )

  const [text, setText] = useState(data)

  useEffect(() => {
    dispatch(fetchPracticeTerms(token))
  }, [])

  useEffect(() => {
    setText(data)
  }, [data])

  const handleChange = (value) => {
    setText(value)
  }

  const handleAdd = () => {
    dispatch(postPracticeTerms([token, { practice_terms: text }]))
  }

  const handleSave = () => {
    if (text === data) {
      toast.warning('Nothing was changed.')
    } else dispatch(updatePracticeTerms([token, { practice_terms: text }]))
  }

  return (
    <>
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={{ m: '2rem 3rem' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <CssBaseline />

            <Grid item xs={12}>
              <Typography variant="h3" className={classes.rootHeading}>
                Practice Terms
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextEditor text={text} handleChange={handleChange} type="" />
            </Grid>
            <Grid item xs={12}>
              {data ? (
                <Button
                  variant="contained"
                  onClick={handleSave}
                  color="secondary"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  color="secondary"
                  className={classes.button}
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default PracticeTerms
