import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import SignIn from 'views/SignIn'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import { loggedInUser } from 'store/CurrentUser'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

const ProtectedRoute = ({ component: Component, userRole, ...restOfProps }) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => loggedInUser(state))
  if (user) {
    const timeLeft = moment.duration(moment().diff(user.tokenCreatedAt))
    if (timeLeft.asDays() > 1) dispatch(logoutUser()) //logout after one day
    else {
      //logout automatically after 3 hours of inactivity
      setTimeout(() => dispatch(logoutUser()), 3 * 60 * 60 * 1000)
    }
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user && user.role === userRole ? <Component {...props} /> : <SignIn />
      }
    />
  )
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  userRole: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
}
