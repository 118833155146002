import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchVisitHints,
  updateVisitHints,
} from 'api-client/VisitHintsData/thunks'

const initialState = {
  providerVisitHints: '',
  visitHints: '',
  status: constants.idle,
  isSubmitting: false,
  error: null,
}

const visitHintsDataSlice = createSlice({
  name: 'visitHintsData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVisitHints.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchVisitHints.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.visitHints = action.payload.data.visit_hints
      if (action.payload.data.provider_visit_hints)
        state.providerVisitHints = action.payload.data.provider_visit_hints
      state.error = null
    },
    [fetchVisitHints.rejected]: (state, action) => {
      state.status = constants.failed
      state.visitHints = ''
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
        toast.error(state.error)
      } else {
        state.error = action.payload.message
        toast.error(state.error)
      }
    },
    [updateVisitHints.pending]: (state) => {
      state.isSubmitting = true
    },
    [updateVisitHints.fulfilled]: (state, action) => {
      if (state.providerVisitHints)
        state.providerVisitHints = action.meta.arg[1]['visit_hints']
      else state.visitHints = action.meta.arg[1]['visit_hints']
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Visit Hints Updated')
    },
    [updateVisitHints.rejected]: (state, action) => {
      state.status = constants.failed
      state.isSubmitting = false
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.visitHints = ''
      state.providerVisitHints = ''
      state.status = constants.idle
      state.isSubmitting = false
      state.error = null
    },
  },
})

export default visitHintsDataSlice.reducer
