import { object, date } from 'yup'
import moment from 'moment'

export const dateRangeFormSchema = object().shape({
  startDate: date()
    .typeError('Invalid Start Date')
    .label('Start Date')
    .required(),
  endDate: date()
    .typeError('Invalid End Date')
    .label('End Date')
    .test(
      'endDate should after startDate check',
      'End Date must be after the Start Date',
      function (value) {
        const startDate = moment(this.parent.startDate)
        const endDate = moment(value)
        return endDate.isSameOrAfter(startDate, 'date')
      }
    ),
})
