import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  addButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  loader: {
    marginTop: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
})

export default useStyles
