import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loader: {
    color: 'black',
    margin: '0 0 0 10px',
  },
  pageLoader: {
    marginTop: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
