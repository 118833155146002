import { Button, Box, TextField, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { constants } from 'utils/constants'
import useStyles from 'views/ForgotPassword/styles'

const Form = (props) => {
  const {
    values: { email },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const classes = useStyles()
  const { status } = useSelector((state) => state.passwordAPIsDataState)

  return (
    <>
      <TextField
        margin="normal"
        sx={{ mt: 3, mb: 1, width: '100%' }}
        required
        id="email"
        label="Email"
        type="email"
        name="email"
        helperText={touched.email ? errors.email : ''}
        error={touched.email && Boolean(errors.email)}
        value={email}
        onChange={change.bind(null, 'email')}
      />

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, width: '100%', positive: 'absolute' }}
        disabled={!isValid}
      >
        Send Password Reset Email
        {status === constants.loading ? (
          <Box>
            <CircularProgress className={classes.loader} size={15} />
          </Box>
        ) : null}
      </Button>
    </>
  )
}

Form.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
}

export default Form
