import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import FormikTextField from 'components/FormikTextField'
import useStyles from 'views/Provider/Profile/Forms/PersonalDetails/styles'
import {
  GENDERS,
  PRACTICELOCATIONS,
} from 'utils/constants/selectFieldConstants'
import { loggedInUserToken } from 'store/CurrentUser'
import { updateProvider } from 'api-client/ProvidersData/thunks'
import { getStatesList } from 'store/StatesData'
import { fetchStates } from 'api-client/StatesData/thunks'
import { statesMap } from 'utils/constants/states'
import { getProvider } from 'store/ProvidersData'
import { personalDetailsSchema } from 'schema/PersonalDetailsForm'
import { sortBy } from 'lodash'

const PersonalDetailsForm = ({ isOpenModal, handleCloseModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const statesList = useSelector((state) => getStatesList(state))
  const provider = useSelector((state) => getProvider(state))
  const token = useSelector((state) => loggedInUserToken(state))

  const mappedState = statesMap[`${provider['Addresses'][0]?.state}`]
  const updatedMappedState =
    mappedState && `${mappedState}, ${provider['Addresses'][0]?.state}`

  useEffect(() => {
    if (!statesList.length) {
      dispatch(fetchStates(token))
    }
  }, [])

  const initialValues = {
    first_name: provider?.first_name ?? '',
    last_name: provider?.last_name ?? '',
    gender: provider?.gender ?? '',
    date_of_birth: moment(provider?.date_of_birth) ?? '',
    address_type: provider['Addresses'][0]?.address_type ?? '',
    phone_number: provider?.phone_number ?? '',
    address_line: provider['Addresses'][0]?.address_line ?? '',
    address_line_2: provider['Addresses'][0]?.address_line_2 ?? '',
    city: provider['Addresses'][0]?.city ?? '',
    state: updatedMappedState ?? '',
    zip_code: provider['Addresses'][0]?.zip_code ?? '',
  }

  const handleSubmit = (values) => {
    const updatedState = values.state.split(',')
    const updatedDateOfBirth = moment(values.date_of_birth).format('YYYY-MM-DD')
    const updatedValues = { ...values }
    updatedValues.state = updatedState[1].trim()
    updatedValues.date_of_birth = updatedDateOfBirth
    dispatch(updateProvider([token, provider.id, updatedValues]))
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        Edit Personal Information
        <Typography variant="body2">
          Note: Selecting Home hides your address and phone from your profile
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.form}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={personalDetailsSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="first_name"
                      label="First Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="last_name"
                      label="Last Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="gender"
                      label="Gender"
                      select={true}
                      accessor="value"
                      items={GENDERS}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Date Of Birth"
                        disableFuture={true}
                        value={moment(values.date_of_birth)}
                        onChange={(value) => {
                          setFieldValue('date_of_birth', value)
                        }}
                        inputProps={{ readOnly: true }}
                        renderInput={(params) => (
                          <FormikTextField
                            className={classes.field}
                            name="date_of_birth"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="address_type"
                      label="Practice Location"
                      select={true}
                      accessor="value"
                      items={PRACTICELOCATIONS}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="phone_number"
                      label={
                        values.address_type === 'office'
                          ? 'Office Phone No'
                          : 'Home Phone No'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      className={classes.field}
                      name="address_line"
                      label={
                        values.address_type === 'office'
                          ? 'Office Address'
                          : 'Home Address'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      className={classes.field}
                      name="address_line_2"
                      label={
                        values.address_type === 'office'
                          ? 'Office Address - Line 2'
                          : 'Home Address - Line 2'
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikTextField
                      className={classes.field}
                      name="city"
                      label="City"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="state"
                      label="State"
                      select={true}
                      accessor="name"
                      accessor2="code"
                      items={sortBy(statesList, (e) => e.name.toLowerCase())}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      className={classes.field}
                      name="zip_code"
                      label="Zip Code"
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PersonalDetailsForm

PersonalDetailsForm.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}
