import moment from 'moment/moment'
import { dateFormat, WEB_TIME_FORMAT } from 'utils/constants'
import { style } from 'views/Provider/ScheduleExceptions/styles'
import { Chip } from '@mui/material'
import React from 'react'

export const COLUMNS = [
  {
    Header: 'Exception Date',
    accessor: 'date',
    Cell: (row) => moment(new Date(row.value)).format(dateFormat),
  },
  {
    disableSortBy: true,
    Header: 'Status',
    accessor: 'is_open',
    Cell: (row) => (
      <Chip
        label={row.value ? 'Open' : 'Closed'}
        size="small"
        sx={row.value ? style.activeChip : style.inActiveChip}
      />
    ),
  },
  {
    disableSortBy: true,
    Header: () => 'Open time',
    accessor: 'open_time',
    Cell: (row) =>
      row.value ? moment(row.value, 'HH:mm:ss').format(WEB_TIME_FORMAT) : 'N/A',
  },
  {
    disableSortBy: true,
    Header: 'Close time',
    accessor: 'close_time',
    Cell: (row) =>
      row.value ? moment(row.value, 'HH:mm:ss').format(WEB_TIME_FORMAT) : 'N/A',
  },
]
