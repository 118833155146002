import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Tooltip,
  CircularProgress,
  Card,
  Link,
} from '@mui/material'
import useStyles from 'views/Provider/Profile/styles'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TokenIcon from '@mui/icons-material/Token'
import PersonalDetailsForm from 'views/Provider/Profile/Forms/PersonalDetails'
import ProfessionalDetailsForm from 'views/Provider/Profile/Forms/ProfessionalDetails'
import ProfessionalExperienceForm from 'views/Provider/Profile/Forms/ProfessionalExperience'
import MedicalInterestsForm from 'views/Provider/Profile/Forms/MedicalInterests'
import HobbiesAndPersonalInterestsForm from 'views/Provider/Profile/Forms/HobbiesAndPersonalInterests'
import EducationDetailsForm from 'views/Provider/Profile/Forms/EducationDetails'
import MedicalTrainingsForm from 'views/Provider/Profile/Forms/MedicalTraining'
import { useDispatch, useSelector } from 'react-redux'
import { getProviderDegreeList } from 'store/ProviderDegreeData'
import {
  deleteProviderDegree,
  fetchProviderDegree,
} from 'api-client/ProviderDegreeData/thunks'
import { loggedInUser, loggedInUserToken } from 'store/CurrentUser'
import { getProviderMedicalTrainingList } from 'store/ProviderMedicalTrainingData'
import {
  fetchProviderMedicalTraining,
  deleteProviderMedicalTraining,
} from 'api-client/ProviderMedicalTrainingData/thunks'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import { statesMap } from 'utils/constants/states'
import { fetchSingleProvider } from 'api-client/ProvidersData/thunks'
import ImagesAndVideos from 'views/Provider/Profile/Forms/ImagesAndVideos'
import { getProvider } from 'store/ProvidersData'
import { useHistory, useParams } from 'react-router-dom'
import {
  constants,
  dateFormat,
  RestoreProviderInfoMsg,
  userRolesConstants,
} from 'utils/constants'
import ProfileCard from 'views/Provider/Profile/Forms/ProfileCard'
import { getTrainingProgramList } from 'store/TrainingProgramData'
import { getTrainingBoardStatusList } from 'store/TrainingBoardStatusData'
import NotFound from 'views/NotFound'
import { createPatientDataInCSV, makeVisitsCSV } from 'utils/helperFunctions'
import moment from 'moment'
import SocialLinksForm from 'views/Provider/Profile/Forms/SocialLinks'
import SocialLink from 'components/SocialLink'
import { getProviderVisitsWithAllPatients } from 'api-client/PatientData/thunks'
import { toast } from 'react-toastify'
import DateRangeForm from 'components/DateRangeForm'

const ProviderProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const providerId = params.id
  const currentUser = useSelector((state) => loggedInUser(state))
  const provider = useSelector((state) => getProvider(state))
  const token = useSelector((state) => loggedInUserToken(state))
  const { status } = useSelector((state) => state.providersDataState)
  const providerDegreeList = useSelector((state) =>
    getProviderDegreeList(state)
  )
  const providerMedicalTrainingList = useSelector((state) =>
    getProviderMedicalTrainingList(state)
  )
  const trainingProgramList = useSelector((state) =>
    getTrainingProgramList(state)
  )
  const trainingBoardStatusList = useSelector((state) =>
    getTrainingBoardStatusList(state)
  )
  const socialLinks = provider?.Social_link

  useEffect(() => {
    dispatch(fetchSingleProvider([token, providerId]))
    dispatch(fetchProviderDegree([token, providerId]))
    dispatch(fetchProviderMedicalTraining([token, providerId]))
  }, [providerId])

  const mappedState =
    provider.id && statesMap[`${provider['Provider_state_licences'][0]?.state}`]

  const [degreeId, setDegreeId] = useState('')
  const [trainingId, setTrainingId] = useState('')
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false)
  const [isOpenConfirmDeleteDegreeModal, setIsOpenConfirmDeleteDegreeModal] =
    useState(false)
  const [
    isOpenConfirmDeleteTrainingModal,
    setIsOpenConfirmDeleteTrainingModal,
  ] = useState(false)
  const [isOpenPersonalInfoModal, setIsOpenPersonalInfoModal] = useState(false)
  const [isOpenProfessionalInfoModal, setIsOpenProfessionalInfoModal] =
    useState(false)
  const [isOpenEducationModal, setIsOpenEducationModal] = useState(false)
  const [isOpenMedicalTrainingsModal, setIsOpenMedicalTrainingsModal] =
    useState(false)
  const [
    isOpenProfessionalExperienceModal,
    setIsOpenProfessionalExperienceModal,
  ] = useState(false)
  const [isOpenMedicalInterestsModal, setIsOpenMedicalInterestsModal] =
    useState(false)
  const [
    isOpenHobbiesAndPersonalInterestsModal,
    setIsOpenHobbiesAndPersonalInterestsModal,
  ] = useState(false)

  const [isOpenSocialLinksModal, setIsOpenSocialLinksModal] = useState(false)

  const providerDegreeData = providerDegreeList.find(
    (degree) => degree.id === degreeId
  )
  const providerTrainingData = providerMedicalTrainingList.find(
    (training) => training.id === trainingId
  )

  const DownloadProviderAndPatientsVisits = async ({
    start_date,
    end_date,
  }) => {
    const response = await dispatch(
      getProviderVisitsWithAllPatients({
        providerId,
        start_date,
        end_date,
        token,
      })
    )
    const visitsData = response.payload.data
    if (!visitsData.length) {
      toast.error('No visits found.')
      return
    }
    makeVisitsCSV(visitsData)
  }

  const toggleDateRangeModal = (value) => {
    setOpenDateRangeModal(value)
  }

  const getTrainingProgramTypeById = (id) => {
    const initialTrainingProgram = trainingProgramList.find(
      (training) => training.id === id
    )
    return initialTrainingProgram?.program ?? ''
  }

  const getTrainingBoardStatusById = (id) => {
    const initialTrainingBoardStatus = trainingBoardStatusList.find(
      (boardStatus) => boardStatus.id === id
    )
    return initialTrainingBoardStatus?.status ?? ''
  }

  const handleDeleteTraining = () => {
    dispatch(deleteProviderMedicalTraining([token, provider.id, trainingId]))
  }

  const handleDeleteDegree = () => {
    dispatch(deleteProviderDegree([token, provider.id, degreeId]))
  }

  const handleOpenConfirmDeleteDegreeModal = (id) => {
    setDegreeId(id)
    setIsOpenConfirmDeleteDegreeModal(true)
  }

  const handleCloseConfirmDeleteDegreeModal = () => {
    setDegreeId('')
    setIsOpenConfirmDeleteDegreeModal(false)
  }

  const handleOpenConfirmDeleteTrainingModal = (id) => {
    setTrainingId(id)
    setIsOpenConfirmDeleteTrainingModal(true)
  }

  const handleCloseConfirmDeleteTrainingModal = () => {
    setTrainingId('')
    setIsOpenConfirmDeleteTrainingModal(false)
  }

  const handleOpenPersonalInfoModal = () => {
    setIsOpenPersonalInfoModal(true)
  }

  const handleClosePersonalInfoModal = () => {
    setIsOpenPersonalInfoModal(false)
  }

  const handleOpenProfessionalInfoModal = () => {
    setIsOpenProfessionalInfoModal(true)
  }

  const handleCloseProfessionalInfoModal = () => {
    setIsOpenProfessionalInfoModal(false)
  }

  const handleOpenEducationModal = () => {
    setIsOpenEducationModal(true)
  }

  const handleCloseEducationModal = () => {
    setIsOpenEducationModal(false)
  }

  const handleOpenEducationModalForEdit = (id) => {
    setDegreeId(id)
    setIsOpenEducationModal(true)
  }

  const handleCloseEducationModalForEdit = () => {
    setDegreeId('')
    setIsOpenEducationModal(false)
  }

  const handleOpenMedicalTrainingsModal = () => {
    setIsOpenMedicalTrainingsModal(true)
  }

  const handleCloseMedicalTrainingsModal = () => {
    setIsOpenMedicalTrainingsModal(false)
  }

  const handleOpenMedicalTrainingsModalForEdit = (id) => {
    setTrainingId(id)
    setIsOpenMedicalTrainingsModal(true)
  }

  const handleCloseMedicalTrainingsModalForEdit = () => {
    setTrainingId('')
    setIsOpenMedicalTrainingsModal(false)
  }

  const handleOpenProfessionalExperienceModal = () => {
    setIsOpenProfessionalExperienceModal(true)
  }

  const handleCloseProfessionalExperienceModal = () => {
    setIsOpenProfessionalExperienceModal(false)
  }

  const handleOpenMedicalInterestsModal = () => {
    setIsOpenMedicalInterestsModal(true)
  }

  const handleCloseMedicalInterestsModal = () => {
    setIsOpenMedicalInterestsModal(false)
  }

  const handleOpenHobbiesAndPersonalInterestsModal = () => {
    setIsOpenHobbiesAndPersonalInterestsModal(true)
  }

  const handleCloseHobbiesAndPersonalInterestsModal = () => {
    setIsOpenHobbiesAndPersonalInterestsModal(false)
  }

  const handleOpenSocialLinksModal = () => {
    setIsOpenSocialLinksModal(true)
  }

  const handleCloseSocialLinksModal = () => {
    setIsOpenSocialLinksModal(false)
  }

  if (!Object.keys(provider).length && status === constants.loading)
    return (
      <Box className={classes.loader}>
        <CircularProgress size={60} />
      </Box>
    )

  if (!provider.id) return <NotFound />

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box className={classes.headingItems}>
          {currentUser.role === userRolesConstants.admin && (
            <Tooltip title="Back" placement="left">
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="h5" className={classes.heading}>
            {currentUser.role === userRolesConstants.admin ? 'Provider' : 'My'}{' '}
            Profile
          </Typography>
        </Box>
      </Box>

      <ProfileCard />
      {currentUser.role === userRolesConstants.admin && !provider?.destroyTime && (
        <Card elevation={3} sx={{ p: '0.5rem 1rem ' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Link
              className={classes.clockLink}
              color="secondary"
              onClick={() =>
                history.push(`/admin/providers/${provider.id}/schedule`)
              }
            >
              <AccessTimeIcon fontSize="small" />
              See this provider&apos;s clock
            </Link>

            <Link
              className={classes.clockLink}
              color="secondary"
              onClick={() =>
                history.push(`/admin/providers/${provider.id}/patients`)
              }
            >
              <AccessibleOutlinedIcon fontSize="small" />
              See this provider&apos;s patients
            </Link>

            <Link
              className={classes.clockLink}
              color="secondary"
              onClick={() =>
                history.push(
                  `/admin/providers/${provider.user_id}/registration-tokens`
                )
              }
            >
              <TokenIcon fontSize="small" />
              See this provider&apos;s registration tokens
            </Link>

            <Link
              className={classes.clockLink}
              color="secondary"
              onClick={() => createPatientDataInCSV(token, providerId)}
            >
              <DownloadOutlinedIcon fontSize="small" />
              Download patient&apos;s data in CSV
            </Link>

            <Link
              className={classes.clockLink}
              color="secondary"
              onClick={() => toggleDateRangeModal(true)}
            >
              <DownloadOutlinedIcon fontSize="small" />
              Download patient&apos;s visits in CSV
            </Link>
          </Box>
          <DateRangeForm
            toggleDateRangeModal={toggleDateRangeModal}
            dialogTitle="Select Date Range"
            open={openDateRangeModal}
            handleConfirm={async (startDate, endDate) => {
              await DownloadProviderAndPatientsVisits({
                start_date: moment(startDate).startOf('day').utc().format(),
                end_date: moment(endDate).endOf('day').utc().format(),
              })
            }}
          />
        </Card>
      )}

      <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Personal Information
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenPersonalInfoModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>First Name</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider?.first_name ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Last Name</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider?.last_name ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Gender</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider?.gender ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Date Of Birth</Typography>
            <Typography variant="body2" className={classes.value}>
              {moment(provider?.date_of_birth).format(dateFormat) ??
                'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Phone Number</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider?.phone_number ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Address</Typography>
            {provider['Addresses'].length ? (
              <Typography variant="body2" className={classes.value}>
                {provider['Addresses'][0]?.address_line}
                {', '}
                {provider['Addresses'][0]?.state}
                {', '}
                {provider['Addresses'][0]?.zip_code}
                {', '}
                {provider['Addresses'][0]?.city}
              </Typography>
            ) : (
              <Typography variant="body2" className={classes.value}>
                Not Added.
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Professional Information
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenProfessionalInfoModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Provider Type</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider['Provider_type']?.type ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Speciality</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider['Specialities'][0]?.speciality ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>NPI</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider?.npi ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>DEA</Typography>
            <Typography variant="body2" className={classes.value}>
              {provider['Provider_state_licences'][0]?.dea ?? 'Not Added.'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>
              State Of Licensure
            </Typography>
            {mappedState ? (
              <Typography variant="body2" className={classes.value}>
                {mappedState} (#
                {provider['Provider_state_licences'][0]?.license_number ??
                  'Not Added.'}
                )
              </Typography>
            ) : (
              <Typography variant="body2" className={classes.value}>
                Not Added.
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Professional Experience
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenProfessionalExperienceModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" className={classes.multilineValue}>
            {provider.professional_experience ?? 'Not Added.'}
          </Typography>
        </Grid>

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Medical Interests
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenMedicalInterestsModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" className={classes.multilineValue}>
            {provider.medical_interests ?? 'Not Added.'}
          </Typography>
        </Grid>

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Education Details
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<AddIcon />}
            color="secondary"
            onClick={handleOpenEducationModal}
            disabled={provider.destroyTime}
          >
            Add
          </Button>
        </Grid>

        {!providerDegreeList.length ? (
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.value}>
              Not Added.
            </Typography>
          </Grid>
        ) : (
          providerDegreeList.map((degree) => (
            <Grid item xs={12} key={degree.id}>
              <List sx={{ padding: '0px' }}>
                <ListItem disablePadding>
                  <ListItemText>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '15px' }}
                        >
                          {degree.school}
                          {', '} {degree.state}
                        </Typography>
                        <Typography variant="body2">
                          {degree.degree}
                          {', '}
                          {degree.graduation_year}
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() =>
                              handleOpenEducationModalForEdit(degree.id)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => {
                              handleOpenConfirmDeleteDegreeModal(degree.id)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          ))
        )}

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Medical Trainings
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<AddIcon />}
            color="secondary"
            onClick={handleOpenMedicalTrainingsModal}
            disabled={provider.destroyTime}
          >
            Add
          </Button>
        </Grid>

        {!providerMedicalTrainingList.length ? (
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.value}>
              Not Added.
            </Typography>
          </Grid>
        ) : (
          providerMedicalTrainingList.map((training) => (
            <Grid item xs={12} key={training.id}>
              <List sx={{ padding: '0px' }}>
                <ListItem disablePadding>
                  <ListItemText>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: '15px' }}
                        >
                          {getTrainingProgramTypeById(
                            training.training_program_id
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {training.hospital}
                          {', '} {training.state}
                          {', '} {training.program}
                          {', '} {training.graduation_year}
                        </Typography>
                        <Typography variant="body2">
                          Board Status:{' '}
                          <span>
                            {getTrainingBoardStatusById(
                              training.training_board_status_id
                            )}
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() =>
                              handleOpenMedicalTrainingsModalForEdit(
                                training.id
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() =>
                              handleOpenConfirmDeleteTrainingModal(training.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          ))
        )}

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Hobbies And Personal Interests
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenHobbiesAndPersonalInterestsModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" className={classes.multilineValue}>
            {provider.hobbies ?? 'Not Added.'}
          </Typography>
        </Grid>

        <Grid item xs={11} lg={11} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Web Presence and Social Media
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          className={classes.borderedValue}
          title={provider?.destroyTime && RestoreProviderInfoMsg}
        >
          <Button
            className={classes.editButton}
            startIcon={<EditIcon />}
            color="secondary"
            onClick={handleOpenSocialLinksModal}
            disabled={provider.destroyTime}
          >
            Edit
          </Button>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Website</Typography>
          </Box>

          <Box className={classes.value}>
            {socialLinks?.website_url ? (
              <Link
                variant="body2"
                color="secondary"
                target="_blank"
                href={socialLinks?.website_url}
              >
                {socialLinks?.website_url}
              </Link>
            ) : (
              <Typography variant="body2" className={classes.value}>
                Not Added.
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>
              Make an Appointment
            </Typography>
          </Box>
          <Box className={classes.value}>
            {socialLinks?.appointment_url ? (
              <Link
                variant="body2"
                color="secondary"
                target="_blank"
                href={socialLinks?.appointment_url}
              >
                {socialLinks?.appointment_url}
              </Link>
            ) : (
              <Typography variant="body2" className={classes.value}>
                Not Added.
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box className={classes.singleInfo}>
            <Typography className={classes.label}>Reviews</Typography>
          </Box>
          <Box className={classes.value}>
            {socialLinks?.reviews_url ? (
              <Link
                variant="body2"
                color="secondary"
                target="_blank"
                href={socialLinks?.reviews_url}
              >
                {socialLinks?.reviews_url}
              </Link>
            ) : (
              <Typography variant="body2" className={classes.value}>
                Not Added.
              </Typography>
            )}
          </Box>
        </Grid>

        <SocialLink title="Twitter" url={socialLinks?.twitter_url} />
        <SocialLink title="Facebook" url={socialLinks?.facebook_url} />
        <SocialLink title="Instagram" url={socialLinks?.instagram_url} />
        <SocialLink title="Tik Tok" url={socialLinks?.tiktok_url} />
        <SocialLink title="LinkedIn" url={socialLinks?.linkedin_url} />
        <SocialLink title="Youtube" url={socialLinks?.youtube_url} />

        <Grid item xs={12} md={12} className={classes.borderedValue}>
          <Typography className={classes.sectionHeading} color="secondary">
            Practice Media
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ImagesAndVideos />
        </Grid>
      </Grid>

      <ConfirmDialogBox
        dialogText="Do you really want to delete this Degree?"
        handleConfirm={handleDeleteDegree}
        closeModal={handleCloseConfirmDeleteDegreeModal}
        dialogTitle="Confirm Delete"
        open={isOpenConfirmDeleteDegreeModal}
      />

      <ConfirmDialogBox
        dialogText="Do you really want to delete this Training?"
        handleConfirm={handleDeleteTraining}
        closeModal={handleCloseConfirmDeleteTrainingModal}
        dialogTitle="Confirm Delete"
        open={isOpenConfirmDeleteTrainingModal}
      />

      <PersonalDetailsForm
        handleCloseModal={handleClosePersonalInfoModal}
        isOpenModal={isOpenPersonalInfoModal}
      />

      <ProfessionalDetailsForm
        handleCloseModal={handleCloseProfessionalInfoModal}
        isOpenModal={isOpenProfessionalInfoModal}
      />

      <ProfessionalExperienceForm
        handleCloseModal={handleCloseProfessionalExperienceModal}
        isOpenModal={isOpenProfessionalExperienceModal}
      />

      <MedicalInterestsForm
        handleCloseModal={handleCloseMedicalInterestsModal}
        isOpenModal={isOpenMedicalInterestsModal}
      />

      <HobbiesAndPersonalInterestsForm
        handleCloseModal={handleCloseHobbiesAndPersonalInterestsModal}
        isOpenModal={isOpenHobbiesAndPersonalInterestsModal}
      />

      <SocialLinksForm
        handleCloseModal={handleCloseSocialLinksModal}
        isOpenModal={isOpenSocialLinksModal}
      />

      <EducationDetailsForm
        edit={!!degreeId}
        degreeInitialValues={degreeId ? providerDegreeData : {}}
        handleCloseModal={
          degreeId
            ? handleCloseEducationModalForEdit
            : handleCloseEducationModal
        }
        isOpenModal={isOpenEducationModal}
      />

      <MedicalTrainingsForm
        edit={!!trainingId}
        trainingInitialValues={trainingId ? providerTrainingData : {}}
        handleCloseModal={
          trainingId
            ? handleCloseMedicalTrainingsModalForEdit
            : handleCloseMedicalTrainingsModal
        }
        isOpenModal={isOpenMedicalTrainingsModal}
      />
    </Container>
  )
}

export default ProviderProfile
