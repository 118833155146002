import React from 'react'
import NavDrawer from 'views/NavDrawer'
import { DrawerHeader } from 'components/DrawerHeader'
import { Box } from '@mui/material'
import useStyles from 'views/Admin/Layout/styles'
import { Switch, Route } from 'react-router-dom'
import Speciality from 'views/Admin/Speciality'
import Providers from 'views/Admin/Providers'
import Complaint from 'views/Admin/Complaint'
import PracticeTerms from 'views/Admin/PracticeTerms'
import VisitHints from 'views/Admin/VisitHints'
import Dashboard from 'views/Admin/Dashboard'
import ProviderIcon from 'assets/images/provider-icon-48x48.png'
import ComplaintsIcon from 'assets/images/complaint-icon-48x48.png'
import SpecialityIcon from 'assets/images/speciality-icon-48x48.png'
import DashboardIcon from '@mui/icons-material/Dashboard'
import TermsIcon from 'assets/images/terms-icon.png'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import NotFound from 'views/NotFound'

import ProviderProfile from 'views/Provider/Profile'
import Settings from 'views/Provider/Settings'
import ProviderPatients from 'views/Provider/Patients'
import ResetPassword from 'views/ResetPassword'
import RegistrationTokens from 'views/Admin/RegistrationTokens'

const Layout = () => {
  const classes = useStyles()
  const adminLinkedRoutes = {
    '/admin': [
      'Dashboard',
      <DashboardIcon
        className={classes.darkIcon}
        width="25"
        height="22"
        key={0}
        alt=""
      />,
    ],

    '/admin/providers': [
      'Providers',
      <img
        src={ProviderIcon}
        className={classes.darkIcon}
        width="25"
        height="22"
        key={0}
        alt=""
      />,
    ],
    '/admin/speciality': [
      'Specialities',
      <img
        src={SpecialityIcon}
        className={classes.specialityIcon}
        width="30"
        height="25"
        key={1}
        alt=""
      />,
    ],
    '/admin/complaints': [
      'Complaints',
      <img
        src={ComplaintsIcon}
        className={classes.darkIcon}
        width="25"
        height="22"
        key={2}
        alt=""
      />,
    ],
    '/admin/practiceTerms': [
      'Practice Terms',
      <img
        src={TermsIcon}
        className={classes.darkIcon}
        width="25"
        height="22"
        key={7}
        alt=""
      />,
    ],
    '/admin/visitHints': [
      'Visit Hints',
      <FormatListNumberedIcon
        className={classes.darkIcon}
        width="25"
        height="22"
        key={7}
        alt=""
      />,
    ],
  }
  return (
    <>
      <Box className={classes.mainBox}>
        <NavDrawer routes={adminLinkedRoutes} />
        <Box component="main" className={classes.contentBox}>
          <DrawerHeader />

          <main className={classes.content}>
            <Switch>
              <Route exact path="/admin/" component={Dashboard} />
              <Route path="/admin/complaints" exact component={Complaint} />
              <Route exact path="/admin/speciality" component={Speciality} />
              <Route exact path="/admin/providers" component={Providers} />
              <Route
                exact
                path="/admin/practiceTerms"
                component={PracticeTerms}
              />
              <Route exact path="/admin/visitHints" component={VisitHints} />
              <Route
                exact
                path="/admin/providers/:id/profile"
                component={ProviderProfile}
              />
              <Route
                exact
                path="/admin/providers/:id/schedule"
                component={Settings}
              />
              <Route
                exact
                path="/admin/providers/:id/patients"
                component={ProviderPatients}
              />
              <Route
                exact
                path="/admin/providers/:id/registration-tokens"
                component={RegistrationTokens}
              />
              <Route
                exact
                path="/admin/providers/:id/patients/:userId/registration-tokens"
                component={RegistrationTokens}
              />
              <Route exact path="/admin/settings" component={ResetPassword} />
              <Route path="*" component={NotFound} />
            </Switch>
          </main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
