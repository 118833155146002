import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  field: {
    width: '100%',
  },
  form: {
    marginTop: '6px',
  },
  buttonBox: {
    marginTop: '1.5rem',
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'flex-end',
  },
  saveButton: {
    padding: '8px 3rem',
    borderRadius: '6px',
  },
  cancelButton: {
    padding: '7px 2.5rem',
    borderRadius: '6px',
  },
})

export default useStyles
