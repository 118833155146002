import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import FormikTextField from 'components/FormikTextField'
import useStyles from 'views/Provider/Profile/Forms/ProfessionalDetails/styles'
import { loggedInUserToken } from 'store/CurrentUser'
import { fetchSpecialities } from 'api-client/SpecialityData/thunks'
import { getSpecialityList } from 'store/SpecialityData'
import { getProviderTypeList } from 'store/ProviderTypeData'
import { fetchProviderTypes } from 'api-client/ProviderTypeData/thunks'
import { getStatesList } from 'store/StatesData'
import { fetchStates } from 'api-client/StatesData/thunks'
import { statesMap } from 'utils/constants/states'
import {
  deleteProviderStateLicenses,
  updateProvider,
} from 'api-client/ProvidersData/thunks'
import { getProvider } from 'store/ProvidersData'
import { professionalDetailsSchema } from 'schema/ProfessionalDetailsForm'
import { sortBy } from 'lodash'

const ProfessionalDetailsForm = ({ isOpenModal, handleCloseModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [licState, setLicState] = useState('')
  const provider = useSelector((state) => getProvider(state))
  const token = useSelector((state) => loggedInUserToken(state))
  const specialityList = useSelector((state) => getSpecialityList(state))
  const providerTypesList = useSelector((state) => getProviderTypeList(state))
  const statesList = useSelector((state) => getStatesList(state))
  const sortedStatesList = sortBy(statesList, (e) => e.name.toLowerCase())

  useEffect(() => {
    if (!specialityList.length) {
      dispatch(fetchSpecialities(token))
    }
    if (!providerTypesList.length) {
      dispatch(fetchProviderTypes(token))
    }
    if (!statesList.length) {
      dispatch(fetchStates(token))
    }
  }, [])

  const mappedState =
    statesMap[`${provider['Provider_state_licences'][0]?.state}`]
  const updatedMappedState = mappedState
    ? `${mappedState}, ${provider['Provider_state_licences'][0]?.state}` ?? ''
    : ''

  useEffect(() => {
    setLicState(updatedMappedState)
  }, [])

  const closeModal = () => {
    handleCloseModal()
    setLicState(updatedMappedState)
  }

  const initialValues = {
    provider_type: provider['Provider_type']?.type ?? '',
    npi: provider.npi ?? '',
    speciality: provider['Specialities'][0]?.speciality ?? '',
    state: updatedMappedState,
    dea: provider['Provider_state_licences'][0]?.dea ?? '',
    license_number:
      provider['Provider_state_licences'][0]?.license_number ?? '',
    insurance:
      provider['Provider_state_licences'][0]?.insurance === null
        ? 'None'
        : provider['Provider_state_licences'][0]?.insurance
        ? 'Yes'
        : 'No',
  }

  const handleSubmit = async (values) => {
    await dispatch(
      deleteProviderStateLicenses([
        token,
        provider.id,
        provider['Provider_state_licences'][0]?.id,
      ])
    )
    const updatedState = values.state.split(',')
    const providerType = providerTypesList.find(
      ({ type }) => type === values.provider_type
    )
    const specialityType = specialityList.find(
      ({ speciality }) => speciality === values.speciality
    )

    const updatedValues = {}
    updatedValues.npi = values.npi
    updatedValues.provider_type = providerType.id
    updatedValues.speciality = specialityType.id
    updatedValues.state_licenses = [
      {
        state: updatedState[1].trim(),
        dea: values.dea ? values.dea : null,
        license_number: values.license_number,
        insurance:
          values.insurance === 'None' ? null : values.insurance === 'Yes',
      },
    ]

    dispatch(updateProvider([token, provider.id, updatedValues]))
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={closeModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        Edit Professional Information
      </DialogTitle>

      <DialogContent>
        <Box className={classes.form}>
          <Formik
            enableReinitialize={true}
            validationSchema={professionalDetailsSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormikTextField
                      className={classes.field}
                      name="provider_type"
                      label="Provider Type"
                      select={true}
                      accessor="type"
                      accessor2=""
                      items={sortBy(providerTypesList, (e) =>
                        e.type.toLowerCase()
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="npi"
                      label="NPI"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="speciality"
                      label="Speciality"
                      select={true}
                      accessor="speciality"
                      accessor2=""
                      items={sortBy(specialityList, (e) =>
                        e.speciality.toLowerCase()
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      size="small"
                      className={classes.field}
                      label="State Of Licensure"
                      value={licState}
                      onChange={(e) => {
                        setLicState(e.target.value)
                        setFieldValue('state', e.target.value)
                        e.target.value !== updatedMappedState
                          ? setFieldValue('license_number', '')
                          : null
                      }}
                    >
                      {sortedStatesList.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={`${item.name}, ${item.code}`}
                        >
                          {`${item.name}, ${item.code}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="dea"
                      label={`DEA for ${licState}`}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      className={classes.field}
                      name="license_number"
                      label={`Lic. No for ${licState}`}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      className={classes.field}
                      name="insurance"
                      label="Independent Malpractice Insurance"
                      select={true}
                      accessor="value"
                      accessor2=""
                      items={[
                        { value: 'None' },
                        { value: 'Yes' },
                        { value: 'No' },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ProfessionalDetailsForm

ProfessionalDetailsForm.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}
