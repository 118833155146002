import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  loader: {
    marginTop: '100px',
    marginBottom: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveBtnloader: {
    margin: '0 0 0 10px',
  },
  btnSaveSchedule: {
    margin: '20px 30px 25px 0px',
  },
}))

export default useStyles
