import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchTrainingPrograms,
  postTrainingProgram,
  updateTrainingProgram,
  deleteTrainingProgram,
} from 'api-client/TrainingProgramData/thunks'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}
const trainingProgramDataSlice = createSlice({
  name: 'trainingProgramData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTrainingPrograms.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchTrainingPrograms.fulfilled]: (state, action) => {
      state.error = null
      state.status = constants.succeeded
      state.data = action.payload.data
    },
    [fetchTrainingPrograms.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postTrainingProgram.pending]: (state) => {
      state.status = constants.loading
    },
    [postTrainingProgram.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload.data]
      state.status = constants.succeeded
      state.error = null
      toast.success('Program Added')
    },
    [postTrainingProgram.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateTrainingProgram.pending]: (state) => {
      state.status = constants.loading
    },
    [updateTrainingProgram.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          row.program = action.meta.arg[2]['training_program']
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Program Updated')
    },
    [updateTrainingProgram.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
    [deleteTrainingProgram.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteTrainingProgram.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          return false
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Program Deleted')
    },
    [deleteTrainingProgram.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getTrainingProgramList = (state) =>
  state.trainingProgramDataState.data
export const trainingProgramStatus = (state) =>
  state.trainingProgramDataState.status

export default trainingProgramDataSlice.reducer
