import React from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import FormikTextField from 'components/FormikTextField'
import useStyles from 'views/Provider/Profile/Forms/SocialLinks/styles'
import { loggedInUserToken } from 'store/CurrentUser'
import { updateProvider } from 'api-client/ProvidersData/thunks'
import { getProvider } from 'store/ProvidersData'
import { socialLinksFormSchema } from 'schema/SocialLinksForm'
import { attachHTTPProtocolToLink } from 'utils/helperFunctions'

const SocialLinksForm = ({ isOpenModal, handleCloseModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const provider = useSelector((state) => getProvider(state))
  const token = useSelector((state) => loggedInUserToken(state))

  const socialLinks = provider?.Social_link

  const initialValues = {
    website_url: socialLinks?.website_url ?? '',
    appointment_url: socialLinks?.appointment_url ?? '',
    reviews_url: socialLinks?.reviews_url ?? '',
    twitter_url: socialLinks?.twitter_url ?? '',
    facebook_url: socialLinks?.facebook_url ?? '',
    instagram_url: socialLinks?.instagram_url ?? '',
    tiktok_url: socialLinks?.tiktok_url ?? '',
    linkedin_url: socialLinks?.linkedin_url ?? '',
    youtube_url: socialLinks?.youtube_url ?? '',
  }

  const handleSubmit = (values) => {
    const payload = {
      website_url: attachHTTPProtocolToLink(values.website_url),
      appointment_url: attachHTTPProtocolToLink(values.appointment_url),
      reviews_url: attachHTTPProtocolToLink(values.reviews_url),
      twitter_url: attachHTTPProtocolToLink(values.twitter_url),
      facebook_url: attachHTTPProtocolToLink(values.facebook_url),
      instagram_url: attachHTTPProtocolToLink(values.instagram_url),
      tiktok_url: attachHTTPProtocolToLink(values.tiktok_url),
      linkedin_url: attachHTTPProtocolToLink(values.linkedin_url),
      youtube_url: attachHTTPProtocolToLink(values.youtube_url),
    }

    dispatch(updateProvider([token, provider.id, payload]))
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        Edit web presence and social media
      </DialogTitle>
      <DialogContent>
        <Box className={classes.form}>
          <Formik
            initialValues={initialValues}
            validationSchema={socialLinksFormSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="website_url"
                      label="Website Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="appointment_url"
                      label="Appointment Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="reviews_url"
                      label="Reviews Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="twitter_url"
                      label="Twitter Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="facebook_url"
                      label="Facebook Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="instagram_url"
                      label="Instagram Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="tiktok_url"
                      label="TikTok Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="linkedin_url"
                      label="LinkedIn Url"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="youtube_url"
                      label="Youtube Url"
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SocialLinksForm

SocialLinksForm.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}
