import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  addButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  loader: {
    marginTop: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  search: {
    width: '350px',
    padding: '4px 4px',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid #e0e0e0',
  },
  searchBox: {
    width: '375px',
  },
  searchHeadeer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.3rem',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    padding: '0.6rem',
  },
  totalText: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  total: {
    backgroundColor: '#e0e0e0',
    padding: '0.2rem 0.6rem',
    textAlign: 'center',
    borderRadius: '12px',
    maxWidth: '80px',
    marginLeft: '0.4rem',
    fontWeight: 500,
  },
})

export default useStyles

export const style = {
  inActiveChip: {
    color: '#de4e7b',
    backgroundColor: '#F8ECEE',
    padding: '0.1rem 0.4rem',
  },
  activeChip: {
    color: '#2D9369',
    backgroundColor: '#e8f5e9',
    padding: '0.2rem 0.8rem',
  },
}
