import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from 'views/Provider/Layout'
import ProtectedRoute from 'views/ProtectedRoute'
import AdminLayout from 'views/Admin/Layout'
import SignIn from 'views/SignIn'
import ChangePassword from 'views/ChangePassword'
import ForgotPassword from 'views/ForgotPassword'
import PasswordChangeSuccessPage from 'views/ChangePassword/success'
import PasswordChangeFailPage from 'views/ChangePassword/fail'
import NotFound from 'views/NotFound'

const AppRoutes = () => (
  <Router>
    <Switch>
      <ProtectedRoute path="/admin" userRole="admin" component={AdminLayout} />
      <ProtectedRoute path="/provider" userRole="provider" component={Layout} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route
        path="/change-password/success"
        component={PasswordChangeSuccessPage}
      />
      <Route path="/change-password/fail" component={PasswordChangeFailPage} />
      <Route path="/change-password" component={ChangePassword} />
      <Route exact path="/" component={SignIn} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
)

export default AppRoutes
