import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  pagination: {
    margin: '1rem auto 0',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },

  pageInfo: {
    color: '#a0a3bd',
    fontSize: '0.874em',
    letterSpacing: '0.5px',
  },
  pageButtons: {
    display: 'flex',
  },

  pageBtn: {
    border: '1px solid #a0a3bd',
    color: '#a0a3bd',
    borderRadius: '5px',
    margin: '5px',
    width: '30px',
    height: '30px',
    fontWeight: 'normal',
    fontSize: '15px',
    '&:hover:enabled': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&:disabled': {
      border: '1px solid rgba(0,0,0,0.1)',
    },
  },

  activeBtn: {
    border: '1px solid blue',
    color: 'blue',
    backgroundColor: 'transparent',
  },

  disabledPageBtn: {
    backgroundColor: '#a0a3bd',
    cursor: 'not-allowed',
    opacity: '0.5',
  },
  pagesSpan: {
    marginLeft: '5px',
    fontSize: '15px',
    marginRight: '5px',
  },
  paginationSelectBox: { marginLeft: '5px', height: '35px' },
})

export default useStyles
