import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import { constants } from 'utils/constants'
import {
  fetchProviderMedicalTraining,
  addProviderMedicalTraining,
  deleteProviderMedicalTraining,
  updateProviderMedicalTraining,
} from 'api-client/ProviderMedicalTrainingData/thunks'
import { toast } from 'react-toastify'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const providerMedicalTrainingDataSlice = createSlice({
  name: 'providerMedicalTrainingData',
  initialState,
  extraReducers: {
    [fetchProviderMedicalTraining.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchProviderMedicalTraining.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [fetchProviderMedicalTraining.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [addProviderMedicalTraining.pending]: (state) => {
      state.status = constants.loading
    },
    [addProviderMedicalTraining.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data.push(action.payload.data)
      toast('Training Added Successfully.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
      state.error = null
    },
    [addProviderMedicalTraining.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [updateProviderMedicalTraining.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderMedicalTraining.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      const values = action.meta.arg[3]
      state.data.map((row) => {
        if (row.id === action.meta.arg[2]) {
          row.program = values.program
          row.hospital = values.hospital
          row.state = values.state
          row.graduation_year = values.graduation_year
          row.concentration = values.concentration
          row.training_program_id = values.training_program_id
          row.training_board_status_id = values.training_board_status_id
        }
        return true
      })
      toast('Provider Training Updated.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
      state.error = null
    },
    [updateProviderMedicalTraining.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [deleteProviderMedicalTraining.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteProviderMedicalTraining.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = state.data.filter((row) => row.id !== action.meta.arg[2])
      toast.success('Training Deleted Successfully.')
      state.error = null
    },
    [deleteProviderMedicalTraining.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.data = []
      state.provider = {}
      state.status = constants.idle
      state.error = null
    },
  },
})

export const getProviderMedicalTrainingList = (state) =>
  state.providerMedicalTrainingDataState.data

export default providerMedicalTrainingDataSlice.reducer
