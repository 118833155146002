import { configureStore } from '@reduxjs/toolkit'
import currentUserSlice from 'store/CurrentUser'
import providersDataSlice from 'store/ProvidersData'
import specialityDataSlice from 'store/SpecialityData'
import complaintDataSlice from 'store/ComplaintData'
import allergyDataSlice from 'store/AllergyData'
import medicationDataSlice from 'store/MedicationData'
import providerTypeDataSlice from 'store/ProviderTypeData'
import passwordAPIsDataSlice from 'store/PasswordAPIs'
import trainingBoardStatusDataSlice from 'store/TrainingBoardStatusData'
import trainingProgramDataSlice from 'store/TrainingProgramData'
import dashboardDataSlice from 'store/DashboardData'
import practiceTermsDataSlice from 'store/PracticeTermsData'
import providerPracticeTermsDataSlice from 'store/ProviderPracticeTermsData'
import visitHintsDataSlice from 'store/VisitHintsData'
import providerHoursSlice from 'store/ProviderHours'
import scheduleExceptionsSlice from 'store/ScheduleExceptions'
import statesDataSlice from 'store/StatesData'
import providerDegreeDataSlice from 'store/ProviderDegreeData'
import providerMedicalTrainingDataSlice from 'store/ProviderMedicalTrainingData'
import patientsDataSlice from 'store/PatientData'
import registrationTokensSlice from 'store/RegistrationTokens'

import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  currentUserState: currentUserSlice,
  dashboardDataState: dashboardDataSlice,
  providersDataState: providersDataSlice,
  specialityDataState: specialityDataSlice,
  complaintDataState: complaintDataSlice,
  allergyDataState: allergyDataSlice,
  medicationDataState: medicationDataSlice,
  providerTypeDataState: providerTypeDataSlice,
  passwordAPIsDataState: passwordAPIsDataSlice,
  trainingBoardStatusDataState: trainingBoardStatusDataSlice,
  trainingProgramDataState: trainingProgramDataSlice,
  practiceTermsDataState: practiceTermsDataSlice,
  visitHintsDataState: visitHintsDataSlice,
  providerPracticeTermsDataState: providerPracticeTermsDataSlice,
  providerHoursState: providerHoursSlice,
  scheduleExceptionsState: scheduleExceptionsSlice,
  statesDataState: statesDataSlice,
  providerDegreeDataState: providerDegreeDataSlice,
  providerMedicalTrainingDataState: providerMedicalTrainingDataSlice,
  patientsDataState: patientsDataSlice,
  registrationTokensState: registrationTokensSlice,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})
