import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetchCurrentUser',
  async (userObj, { rejectWithValue }) => {
    try {
      userObj['role'] = 'admin'
      const response = await axios.post(`${baseApiUrl}/login`, userObj)

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const resetCurrentUserPassword = createAsyncThunk(
  'currentUser/resetCurrentUserPassword',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { token, reqData } = requestObj
      const response = await axios.post(
        `${baseApiUrl}/users/changePassword`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const logoutUser = createAsyncThunk(
  'currentUser/logoutUser',
  async ({ rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseApiUrl}/logout`)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
