import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import useStyles from 'components/ConfirmDialogBox/styles'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const ConfirmDialogBox = ({
  closeModal,
  dialogTitle,
  open,
  dialogText,
  handleConfirm,
}) => {
  const classes = useStyles()

  const onConfirm = () => {
    handleConfirm()
    closeModal()
  }

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Dialog
        className={classes.root}
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={onConfirm}>
            Yes
          </Button>
          <Button onClick={closeModal} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

ConfirmDialogBox.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogText: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
}

export default ConfirmDialogBox
