import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import useStyles from 'views/NotFound/styles'
import { useSelector } from 'react-redux'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import { userRolesConstants } from 'utils/constants'

const NotFound = () => {
  const history = useHistory()
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const user = useSelector((state) => loggedInUser(state))

  const classes = useStyles()
  return (
    <Box className={classes.errorBox}>
      <Typography className={classes.errorText}>Page not found</Typography>
      <Typography>This page does not exist.</Typography>
      <Button
        className={classes.gotoButton}
        variant="contained"
        color="secondary"
        onClick={() => history.push('/')}
      >
        Go to{' '}
        {user.role === userRolesConstants.admin ||
        (user.role === userRolesConstants.provider && userInfo.approved_on)
          ? 'Dashboard'
          : 'Profile'}
      </Button>
    </Box>
  )
}

export default NotFound
