import React from 'react'
import AppRoutes from 'views/AppRoutes.js'
import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'react-toastify/dist/ReactToastify.css'
import theme from 'config/theme'

const App = () => (
  <ThemeProvider theme={theme}>
    <AppRoutes />
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </ThemeProvider>
)

export default App
