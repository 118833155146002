import React, { useState, useEffect } from 'react'
import useStyles from 'views/Provider/PracticeTerms/styles'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import TextEditor from 'components/TextEditor'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPracticeTerms,
  updatePracticeTerms,
} from 'api-client/ProviderPracticeTermsData/thunks'
import { fetchPracticeTerms as fetchDefaultPracticeTerms } from 'api-client/PracticeTermsData/thunks'
import { constants } from 'utils/constants'
import DisabledPage from 'views/DisabledPage'
import { PRACTICE_TERMS_TYPE } from 'utils/constants/practiceTermsType'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import { toast } from 'react-toastify'

const PracticeTerms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { token, userInfo } = useSelector((state) => state.currentUserState)
  const { data, isSubmitting } = useSelector(
    (state) => state.providerPracticeTermsDataState
  )
  const { data: defaultPracticeTerms, status } = useSelector(
    (state) => state.practiceTermsDataState
  )

  const [text, setText] = useState(defaultPracticeTerms)
  const [terms, setTerms] = useState(PRACTICE_TERMS_TYPE.DEFAULT)

  useEffect(() => {
    dispatch(fetchPracticeTerms([token, userInfo.id]))
    dispatch(fetchDefaultPracticeTerms(token))
  }, [])

  useEffect(() => {
    if (terms === PRACTICE_TERMS_TYPE.CUSTOMIZED) setText(data)
    if (terms === PRACTICE_TERMS_TYPE.DEFAULT) setText(defaultPracticeTerms)
  }, [data, defaultPracticeTerms])

  const handleChangeText = (value) => {
    setText(value)
  }

  const handleChangeTerms = (e) => {
    setTerms(e.target.value)
    if (e.target.value === PRACTICE_TERMS_TYPE.DEFAULT) {
      setText(defaultPracticeTerms)
    } else {
      setText(data)
    }
  }

  const handleSave = () => {
    if (text === data) {
      toast.warning('Nothing was changed.')
    } else {
      dispatch(
        updatePracticeTerms([token, userInfo.id, { practice_terms: text }])
      )
    }
  }

  const [openEditTerms, setOpenEditTerms] = React.useState(false)

  const openEditTermsDialog = () => {
    setOpenEditTerms(true)
  }
  const closeEditTermsDialog = () => {
    setOpenEditTerms(false)
  }

  const handleEditConfirm = () => {
    setText(defaultPracticeTerms)
    setTerms(PRACTICE_TERMS_TYPE.CUSTOMIZED)
    setOpenEditTerms(false)
  }

  if (!userInfo.approved_on) return <DisabledPage />

  return (
    <>
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={{ m: '2rem 3rem' }}>
          <Typography variant="h3" className={classes.rootHeading}>
            Practice Terms
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TextEditor
              text={text}
              handleChange={handleChangeText}
              type={terms}
            />

            <Box className={classes.actionBox}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={terms}
                  onChange={handleChangeTerms}
                >
                  <FormControlLabel
                    value="default"
                    control={<Radio />}
                    label="Default"
                  />
                  <FormControlLabel
                    value="customized"
                    control={<Radio />}
                    label="Customized"
                  />
                </RadioGroup>
              </FormControl>

              {terms === PRACTICE_TERMS_TYPE.CUSTOMIZED && (
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={openEditTermsDialog}
                    className={classes.copyButton}
                    disabled={isSubmitting}
                  >
                    Copy Default
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    className={classes.saveButton}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <ConfirmDialogBox
            dialogText="Are you sure you want to copy deafult practice terms?"
            handleConfirm={handleEditConfirm}
            closeModal={closeEditTermsDialog}
            dialogTitle="Edit Default Terms"
            open={openEditTerms}
          />
        </Box>
      )}
    </>
  )
}

export default PracticeTerms
