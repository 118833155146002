import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants, SERVER_TIME_FORMAT } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchScheduleExceptions,
  addScheduleException,
  deleteScheduleException,
} from 'api-client/ScheduleExceptions/thunks'
import { sortBy } from 'lodash'
import moment from 'moment'

const initialState = {
  data: null,
  status: constants.idle,
  isSubmitting: false,
  error: null,
}

const scheduleExceptionsSlice = createSlice({
  name: 'scheduleExceptions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchScheduleExceptions.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchScheduleExceptions.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      const response = action.payload.data
      const todayDate = new Date().getDate()
      const updatedExceptions = response.calendar_exceptions.map(
        (exception) => {
          const updatedException = { ...exception }
          updatedException.open_time =
            updatedException.open_time &&
            moment
              .utc(updatedException.open_time, SERVER_TIME_FORMAT)
              .local()
              .set('date', todayDate)

          updatedException.close_time =
            updatedException.close_time &&
            moment
              .utc(updatedException.close_time, SERVER_TIME_FORMAT)
              .local()
              .set('date', todayDate)

          return updatedException
        }
      )
      state.data = action.payload.data
      state.data.calendar_exceptions = updatedExceptions
      state.error = null
    },
    [fetchScheduleExceptions.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
        toast.error(state.error)
      } else {
        state.error = action.payload.message
        toast.error(state.error)
      }
    },

    [addScheduleException.pending]: (state) => {
      state.isSubmitting = true
    },
    [addScheduleException.fulfilled]: (state, action) => {
      const updatedResponse = { ...action.payload.data }
      const todayDate = new Date().getDate()
      updatedResponse.open_time =
        updatedResponse.is_open &&
        moment
          .utc(updatedResponse.open_time, SERVER_TIME_FORMAT)
          .local()
          .set('date', todayDate)

      updatedResponse.close_time =
        updatedResponse.is_open &&
        moment
          .utc(updatedResponse.close_time, SERVER_TIME_FORMAT)
          .local()
          .set('date', todayDate)

      const updatedCalendarExceptions = [
        ...state.data.calendar_exceptions,
        updatedResponse,
      ]
      state.data.calendar_exceptions = sortBy(
        updatedCalendarExceptions,
        (row) => row.date
      )
      state.data.total_elements += 1
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Schedule Exception Added')
    },
    [addScheduleException.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      state.isSubmitting = false
      toast.error(state.error)
    },

    [deleteScheduleException.pending]: (state) => {
      state.isSubmitting = true
    },
    [deleteScheduleException.fulfilled]: (state, action) => {
      const response = action.payload.data
      state.data.calendar_exceptions = state.data.calendar_exceptions.filter(
        (row) => row.id !== response.id
      )
      state.data.total_elements -= 1
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Schedule Exception Deleted')
    },
    [deleteScheduleException.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      state.isSubmitting = false
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.active = {}
      state.past = {}
      state.isSubmitting = false
      state.upcoming = {}
      state.status = constants.idle
      state.error = null
    },
  },
})

export const getProviderExceptions = (state) =>
  state.scheduleExceptionsState.data

export default scheduleExceptionsSlice.reducer
