import moment from 'moment'
import { dateFormat } from 'utils/constants'

export const DEFAULT_COLUMNS = [
  {
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: (row) => moment(new Date(row.value)).format(dateFormat),
  },
  {
    Header: 'Last updated at',
    accessor: 'updatedAt',
    Cell: (row) => moment(new Date(row.value)).format(dateFormat),
  },
]
