import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  clearFilterProviderType,
  getDataList,
  getFilterProviderType,
  getProvider,
  getTotalElements,
  getTotalPages,
} from 'store/ProvidersData'
import { loggedInUserToken } from 'store/CurrentUser'
import {
  fetchProvidersData,
  restoreProvider,
} from 'api-client/ProvidersData/thunks'
import {
  Box,
  Typography,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  InputBase,
  Tooltip,
  IconButton,
  Card,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Chip,
} from '@mui/material'
import Pagination from 'components/Pagination'
import { toast } from 'react-toastify'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import SyncIcon from '@mui/icons-material/Sync'
import useStyles from 'views/Admin/Providers/styles'

import { constants } from 'utils/constants'
import { COLUMNS } from 'views/Admin/Providers/columns'
import BasicTable from 'components/BasicTable'
import { PROVIDER_TYPE_FILTERS } from 'utils/constants/providerTypeFilters'
import DownloadPatientsOfProvider from 'views/Provider/Patients/ManualActions/DownloadPatientsOfProvider'
import PropTypes from 'prop-types'
import ConfirmDialogBox from 'components/ConfirmDialogBox'

const Providers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { status } = useSelector((state) => state.providersDataState)
  const filterProviderType = useSelector((state) =>
    getFilterProviderType(state)
  )
  const [active, setActive] = useState(false)
  const [inActive, setInActive] = useState(false)
  const [approved, setApproved] = useState(
    filterProviderType === PROVIDER_TYPE_FILTERS.APPROVED
  )
  const [unApproved, setUnApproved] = useState(
    filterProviderType === PROVIDER_TYPE_FILTERS.UN_APPROVED
  )
  const token = useSelector((state) => loggedInUserToken(state))

  const data = useSelector((state) => getDataList(state))
  const pages = useSelector((state) => getTotalPages(state))
  const totalElements = useSelector((state) => getTotalElements(state))

  const [currentPage, setCurrentPage] = useState('1')
  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [sortOn, setSortOn] = useState([{ id: 'createdAt', desc: true }])
  const [searchText, setSearchText] = useState('')

  const searchTextHandler = (event) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    toast.dismiss()
    let activeResponse = null
    let approvedResponse = null

    if (active !== inActive) {
      activeResponse = active ? 1 : 0
    }
    if (approved !== unApproved) {
      approvedResponse = approved ? 1 : 0
    }
    const requestBody = [
      token,
      {
        page: currentPage,
        page_size: rowsPerPage,
        sortOn,
        search_provider: searchText,
        is_active: activeResponse,
        is_approved: approvedResponse,
      },
    ]
    dispatch(fetchProvidersData(requestBody))

    return () => dispatch(clearFilterProviderType())
  }, [
    currentPage,
    rowsPerPage,
    sortOn,
    sortOn,
    active,
    inActive,
    approved,
    unApproved,
  ])

  const searchHandler = () => {
    dispatch(
      fetchProvidersData([
        token,
        {
          page: currentPage,
          page_size: rowsPerPage,
          sortOn,
          search_provider: searchText,
        },
      ])
    )
  }
  const handleClearSearch = () => {
    setSearchText('')
    dispatch(
      fetchProvidersData([
        token,
        {
          page: currentPage,
          page_size: rowsPerPage,
          sortOn,
          search_provider: '',
        },
      ])
    )
  }

  const handleClearFilters = () => {
    setActive(false)
    setInActive(false)
    setApproved(false)
    setUnApproved(false)
    dispatch(clearFilterProviderType())
  }

  const onRowClick = (id) => {
    history.push(`/admin/providers/${id}/profile`)
  }

  return (
    <Box sx={{ m: 'auto 1rem' }}>
      <Box className={classes.pageHeader}>
        <Typography className={classes.headerTitle}>Providers</Typography>
      </Box>

      <Card elevation={0} component={Paper}>
        <Box sx={{ p: '1rem 1rem 0.5rem 1rem' }}>
          <Box sx={{ display: 'flex', gap: '1.5rem' }}>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Filter by status
              </Typography>
              <Box>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    }
                    label={<Typography variant="body2">Active</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={inActive}
                        onChange={(e) => setInActive(e.target.checked)}
                      />
                    }
                    label={<Typography variant="body2">In Active</Typography>}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Filter by approval status
              </Typography>
              <Box>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={approved}
                        onChange={(e) => setApproved(e.target.checked)}
                      />
                    }
                    label={<Typography variant="body2">Approved</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={unApproved}
                        onChange={(e) => setUnApproved(e.target.checked)}
                      />
                    }
                    label={<Typography variant="body2">Un Approved</Typography>}
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>
          {active === true ||
          inActive === true ||
          approved === true ||
          unApproved === true ? (
            <Chip
              label="Clear all"
              variant="outlined"
              size="small"
              sx={{ mt: '0.3rem' }}
              onClick={handleClearFilters}
            />
          ) : null}
        </Box>
      </Card>

      <Box className={classes.searchHeadeer}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
            <span className={classes.totalText}> Total </span>
            <span className={classes.total}>
              {totalElements}
              {data.length === 1 ? ' Item' : ' Items'}
            </span>
          </Typography>
        </Box>

        <Paper className={classes.search} elevation={0}>
          <SearchIcon sx={{ margin: '0rem 0.6rem' }} />
          <InputBase
            placeholder="Search by provider name"
            value={searchText}
            className={classes.searchBox}
            onChange={searchTextHandler}
            onKeyUp={searchHandler}
          />
          {searchText && (
            <Tooltip title="Clear" placement="bottom">
              <IconButton onClick={handleClearSearch} aria-label="clear">
                <ClearIcon />
              </IconButton>
            </Tooltip>
          )}
        </Paper>
      </Box>

      <Box>
        {status === constants.loading ? (
          <Box className={classes.loader}>
            <CircularProgress size={60} />
          </Box>
        ) : (
          <>
            <Grid container spacing={4}>
              <CssBaseline />
              <Grid item xs={12}>
                <BasicTable
                  dataList={data}
                  COLUMNS={COLUMNS}
                  hideActions={true}
                  access="provider"
                  setSortOn={setSortOn}
                  initialSort={sortOn}
                  onRowClick={onRowClick}
                  extraStyles={{ cursor: 'pointer' }}
                  showManualAction={true}
                  ManualAction={ManualActions}
                />
              </Grid>
            </Grid>
            {totalElements >= 5 && (
              <Box sx={{ mb: '1.5rem' }}>
                <Pagination
                  pageChangeHandler={setCurrentPage}
                  rowsPerPageHandler={setRowsPerPage}
                  currentPage={parseInt(currentPage)}
                  rowsPerPage={rowsPerPage}
                  totalPages={pages}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Providers

const ManualActions = (props) => {
  const { id, destroyTime } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [openRestoreProviderModal, setOpenRestoreProviderModal] =
    useState(false)
  const token = useSelector((state) => loggedInUserToken(state))
  const provider = useSelector((state) => getProvider(state))

  const handleOpenRestoreProviderDialog = (e) => {
    e.stopPropagation()
    setOpenRestoreProviderModal(true)
  }

  const handleCloseRestoreProviderDialog = (e) => {
    setOpenRestoreProviderModal(false)
    e.stopPropagation()
  }

  const handleRestoreProvider = async (e) => {
    setLoading(true)
    await dispatch(restoreProvider({ token, providerId: provider?.id }))
    handleCloseRestoreProviderDialog(e)
    setLoading(false)
  }

  return (
    <Box>
      <DownloadPatientsOfProvider id={id} />
      {destroyTime && (
        <Tooltip title="Restore Provider">
          <IconButton
            disabled={loading}
            onClick={(e) => handleOpenRestoreProviderDialog(e)}
          >
            <SyncIcon />
          </IconButton>
        </Tooltip>
      )}

      <ConfirmDialogBox
        closeModal={(e) => handleCloseRestoreProviderDialog(e)}
        open={openRestoreProviderModal}
        dialogTitle="Confirm Restore Provider"
        dialogText="Are you sure you want to restore this provider?"
        handleConfirm={(e) => {
          handleRestoreProvider(e)
        }}
      />
    </Box>
  )
}

ManualActions.propTypes = {
  id: PropTypes.string,
  destroyTime: PropTypes.string,
}

ManualActions.defaultProps = {
  id: '',
  destroyTime: null,
}
