import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  addButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  inActiveBtn: {
    padding: '6px 40px',
    backgroundColor: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
    },
  },
  activeBtn: {
    padding: '6px 40px',
    backgroundColor: theme.palette.secondary.main,
  },
  loader: {
    marginTop: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  buttonContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export default useStyles
