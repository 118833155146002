import React from 'react'
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import useStyles from 'components/TableSearchHeader/styles'
import PropTypes from 'prop-types'

const TableSearchHeader = ({
  totalItems,
  searchText,
  searchTextHandler,
  handleClearSearch,
  itemName,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.searchHeadeer}>
      <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
        <span className={classes.totalText}> Total </span>
        <span className={classes.total}>
          {totalItems}
          {totalItems === 1 ? ' Item' : ' Items'}
        </span>
      </Typography>
      <Paper className={classes.search} elevation={0}>
        <SearchIcon sx={{ margin: '0rem 0.6rem' }} />
        <InputBase
          placeholder={`Search by ${itemName}`}
          value={searchText}
          className={classes.searchBox}
          onChange={searchTextHandler}
        />
        {searchText && (
          <Tooltip title="Clear" placement="bottom">
            <IconButton onClick={handleClearSearch} aria-label="clear">
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </Paper>
    </Box>
  )
}

export default TableSearchHeader

TableSearchHeader.propTypes = {
  totalItems: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  searchTextHandler: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
}
