import moment from 'moment'
import * as Yup from 'yup'

export const initialState = {
  date: moment(new Date().setDate(new Date().getDate() + 1)),
  is_open: false,
  open_time: moment().set({ hour: 9, minute: 0, second: 0 }),
  close_time: moment().set({ hour: 17, minute: 0, second: 0 }),
}

export const scheduleExceptionSchema = Yup.object({
  date: Yup.date().label('Date').required('Date is required').nullable(),
  is_open: Yup.bool().label('Is Open flag').required(),
  open_time: Yup.date()
    .label('Open time')
    .when('is_open', {
      is: true,
      then: Yup.date()
        .required('Open Time is required')
        .typeError('Please select time from clock')
        .nullable(),
    }),
  close_time: Yup.date()
    .label('Close time')
    .when('is_open', {
      is: true,
      then: Yup.date()
        .required('Close Time is required')
        .typeError('Please select time from clock')
        .nullable()
        .min(Yup.ref('open_time'), 'Open time should be before close time'),
    }),
})
