import React from 'react'
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  Link,
  Grid,
} from '@mui/material'
import Logo from 'assets/images/LOGO.png'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Formik } from 'formik'
import {
  initialValues,
  validationSchema,
} from 'schema/ForgotPassword.Form.Validation'
import { useDispatch } from 'react-redux'
import { sendForgotPasswordEmail } from 'api-client/PasswordAPIs/thunks'

import Form from 'views/ForgotPassword/Form'

const theme = createTheme()

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const dataObj = {
      email: data.get('email').trim(),
    }
    dispatch(sendForgotPasswordEmail(dataObj))
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: 8, mr: 2 }}
        >
          <img src={Logo} width="200" alt="HealHT LOGO" />
        </Box>

        <Box
          sx={{
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
            >
              {(props) => <Form {...props} />}
            </Formik>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2">
                  Go to Sign In
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default ForgotPassword
