import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchPracticeTerms = createAsyncThunk(
  'practiceTermsData/fetchPracticeTerms',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/practiceTerms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postPracticeTerms = createAsyncThunk(
  'practiceTermsData/postPracticeTerms',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/practiceTerms`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updatePracticeTerms = createAsyncThunk(
  'practiceTermsData/updatePracticeTerms',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/practiceTerms`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
