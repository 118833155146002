import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import FormikTextField from 'components/FormikTextField'
import useStyles from 'views/Provider/Profile/Forms/EducationDetails/styles'
import { YEARS } from 'utils/constants/years'
import {
  addProviderDegree,
  updateProviderDegree,
} from 'api-client/ProviderDegreeData/thunks'
import { loggedInUserToken } from 'store/CurrentUser'
import { fetchStates } from 'api-client/StatesData/thunks'
import { getStatesList } from 'store/StatesData'
import { getProvider } from 'store/ProvidersData'
import { educationDetailsSchema } from 'schema/EducationDetailsForm'
import { sortBy } from 'lodash'
import { statesMap } from 'utils/constants/states'

const EducationDetailsForm = ({
  edit,
  degreeInitialValues,
  isOpenModal,
  handleCloseModal,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const provider = useSelector((state) => getProvider(state))
  const statesList = useSelector((state) => getStatesList(state))
  const token = useSelector((state) => loggedInUserToken(state))

  useEffect(() => {
    if (!statesList.length) {
      dispatch(fetchStates(token))
    }
  }, [])

  const mappedState = statesMap[degreeInitialValues?.state]
  const updatedMappedState = mappedState
    ? `${mappedState}, ${degreeInitialValues?.state}` ?? ''
    : ''

  const initialValues = {
    degree: degreeInitialValues?.degree ?? '',
    school: degreeInitialValues?.school ?? '',
    state: updatedMappedState,
    concentration: degreeInitialValues?.concentration ?? '',
    graduation_year: degreeInitialValues?.graduation_year ?? '',
  }

  const handleSubmit = (values) => {
    const updatedValues = { ...values }
    const updatedState = values.state.split(',')
    updatedValues.state = updatedState[1].trim()

    if (edit) {
      dispatch(
        updateProviderDegree([
          token,
          provider.id,
          degreeInitialValues.id,
          updatedValues,
        ])
      )
    }
    if (!edit) {
      dispatch(addProviderDegree([token, provider.id, updatedValues]))
    }
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        {edit === true ? 'Edit' : 'Add'} Education Details
      </DialogTitle>

      <DialogContent>
        <Box className={classes.form}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={educationDetailsSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormikTextField
                      className={classes.field}
                      name="degree"
                      label="Degree Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="school"
                      label="Name Of School"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="state"
                      label="State Of School"
                      select={true}
                      accessor="name"
                      accessor2="code"
                      items={sortBy(statesList, (e) => e.name.toLowerCase())}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="concentration"
                      label="Concentration"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="graduation_year"
                      label="Year Of Graduation"
                      select={true}
                      accessor="value"
                      items={YEARS}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    {edit ? 'Save' : 'Add'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default EducationDetailsForm

EducationDetailsForm.propTypes = {
  edit: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  degreeInitialValues: PropTypes.object,
}

EducationDetailsForm.defaultProps = {
  degreeInitialValues: {},
}
