import React from 'react'
import useStyles from 'views/Provider/ScheduleExceptions/ScheduleModal/styles'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
} from '@mui/material'
import IOSSwitch from 'components/Switch'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { initialState, scheduleExceptionSchema } from 'schema/scheduleException'
import moment from 'moment'
import {
  dateFormat,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT,
} from 'utils/constants'
import { getTodayDate } from 'utils/helperFunctions'
import { Formik, Form } from 'formik'
import FormikTextField from 'components/FormikTextField'

const ScheduleModal = ({ open, handleClose, handleAdd }) => {
  const classes = useStyles()

  const handleSubmit = (values) => {
    const scheduleException = {
      date: values.date.format(SERVER_DATE_FORMAT),
      is_open: values.is_open,
      open_time: values.open_time.format(SERVER_TIME_FORMAT),
      close_time: values.close_time.format(SERVER_TIME_FORMAT),
    }
    handleAdd(scheduleException)
    handleClose()
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Add Schedule Exception</DialogTitle>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validationSchema={scheduleExceptionSchema}
        >
          {({
            values: { date, is_open, open_time, close_time },
            setFieldValue,
          }) => (
            <Form>
              <DialogContent sx={{ pt: 0 }}>
                <DialogContentText className={classes.dialogContentText}>
                  Are you closed or open?{' '}
                  <strong>{moment(date).format(dateFormat)}</strong>
                </DialogContentText>

                <Grid container columnSpacing={5}>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Date"
                        minDate={moment(getTodayDate())}
                        value={date}
                        onChange={(value) => {
                          setFieldValue(`date`, value)
                        }}
                        renderInput={(params) => (
                          <FormikTextField
                            name="date"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'hh:mm a',
                            }}
                            size="small"
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <FormGroup className={classes.widthFitContent}>
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <IOSSwitch
                            className={classes.switch}
                            sx={{ m: 1 }}
                            checked={is_open}
                            onChange={(event) => {
                              setFieldValue(`is_open`, event.target.checked)
                            }}
                          />
                        }
                        label={
                          <Typography fontSize={18}>
                            {is_open ? 'Open' : 'Closed'}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  {is_open ? (
                    <>
                      <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            openTo="hours"
                            label="Open time"
                            value={open_time}
                            minutesStep={15}
                            onChange={(value) => {
                              setFieldValue(`open_time`, value)
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                name="open_time"
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'hh:mm a',
                                }}
                                FormHelperTextProps={{
                                  classes: {
                                    root: classes.root,
                                  },
                                }}
                                size="small"
                                readOnly={true}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <TimePicker
                            openTo="hours"
                            label="Close time"
                            value={close_time || null}
                            minutesStep={15}
                            onChange={(value) => {
                              setFieldValue(`close_time`, value)
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                name="close_time"
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'hh:mm a',
                                }}
                                FormHelperTextProps={{
                                  classes: {
                                    root: classes.root,
                                  },
                                }}
                                size="small"
                                readOnly={true}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    className={classes.cancelButton}
                    color="error"
                    variant="contained"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.saveButton}
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    Add
                  </Button>
                </Box>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}

ScheduleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
}

export default ScheduleModal
