import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  rootHeading: {
    marginBottom: '20px',
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 'bold',
    fontSize: '2.3rem',
  },
  button: {
    borderRadius: '4px',
    float: 'right',
    width: '150px',
    marginRight: '15px',
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  buttonBox: {
    display: 'flex',
    gap: '0.75rem',
  },
  saveButton: {
    padding: '0.45rem 3rem',
  },
  copyButton: {
    padding: '0.45rem 1.5rem',
  },
  loader: {
    marginTop: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
