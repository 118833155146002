import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  heading: {
    fontWeight: 'bold',
    fontSize: '28px',
  },
  mainContent: {
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default useStyles
