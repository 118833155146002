import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchMedications,
  postMedication,
  updateMedication,
  deleteMedication,
} from 'api-client/MedicationData/thunks'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const medicationDataSlice = createSlice({
  name: 'medicationData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMedications.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchMedications.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [fetchMedications.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postMedication.pending]: (state) => {
      state.status = constants.loading
    },
    [postMedication.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload.data]
      state.status = constants.succeeded
      state.error = null
      toast.success('Medication Added')
    },
    [postMedication.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateMedication.pending]: (state) => {
      state.status = constants.loading
    },
    [updateMedication.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          row.medication = action.meta.arg[2]['medication']
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Medication Updated')
    },
    [updateMedication.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
    [deleteMedication.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteMedication.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          return false
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Medication Deleted')
    },
    [deleteMedication.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getMedicationList = (state) => state.medicationDataState.data
export const medicationStatus = (state) => state.medicationDataState.status

export default medicationDataSlice.reducer
