import moment from 'moment'
import * as Yup from 'yup'

export const dailyScheduleSchema = Yup.object({
  is_open: Yup.bool().label('Is Open flag').required(),
  open_time: Yup.date()
    .label('Open time')
    .when('is_open', {
      is: true,
      then: Yup.date().typeError('Please select time from clock'),
    })
    .nullable(),
  close_time: Yup.date()
    .label('Close time')
    .when('is_open', {
      is: true,
      then: Yup.date()
        .typeError('Please select time from clock')
        .test(
          'open close check',
          'Open Time should be before Close Time',
          function (value) {
            const openTime = moment(this.parent.open_time)
            const closeTime = moment(value)
            if (closeTime.isBefore(openTime)) return 0

            return 1
          }
        )
        .test(
          'same time check',
          'Open and Close Times should not be same',
          function (value) {
            const openTime = moment(this.parent.open_time)
            const closeTime = moment(value)
            if (closeTime.isSame(openTime)) return 0
            return 1
          }
        ),
    })
    .nullable(),
})

export const weeklyScheduleSchema = Yup.array()
  .of(dailyScheduleSchema)
  .label('Provider Weekly Schedule')
  .required()
