import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material'
import Logo from 'assets/images/LOGO.png'
import { constants, userRolesConstants } from 'utils/constants'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Formik } from 'formik'
import { initialValues, validationSchema } from 'schema/SignIn.Form.Validation'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import { toast } from 'react-toastify'

import Form from 'views/SignIn/Form'
import { fetchCurrentUser } from 'api-client/CurrentUser/thunks'
import { useHistory } from 'react-router'

const theme = createTheme()

const SignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => loggedInUser(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const { status } = useSelector((state) => state.currentUserState)
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const userObj = {
      email: data.get('email').trim(),
      password: data.get('password').trim(),
    }
    dispatch(fetchCurrentUser(userObj))
  }

  if (status === constants.succeeded) {
    if (user) {
      if (user.role === userRolesConstants.admin) history.push('/admin')
      if (user.role === userRolesConstants.provider && userInfo.approved_on)
        history.push('/provider')
      if (user.role === userRolesConstants.provider && !userInfo.approved_on)
        history.push(`/provider/${userInfo.id}/profile`)
    } else {
      toast.error('Something went wrong')
      toast.error('Please try again after a while')
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: 8, mr: 2 }}
        >
          <img src={Logo} width="200" alt="HealHT LOGO" />
        </Box>

        <Box
          sx={{
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
            >
              {(props) => <Form {...props} />}
            </Formik>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default SignIn
