import { isString, replace } from 'lodash'
import numeral from 'numeral'
import store from 'store'
import { getPatientDataAgainstProvider } from 'api-client/PatientData/thunks'

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00')
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%')
}

export function fNumber(number) {
  return numeral(number).format()
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '')
}

export function fData(number) {
  return numeral(number).format('0.0 b')
}

export const getTodayDate = () => {
  const todayDate = new Date()
  todayDate.setDate(todayDate.getDate() + 1)
  return todayDate
}

export const sortItemsInSensitive = (prevRow, currRow, columnId) => {
  if (
    prevRow.original[columnId].toLowerCase() >
    currRow.original[columnId].toLowerCase()
  ) {
    return 1
  } else if (
    prevRow.original[columnId].toLowerCase() <
    currRow.original[columnId].toLowerCase()
  ) {
    return -1
  }
  return 0
}
export const attachHTTPProtocolToLink = (value) => {
  if (!value) {
    return null
  }
  if (value.includes('http://') || value.includes('https://')) {
    return value
  }
  return `https://${value}`
}

export const createPatientDataInCSV = async (token, providerId) => {
  const response = await store.dispatch(
    getPatientDataAgainstProvider({ providerId, token })
  )
  const patientData = response.payload.data
  const headings = [
    'Patient Name',
    'Gender',
    'Date of birth',
    'Phone',
    'Email',
    'Address',
    'Status',
  ]
  const values = patientData?.map((item) => [
    item.name,
    item.gender,
    new Date(item.date_of_birth).toLocaleDateString(),
    item.phone_number,
    item.email,
    item.address,
    item.status,
  ])
  const rows = [headings, ...values]
  const csvContent = rows
    .map((row) =>
      row
        .map((value) => {
          if (value.includes(',')) {
            return `"${value}"`
          }
          return ` ${value.replace('\n', '')}`
        })
        .join(',')
    )
    .join('\n')

  const link = document.createElement('a')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURI(csvContent)
  )
  link.setAttribute('download', 'patients.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const makeVisitsCSV = (visitsData) => {
  const headings = [
    'Patient Name',
    'Provider Name',
    'Provider Speciality',
    'Provider Location',
    'Created At',
    'Reason',
    'Status',
    'Total messages',
    'Media',
  ]
  const values = visitsData?.map((item) => [
    item.patient_name,
    item.provider_name,
    item.provider_specialty,
    item.provider_location,
    new Date(item.date).toLocaleDateString(),
    item.complaint,
    item.status,
    item.total_messages.toString(),
    item.media,
  ])
  const rows = [headings, ...values]
  const csvContent = rows
    .map((row) =>
      row
        .map((value) => {
          if (isString(value) && value.includes(',')) {
            return `"${value.padEnd(value.length)}"`
          }
          return ` ${value.replace(/\n/g, '')}`
        })
        .join(',')
    )
    .join('\n')
  const link = document.createElement('a')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURI(csvContent)
  )
  link.setAttribute('download', 'visits.csv')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
