import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loader: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingItems: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 'bold',
    color: '#616161',
  },
  field: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  dialogHeading: {
    padding: '12px 24px 8px 24px',
  },
  container: {
    marginTop: '1rem',
  },
  sectionHeading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  singleInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '15px',
    marginRight: '1rem',
  },
  multilineValue: {
    fontSize: '15px',
    marginRight: '1rem',
    whiteSpace: 'pre-wrap',
  },
  editIconBtn: {
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  avatar: {
    height: '150px',
    width: '150px',
  },
  profileActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    marginTop: '8px',
  },
  editButton: {
    paddingBottom: '2px',
  },
  borderedValue: {
    borderBottom: '2px solid #e0e0e0',
  },
  clockLink: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 5,
    textDecoration: 'none',
    fontSize: '15px',
    fontWeight: 500,
    cursor: 'pointer',
    maxWidth: '300px',
  },
})

export default useStyles
