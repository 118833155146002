import React from 'react'
import PropTypes from 'prop-types'

// material
import { alpha, styled } from '@mui/material/styles'
import { Card, Typography } from '@mui/material'
import WorkIcon from '@mui/icons-material/Work'

import theme from 'config/theme'

const RootStyle = styled(Card)(() => ({
  boxShadow: 'none',
  textAlign: 'center',
  borderRadius: '16px',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
}))

const IconWrapperStyle = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}))

// ----------------------------------------------------------------------

const VisitCard = ({ count }) => (
  <RootStyle>
    <IconWrapperStyle>
      <WorkIcon />
    </IconWrapperStyle>
    <Typography variant="h3">{count}</Typography>
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      Visits
    </Typography>
  </RootStyle>
)

VisitCard.propTypes = {
  count: PropTypes.number.isRequired,
}

export default VisitCard
