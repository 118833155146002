import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainBox: { display: 'flex' },
  contentBox: { flexGrow: 1, padding: '20px' },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    margin: theme.spacing(0),

    minHeight: '100%',
    backgroundColor: '$body-bg',
  },
  darkIcon: {
    marginLeft: '2px !important',
    filter: 'opacity(1) drop-shadow(0 0 0 black)',
  },
}))

export default useStyles
