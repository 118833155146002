import { object, string, ref } from 'yup'

export const ResetPasswordSchema = object().shape({
  current_password: string().label('Current Password').required(),
  new_password: string()
    .min(8, 'New password must be at least 8 characters')
    .label('New password')
    .required(),
  confirm_password: string()
    .oneOf([ref('new_password'), null], 'Passwords do not match.')
    .label('Confirm password')
    .required(),
})
