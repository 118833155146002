const year = new Date().getFullYear()

export const YEARS = []
export const VISITS_YEARS = []

for (let i = year; i >= 1973; i--) {
  YEARS.push({ value: i })
}

for (let i = year; i > 2020; i--) {
  VISITS_YEARS.push({ value: i })
}
