import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  rootHeading: {
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 'bold',
    fontSize: '2.3rem',
  },
  card: {},
  button: {
    borderRadius: '4px',
    float: 'right',
    marginTop: '-10px',
    marginRight: '5px',
    width: '150px',
  },
  loader: {
    marginTop: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
