import moment from 'moment/moment'
import { dateFormat } from 'utils/constants'
import { Box, Chip, Typography } from '@mui/material'
import { style } from 'views/Admin/Providers/styles'
import React from 'react'
import Avatar from '@mui/material/Avatar'

export const COLUMNS = [
  {
    Header: 'Name',
    Cell: (cell) => (
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Avatar
          alt={cell.row.original.first_name}
          src={cell.row.original.thumbnail_profile_pic}
        />
        <Typography>{cell.row.original.full_name}</Typography>
      </Box>
    ),
  },
  {
    disableSortBy: true,
    Header: 'Mobile',
    accessor: 'phone_number',
  },
  {
    disableSortBy: true,
    Header: 'Email',
    accessor: 'User.email',
  },
  {
    disableSortBy: true,
    Header: 'Status',
    accessor: 'User.is_active',
    Cell: (row) => (
      <Chip
        label={row.value ? 'Active' : 'In Active'}
        size="small"
        sx={row.value ? style.activeChip : style.inActiveChip}
      />
    ),
  },
  {
    Header: 'Created at',
    accessor: 'createdAt',
    Cell: (row) => moment(new Date(row.value)).format(dateFormat),
  },
  {
    Header: 'Approved at',
    accessor: 'approved_on',
    Cell: (row) =>
      row.value
        ? moment(new Date(row.value)).format(dateFormat)
        : 'Not Approved',
  },
]
