import React from 'react'
import { Box, Grid, Link, Typography } from '@mui/material'
import useStyles from 'components/SocialLink/styles'
import PropTypes from 'prop-types'

const SocialLink = ({ title, url }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6}>
      <Box className={classes.singleInfo}>
        <Typography className={classes.label}>{title}</Typography>
        {!url ? (
          <Typography variant="body2" className={classes.value}>
            Not Added.
          </Typography>
        ) : (
          <Link
            className={classes.value}
            variant="body2"
            color="secondary"
            target="_blank"
            href={url}
          >
            {url}
          </Link>
        )}
      </Box>
    </Grid>
  )
}

export default SocialLink

SocialLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

SocialLink.defaultProps = {
  url: '',
}
