import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Button,
  CssBaseline,
  CircularProgress,
  Typography,
} from '@mui/material'
import SpecialityTable from 'components/BasicTable'
import {
  fetchSpecialities,
  deleteSpeciality,
  updateSpeciality,
  postSpeciality,
} from 'api-client/SpecialityData/thunks'
import useStyles from 'views/Admin/Speciality/styles'
import { useDispatch, useSelector } from 'react-redux'
import { loggedInUserToken } from 'store/CurrentUser'
import { getSpecialityList } from 'store/SpecialityData'
import { ApiRequestMethod, constants } from 'utils/constants'
import { COLUMNS } from 'views/Admin/Speciality/columns'
import AddIcon from '@mui/icons-material/Add'
import CustomSingleTextModal from 'components/CustomSingleTextModal'
import { specialityValidationSchema } from 'schema/SpecialityForm'
import { toast } from 'react-toastify'
import TableSearchHeader from 'components/TableSearchHeader'
import { isEmpty } from 'lodash'
import NoRecordFound from 'components/NoRecordFound'

const Speciality = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const [specialityText, setSpecialityText] = useState('')
  const [specialityId, setSpecialityId] = useState(false)
  const [openSpecialityModal, setOpenSpecialityModal] = useState(false)
  const [sortOn, setSortOn] = useState([{ id: 'speciality', asc: true }])
  const token = useSelector((state) => loggedInUserToken(state))
  const specialityList = useSelector((state) => getSpecialityList(state))
  const { status } = useSelector((state) => state.specialityDataState)

  const filteredList = searchText
    ? specialityList.filter((item) =>
        item.speciality.toLowerCase().includes(searchText.toLowerCase())
      )
    : [...specialityList]

  useEffect(() => {
    dispatch(fetchSpecialities(token))
  }, [])

  const handleOpenSpecialityModal = () => {
    setOpenSpecialityModal(true)
  }

  const handleOpenSpecialityModalForEdit = (cell) => {
    setSpecialityText(cell.value)
    setSpecialityId(cell.row.original.id)
    setOpenSpecialityModal(true)
  }

  const handleCloseSpecialityModal = () => {
    setSpecialityText('')
    setSpecialityId('')
    setOpenSpecialityModal(false)
  }

  const handleAddOrUpdate = (type, values) => {
    const matchedSpeciality = specialityList.filter(
      ({ speciality }) =>
        speciality.toLowerCase() === values.speciality.toLowerCase()
    )
    if (!matchedSpeciality.length && type === ApiRequestMethod.PATCH) {
      const requestBody = [token, specialityId, values]
      dispatch(updateSpeciality(requestBody))
    } else if (!matchedSpeciality.length && type === ApiRequestMethod.POST) {
      const requestBody = [token, values]
      dispatch(postSpeciality(requestBody))
    } else {
      toast.error('This speciality already exists.')
    }
  }
  const handleDelete = (id) => {
    const requestBody = [token, id]
    dispatch(deleteSpeciality(requestBody))
  }

  const searchTextHandler = (e) => {
    setSearchText(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
  }

  return (
    <Box sx={{ m: '2rem 3rem' }}>
      <Box className={classes.pageHeader}>
        <Typography className={classes.headerTitle}>
          Provider Speciality
        </Typography>
        <Box>
          <Button
            startIcon={<AddIcon />}
            className={classes.addButton}
            onClick={handleOpenSpecialityModal}
            color="secondary"
          >
            Add New
          </Button>
        </Box>
      </Box>

      {isEmpty(specialityList) && status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : isEmpty(specialityList) && status === constants.succeeded ? (
        <NoRecordFound msg="No speciality found" />
      ) : (
        <Grid container spacing={4}>
          <CssBaseline />
          <Grid item xs={12}>
            <TableSearchHeader
              handleClearSearch={handleClearSearch}
              searchText={searchText}
              itemName="Speciality"
              searchTextHandler={searchTextHandler}
              totalItems={filteredList.length}
            />
            <SpecialityTable
              dataList={filteredList}
              COLUMNS={COLUMNS}
              setSortOn={setSortOn}
              initialSort={sortOn}
              access="speciality"
              handleDelete={handleDelete}
              handleOpenModalForEdit={handleOpenSpecialityModalForEdit}
            />
          </Grid>
        </Grid>
      )}
      <CustomSingleTextModal
        handleCloseModal={handleCloseSpecialityModal}
        initialValues={{ speciality: specialityText }}
        edit={!!specialityId}
        handleAddOrUpdate={handleAddOrUpdate}
        dialogTitle="Speciality"
        open={openSpecialityModal}
        schema={specialityValidationSchema}
      />
    </Box>
  )
}
export default Speciality
