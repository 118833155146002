import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  table: {
    borderRadius: '0px 0px 8px 8px',
    boxShadow: 'none',
    border: '1px solid #eeeeee',
  },
  actionHeader: {
    fontWeight: '500',
  },
  iconBox: {
    display: 'flex',
    gap: '0rem',
  },
  row: {
    '&:hover': { backgroundColor: '#e3f2fd' },
  },
  headTitle: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    fontWeight: 500,
  },
}))

export default useStyles
