import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchSpecialities,
  postSpeciality,
  updateSpeciality,
  deleteSpeciality,
} from 'api-client/SpecialityData/thunks'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const specialityDataSlice = createSlice({
  name: 'specialityData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSpecialities.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchSpecialities.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.error = null
      state.data = action.payload.data
    },
    [fetchSpecialities.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postSpeciality.pending]: (state) => {
      state.status = constants.loading
    },
    [postSpeciality.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload.data]
      state.status = constants.succeeded
      state.error = null
      toast.success('Speciality Added')
    },
    [postSpeciality.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateSpeciality.pending]: (state) => {
      state.status = constants.loading
    },
    [updateSpeciality.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          row.speciality = action.meta.arg[2]['speciality']
        }
        return true
      })
      state.error = null
      toast.success('Speciality Updated')
    },
    [updateSpeciality.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
    [deleteSpeciality.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteSpeciality.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          return false
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Speciality Deleted')
    },
    [deleteSpeciality.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getSpecialityList = (state) => state.specialityDataState.data
export const specialityStatus = (state) => state.specialityDataState.status

export default specialityDataSlice.reducer
