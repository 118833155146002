import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchProviderTypes,
  postProviderType,
  updateProviderType,
  deleteProviderType,
} from 'api-client/ProviderTypeData/thunks'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const providerTypeDataSlice = createSlice({
  name: 'providerTypeData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProviderTypes.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchProviderTypes.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [fetchProviderTypes.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postProviderType.pending]: (state) => {
      state.status = constants.loading
    },
    [postProviderType.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload.data]
      state.status = constants.succeeded
      state.error = null
      toast.success('Type Added')
    },
    [postProviderType.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateProviderType.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderType.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          row.type = action.meta.arg[2]['provider_type']
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Type Updated')
    },
    [updateProviderType.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
    [deleteProviderType.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteProviderType.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          return false
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Type Deleted')
    },
    [deleteProviderType.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getProviderTypeList = (state) => state.providerTypeDataState.data
export const providerTypeStatus = (state) => state.providerTypeDataState.status

export default providerTypeDataSlice.reducer
