import { Button, TextField, CircularProgress, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { constants } from 'utils/constants'
import { useSelector } from 'react-redux'
import useStyles from 'views/SignIn/styles'
import React from 'react'

const Form = (props) => {
  const {
    values: { email, password },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
  } = props
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const classes = useStyles()
  const { status } = useSelector((state) => state.currentUserState)
  return (
    <>
      <TextField
        margin="normal"
        sx={{ mt: 3, mb: 1, width: '100%' }}
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        helperText={touched.email ? errors.email : ''}
        error={touched.email && Boolean(errors.email)}
        value={email}
        onChange={change.bind(null, 'email')}
      />
      <TextField
        margin="normal"
        required
        sx={{ mt: 2, mb: 3, width: '100%' }}
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        helperText={touched.password ? errors.password : ''}
        error={touched.password && Boolean(errors.password)}
        value={password}
        onChange={change.bind(null, 'password')}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, width: '100%', positive: 'absolute' }}
        disabled={!isValid}
      >
        Sign In
        {status === constants.loading ? (
          <Box>
            <CircularProgress className={classes.loader} size={15} />
          </Box>
        ) : null}
      </Button>
    </>
  )
}

Form.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
}

export default Form
