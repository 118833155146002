import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  imageCardMedia: {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  value: {
    fontSize: '15px',
    marginRight: '1rem',
  },
})

export default useStyles
