import { monthFullNames } from 'utils/constants'

export default function (data) {
  const dataByMonth = monthFullNames.map((monthName) => {
    const val = data[monthName]
    if (!val) return 0
    return val
  })
  return dataByMonth
}
