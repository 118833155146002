import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import useStyles from 'views/DisabledPage/styles'
import { useSelector } from 'react-redux'
import { loggedInUserInfo } from 'store/CurrentUser'

const DisabledPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  return (
    <Box className={classes.errorBox}>
      <Typography className={classes.errorText}>Page not found</Typography>
      <Typography sx={{ textAlign: 'center' }}>
        You will be able to access this page once an admin approves your
        profile.
        <br />
        <span>Please complete your profile if it is incomplete.</span>
      </Typography>
      <Button
        className={classes.gotoButton}
        variant="contained"
        color="secondary"
        onClick={() => history.push(`/providers/${userInfo.id}`)}
      >
        Go to Profile
      </Button>
    </Box>
  )
}

export default DisabledPage
