import { object, string } from 'yup'
import { isValidLink } from 'utils/constants'

const checkUrl = (value) => {
  if (!value) return true
  if (value) {
    return isValidLink(value)
  }
}

export const socialLinksFormSchema = object().shape({
  website_url: string()
    .label('Website Url')
    .test('is-link', 'Invalid Link', checkUrl),
  appointment_url: string()
    .label('Appointment Url')
    .test('is-link', 'Invalid Link', checkUrl),
  reviews_url: string()
    .label('Reviews Url')
    .test('is-link', 'Invalid Link', checkUrl),
  twitter_url: string()
    .label('Twitter Url')
    .test('is-link', 'Invalid Link', checkUrl),
  facebook_url: string()
    .label('Facebook Url')
    .test('is-link', 'Invalid Link', checkUrl),
  instagram_url: string()
    .label('Instagram Url')
    .test('is-link', 'Invalid Link', checkUrl),
  tiktok_url: string()
    .label('Tiktok Url')
    .test('is-link', 'Invalid Link', checkUrl),
  linkedin_url: string()
    .label('LinkedIn Url')
    .test('is-link', 'Invalid Link', checkUrl),
  youtube_url: string()
    .label('Youtube Url')
    .test('is-link', 'Invalid Link', checkUrl),
})
