import React, { useEffect } from 'react'
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  Link,
  Grid,
  CircularProgress,
} from '@mui/material'
import Logo from 'assets/images/LOGO.png'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Formik } from 'formik'
import {
  initialValues,
  validationSchema,
} from 'schema/ChangePassword.Form.Validation'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import {
  postNewPassword,
  getEmailTokenStatus,
} from 'api-client/PasswordAPIs/thunks'
import Form from 'views/ChangePassword/Form'
import useStyles from 'views/ChangePassword/styles'
import { constants } from 'utils/constants'
import { refreshStatus } from 'store/PasswordAPIs'

const theme = createTheme()

const ChangePassword = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const token = location.search.split('=')[1]

  useEffect(() => {
    dispatch(getEmailTokenStatus(token))
  }, [])

  const { tokenStatus, status, tokenExpiryTime } = useSelector(
    (state) => state.passwordAPIsDataState
  )
  if (tokenExpiryTime) {
    setTimeout(() => history.push('/change-password/fail'), tokenExpiryTime)
  }
  if (tokenStatus === constants.failed || status === constants.failed)
    history.push('/change-password/fail')
  if (status === constants.succeeded) history.push('/change-password/success')

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const dataObj = {
      password: formData.get('password').trim(),
    }
    dispatch(postNewPassword([token, dataObj]))
    dispatch(refreshStatus())
  }

  const classes = useStyles()
  return (
    <>
      {tokenStatus === constants.loading ? (
        <Box className={classes.pageLoader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              display="flex"
              justifyContent="center"
              sx={{ marginTop: 8, mr: 2 }}
            >
              <img src={Logo} width="200" alt="HealHT LOGO" />
            </Box>

            <Box
              sx={{
                marginTop: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Set New Password
              </Typography>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  validateOnMount={true}
                >
                  {(props) => <Form {...props} />}
                </Formik>
                <Grid container>
                  <Grid item xs>
                    <Link href="/" variant="body2">
                      Go to Sign In
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/forgot-password" variant="body2">
                      Request New Link
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
      )
    </>
  )
}

export default ChangePassword
