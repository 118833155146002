import React from 'react'
import NavDrawer from 'views/NavDrawer'
import { DrawerHeader } from 'components/DrawerHeader'
import { Box } from '@mui/material'
import useStyles from 'views/Provider/Layout/styles'
import { Switch, Route } from 'react-router-dom'
import Settings from 'views/Provider/Settings'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined'
import Dashboard from 'views/Provider/Dashboard'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PracticeTerms from 'views/Provider/PracticeTerms'
import TermsIcon from 'assets/images/terms-icon.png'
import VisitHints from 'views/Provider/VisitHints'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import ComplaintList from 'views/Provider/Complaint'
import ProviderProfile from 'views/Provider/Profile'
import ComplaintsIcon from 'assets/images/complaint-icon-48x48.png'
import NotFound from 'views/NotFound'
import ProviderPatients from 'views/Provider/Patients'
import ResetPassword from 'views/ResetPassword'

const Layout = () => {
  const classes = useStyles()
  const linkedRoutes = {
    '/provider': ['Dashboard', <DashboardIcon key={1} />],
    '/provider/patients': ['Patients', <AccessibleOutlinedIcon key={2} />],
    '/provider/schedule': ['Schedule', <AccessTimeIcon key={3} />],
    '/provider/complaints': [
      'Complaints',
      <img
        src={ComplaintsIcon}
        className={classes.darkIcon}
        width="25"
        height="22"
        key={4}
        alt=""
      />,
    ],
    '/provider/practiceTerms': [
      'Practice Terms',
      <img
        src={TermsIcon}
        className={classes.darkIcon}
        width="25"
        height="22"
        key={5}
        alt=""
      />,
    ],
    '/provider/visitHints': [
      'Visit Hints',
      <FormatListNumberedIcon
        className={classes.darkIcon}
        width="25"
        height="22"
        key={6}
        alt=""
      />,
    ],
  }
  return (
    <>
      <Box className={classes.mainBox}>
        <NavDrawer routes={linkedRoutes} />
        <Box component="main" className={classes.contentBox}>
          <DrawerHeader />
          <main className={classes.content}>
            <Switch>
              <Route exact path="/provider" component={Dashboard} />
              <Route
                exact
                path="/provider/practiceTerms"
                component={PracticeTerms}
              />
              <Route exact path="/provider/visitHints" component={VisitHints} />
              <Route
                exact
                path="/provider/complaints"
                component={ComplaintList}
              />
              <Route path="/provider/schedule" exact component={Settings} />
              <Route
                path="/provider/patients"
                exact
                component={ProviderPatients}
              />
              <Route
                path="/provider/:id/profile"
                exact
                component={ProviderProfile}
              />
              <Route
                exact
                path="/provider/settings"
                component={ResetPassword}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
