import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const getPatientDataAgainstProvider = createAsyncThunk(
  'patientData/getPatientDataAgainstProvider',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { providerId, token } = requestObj
      const response = await axios.get(
        `${baseApiUrl}/providers/${providerId}/patients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getProviderVisitsWithSinglePatient = createAsyncThunk(
  'patientData/getProviderVisitsWithSinglePatient',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { providerId, patientId, token } = requestObj
      const response = await axios.get(
        `${baseApiUrl}/visits/providers/${providerId}/patients/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getProviderVisitsWithAllPatients = createAsyncThunk(
  'patientData/getProviderVisitsWithAllPatients',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { providerId, start_date, end_date, token } = requestObj
      const response = await axios.get(
        `${baseApiUrl}/visits/providers/${providerId}?start_date=${start_date}&end_date=${end_date}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
