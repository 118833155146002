import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import {
  loggedInUser,
  loggedInUserInfo,
  loggedInUserToken,
} from 'store/CurrentUser'
import { userRolesConstants } from 'utils/constants'
import { getProviderVisitsWithSinglePatient } from 'api-client/PatientData/thunks'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { makeVisitsCSV } from 'utils/helperFunctions'

const DownloadVisitsOfPatientAndProvider = ({ id }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const token = useSelector((state) => loggedInUserToken(state))
  const user = useSelector((state) => loggedInUser(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))

  const provider_id =
    user?.role === userRolesConstants.provider ? userInfo?.id : params.id

  const DownloadProviderAndPatientVisits = async ({
    patientId,
    providerId,
  }) => {
    if (providerId) {
      setLoading(true)
      const response = await dispatch(
        getProviderVisitsWithSinglePatient({
          patientId,
          providerId,
          token,
        })
      )
      const visitsData = response.payload.data
      setLoading(false)
      if (!visitsData.length) {
        toast.error('No visits found with this patient yet.')
        return
      }
      makeVisitsCSV(visitsData)
    }
  }

  return (
    <>
      {loading ? (
        <IconButton>
          <CircularProgress size={20} color="secondary" />
        </IconButton>
      ) : (
        <Tooltip title="Download visits of this patient in CSV">
          <IconButton
            onClick={() =>
              DownloadProviderAndPatientVisits({
                patientId: id,
                providerId: provider_id,
              })
            }
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

DownloadVisitsOfPatientAndProvider.propTypes = {
  id: PropTypes.string.isRequired,
}

export default DownloadVisitsOfPatientAndProvider
