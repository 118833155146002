import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  mainContainer: {
    color: 'rgba(0,0,0,0.7)',
    marginTop: '-18px',
    paddingBottom: '30px',
  },
  loader: {
    marginTop: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
