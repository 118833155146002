import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import useStyles from 'components/CustomSingleTextModal/styles'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import FormikTextField from 'components/FormikTextField'
import { Form, Formik } from 'formik'
import { capitalize } from 'lodash'
import { ApiRequestMethod } from 'utils/constants'

const ConfirmDialogBox = ({
  handleCloseModal,
  dialogTitle,
  open,
  handleAddOrUpdate,
  edit,
  initialValues,
  schema,
}) => {
  const textKeys = Object.keys(initialValues)
  const classes = useStyles()

  const handleSubmit = (values) => {
    if (edit) {
      handleAddOrUpdate(ApiRequestMethod.PATCH, values)
    } else {
      handleAddOrUpdate(ApiRequestMethod.POST, values)
    }
    handleCloseModal()
  }

  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        fullWidth
        onClose={handleCloseModal}
      >
        <DialogTitle>
          {edit ? 'Edit' : 'Add'} {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form className={classes.form}>
                <Box>
                  <FormikTextField
                    size="medium"
                    className={classes.field}
                    autoFocus
                    name={textKeys[0]}
                    label={capitalize(textKeys[0])}
                  />
                </Box>

                <Box className={classes.buttonBox}>
                  <Button
                    className={classes.cancelButton}
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.saveButton}
                    type="submit"
                    color="secondary"
                    variant="contained"
                  >
                    {edit ? 'Save' : 'Add'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  )
}

ConfirmDialogBox.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleAddOrUpdate: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
}

export default ConfirmDialogBox
