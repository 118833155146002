import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  mainBox: { display: 'flex' },
  contentBox: {
    flexGrow: 1,
    padding: '20px',
    backgroundColor: 'rgb(246,248,250)',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
    backgroundColor: '$body-bg',
  },
  icon: {
    marginLeft: '2px !important',
    filter: 'opacity(0.5) drop-shadow(0 0 0 black)',
  },
  darkIcon: {
    marginLeft: '2px !important',
    filter: 'opacity(0.9) drop-shadow(0 0 0 black)',
  },
  specialityIcon: {
    filter: 'opacity(1) drop-shadow(0 0 0 black)',
  },
}))

export default useStyles
