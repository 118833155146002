import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchProvidersData = createAsyncThunk(
  'providersData/fetchProvidersData',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/providers/getAllProviders`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchSingleProvider = createAsyncThunk(
  'providersData/fetchSingleProvider',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/providers/getProvider/${requestObj[1]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProvider = createAsyncThunk(
  'providersData/updateProvider',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/providers/${requestObj[1] && requestObj[1]}`,
        requestObj[2] && requestObj[2],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProviderProfilePic = createAsyncThunk(
  'providersData/updateProviderProfilePic',
  async (requestObj, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('photo', requestObj[1])
      const response = await axios.post(
        `${baseApiUrl}/image/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProviderState = createAsyncThunk(
  'providersData/updateProviderState',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/admin/updateProviderStatus`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteProvider = createAsyncThunk(
  'providersData/deleteProvider',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/providers/${requestObj[1] && requestObj[1]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const restoreProvider = createAsyncThunk(
  'providersData/restoreProvider',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { providerId, token } = requestObj
      const response = await axios.patch(
        `${baseApiUrl}/admin/restoreProvider/${providerId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteProviderStateLicenses = createAsyncThunk(
  'providersData/deleteProviderStateLicenses',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/providers/${requestObj[1]}/stateLicenses`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
          data: { state_licenses: requestObj[2] },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
