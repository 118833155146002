import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loader: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  headingText: {
    fontWeight: 'bold',
    color: '#616161',
  },
})

export default useStyles
