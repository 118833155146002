import React from 'react'
import useStyles from 'components/TextEditor/styles'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Type from 'components/TextEditor/typeConstants'
import defaultTextEditorConfig from 'components/TextEditor/defaultConfig'

const TextEditor = ({ text, handleChange, type, formats, modules }) => {
  const classes = useStyles(type)()
  return (
    <ReactQuill
      readOnly={type === Type.DEFAULT}
      onChange={handleChange}
      className={classes.editor}
      theme={type === Type.DEFAULT ? 'bubble' : 'snow'}
      value={text}
      modules={modules}
      formats={formats}
      bounds={'.app'}
      placeholder="Write here ..."
    />
  )
}

TextEditor.defaultProps = {
  readOnly: false,
  theme: 'snow',
  formats: defaultTextEditorConfig.formats,
  modules: defaultTextEditorConfig.modules,
}
TextEditor.propTypes = {
  text: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  theme: PropTypes.string,
  formats: PropTypes.array,
  modules: PropTypes.object,
  readOnly: PropTypes.bool,
  type: PropTypes.string.isRequired,
}

export default TextEditor
