import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  singleInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '15px',
    marginRight: '1rem',
  },
})

export default useStyles
