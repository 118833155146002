import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from 'store'
import { Box, CircularProgress } from '@mui/material'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const app = document.getElementById('root')

const persistor = persistStore(store)

if (app) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1]
  if (path) {
    history.replace(path)
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate
          loading={
            <Box className="loader">
              <CircularProgress size={60} />
            </Box>
          }
          persistor={persistor}
        >
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    app
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
