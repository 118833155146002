import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    color: 'rgb(255, 0, 0)',
  },
  mainContainer: {
    border: '3px solid green',
    padding: '10px',
    color: 'rgba(0,0,0,0.7)',
    marginTop: '10%',
    paddingBottom: '30px',
  },
  mainCard: {
    boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
    borderRadius: '16px',
  },
  timePicker: {
    color: 'black',
    '&:disabled': {
      color: 'black',
    },
  },
}))

export default useStyles
