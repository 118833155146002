import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  rootHeading: {
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 'bold',
    fontSize: '2.3rem',
  },
  subHeading: {
    fontSize: '17px',
    color: '#757575',
    fontWeight: 'normal',
    marginLeft: '3px',
  },
  card: {
    marginTop: '1.3rem',
    padding: '0.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '8px',
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  buttonBox: {
    display: 'flex',
    gap: '0.75rem',
  },
  saveButton: {
    padding: '0.45rem 3rem',
  },
  copyButton: {
    padding: '0.45rem 1.5rem',
  },
  loader: {
    marginTop: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigFont: {
    fontSize: 20,
  },
})

export default useStyles
