import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchPrroviderHours = createAsyncThunk(
  'providerHours/fetchProviderHours',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/providerHours/${requestObj.providerId}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj.token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProviderHours = createAsyncThunk(
  'providerHours/updateProviderHours',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/providerHours/${requestObj.providerId}/schedule`,
        requestObj.body,
        {
          headers: {
            Authorization: `Bearer ${requestObj.token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
