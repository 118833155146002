import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1.5rem',
    gap: '0.2rem',
  },
  errorText: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.7)',
  },
  gotoButton: {
    marginTop: '1rem',
  },
})

export default useStyles
