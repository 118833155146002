import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { size } from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, ButtonGroup, Card, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import useStyles from 'views/Provider/ScheduleExceptions/styles'
import {
  addScheduleException,
  deleteScheduleException,
  fetchScheduleExceptions,
} from 'api-client/ScheduleExceptions/thunks'
import {
  constants,
  SERVER_TIME_FORMAT,
  userRolesConstants,
} from 'utils/constants'
import Pagination from 'components/Pagination'
import DisabledPage from 'views/DisabledPage'
import BasicTable from 'components/BasicTable'
import NoRecordFound from 'components/NoRecordFound'
import { COLUMNS } from 'views/Provider/ScheduleExceptions/columns'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import { getProviderExceptions } from 'store/ScheduleExceptions'
import AddScheduleModal from 'views/Provider/ScheduleExceptions/ScheduleModal'
import { EXCEPTION_TYPE } from 'utils/constants/exceptionsType'

const PAGE_SIZE = 7
const Filters = Object.freeze({
  PAGE: 'page',
})

const ScheduleExceptions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const queryParams = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(
    queryParams.get(Filters.PAGE) ? queryParams.get(Filters.PAGE) : '1'
  )
  const [exceptionType, setExceptionType] = useState(EXCEPTION_TYPE.UPCOMING)

  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const user = useSelector((state) => loggedInUser(state))
  const { token } = useSelector((state) => state.currentUserState)

  let providerId = ''
  if (user.role === userRolesConstants.admin) {
    providerId = params.id
  } else providerId = userInfo.id

  useEffect(() => {
    dispatch(
      fetchScheduleExceptions({
        token,
        providerId,
        params: {
          exceptionsType: exceptionType,
          page: currentPage,
          page_size: PAGE_SIZE,
        },
      })
    )
  }, [currentPage, exceptionType])

  const exceptions = useSelector((state) => getProviderExceptions(state))
  const { status } = useSelector((state) => state.scheduleExceptionsState)
  const totalElements = exceptions?.total_elements

  const pages = exceptions?.total_pages

  const [openAddScheduleModal, setOpenAddScheduleModal] = React.useState(false)

  const handleOpenAddScheduleModal = () => {
    setOpenAddScheduleModal(true)
  }

  const handleCloseAddScheduleModal = () => {
    setOpenAddScheduleModal(false)
  }

  const handleSetPastExceptions = () => {
    setExceptionType(EXCEPTION_TYPE.PAST)
  }

  const handleSetUpcomingExceptions = () => {
    setExceptionType(EXCEPTION_TYPE.UPCOMING)
  }

  const handleAddScheduleException = (scheduleException) => {
    scheduleException.open_time = moment(
      scheduleException.open_time,
      'HH:mm:ss'
    )
      .utc()
      .format(SERVER_TIME_FORMAT)
    scheduleException.close_time = moment(
      scheduleException.close_time,
      'HH:mm:ss'
    )
      .utc()
      .format(SERVER_TIME_FORMAT)

    dispatch(
      addScheduleException({
        token,
        providerId,
        body: { ...scheduleException, offset: moment().utcOffset() },
      })
    )
  }

  const handleDeleteScheduleException = (id) => {
    dispatch(
      deleteScheduleException({
        token,
        scheduleExceptionId: id,
      })
    )
  }

  if (status === constants.loading)
    return (
      <Box className={classes.loader}>
        <CircularProgress size={60} />
      </Box>
    )

  if (user.role === userRolesConstants.provider && !userInfo.approved_on)
    return <DisabledPage />

  return (
    <>
      <Card elevation={0}>
        <Box className={classes.buttonContainer}>
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              className={
                exceptionType === EXCEPTION_TYPE.UPCOMING
                  ? classes.activeBtn
                  : classes.inActiveBtn
              }
              onClick={handleSetUpcomingExceptions}
              color="secondary"
            >
              Upcoming
            </Button>
            <Button
              variant="contained"
              className={
                exceptionType === EXCEPTION_TYPE.PAST
                  ? classes.activeBtn
                  : classes.inActiveBtn
              }
              onClick={handleSetPastExceptions}
              color="secondary"
            >
              Past
            </Button>
          </ButtonGroup>

          {exceptionType === EXCEPTION_TYPE.UPCOMING && (
            <Button
              startIcon={<AddIcon />}
              className={classes.addButton}
              onClick={handleOpenAddScheduleModal}
              color="secondary"
            >
              Add Exception
            </Button>
          )}
        </Box>

        <Box sx={{ mt: '1rem' }}>
          {size(exceptions?.calendar_exceptions) ? (
            <BasicTable
              dataList={exceptions.calendar_exceptions}
              COLUMNS={COLUMNS}
              access="Exception"
              hideEdit={true}
              handleDelete={handleDeleteScheduleException}
            />
          ) : (
            <NoRecordFound msg="No exception found" />
          )}
        </Box>

        {totalElements >= PAGE_SIZE ? (
          <Pagination
            pageChangeHandler={setCurrentPage}
            currentPage={parseInt(currentPage)}
            selectPageSize={false}
            pageSizes={[`${PAGE_SIZE}`]}
            totalPages={pages}
            rowsPerPage={`${PAGE_SIZE}`}
            rowsPerPageHandler={() => {}}
          />
        ) : null}
      </Card>
      <AddScheduleModal
        open={openAddScheduleModal}
        handleClose={handleCloseAddScheduleModal}
        handleAdd={handleAddScheduleException}
      />
    </>
  )
}

export default ScheduleExceptions
