import React, { useEffect, useState } from 'react'
import {
  Typography,
  Box,
  CircularProgress,
  Grid,
  CssBaseline,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import useStyles from 'views/Provider/Patients/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getPatientsData } from 'store/PatientData'
import {
  loggedInUser,
  loggedInUserInfo,
  loggedInUserToken,
} from 'store/CurrentUser'
import {
  getPatientDataAgainstProvider,
  getProviderVisitsWithAllPatients,
} from 'api-client/PatientData/thunks'
import { useHistory, useParams } from 'react-router-dom'
import { constants, userRolesConstants } from 'utils/constants'
import { isEmpty } from 'lodash'
import NoRecordFound from 'components/NoRecordFound'
import TableSearchHeader from 'components/TableSearchHeader'
import PatientTable from 'components/BasicTable'
import { COLUMNS } from 'views/Provider/Patients/columns'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TokenIcon from '@mui/icons-material/Token'
import { createPatientDataInCSV, makeVisitsCSV } from 'utils/helperFunctions'
import DownloadVisitsOfPatientAndProvider from 'views/Provider/Patients/ManualActions/DownloadVisitsOfPatientAndProvider'
import DateRangeForm from 'components/DateRangeForm'
import { toast } from 'react-toastify'
import moment from 'moment'
import PropTypes from 'prop-types'

const ProviderPatients = () => {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [providerId, setProviderId] = useState('')
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false)
  const user = useSelector((state) => loggedInUser(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const token = useSelector((state) => loggedInUserToken(state))
  const patientData = useSelector((state) => getPatientsData(state))
  const { status } = useSelector((state) => state.patientsDataState)

  useEffect(() => {
    if (user?.role === userRolesConstants.provider) {
      setProviderId(userInfo?.id)
    } else {
      setProviderId(params.id)
    }
  }, [])

  const filteredList = patientData
    ? searchText
      ? patientData.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        )
      : patientData
    : []

  useEffect(() => {
    if (providerId) {
      dispatch(getPatientDataAgainstProvider({ providerId, token }))
    }
  }, [dispatch, providerId])

  const toggleDateRangeModal = (value) => {
    setOpenDateRangeModal(value)
  }
  const searchTextHandler = (e) => {
    setSearchText(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
  }

  const DownloadProviderAndPatientsVisits = async ({
    start_date,
    end_date,
  }) => {
    const response = await dispatch(
      getProviderVisitsWithAllPatients({
        providerId,
        start_date,
        end_date,
        token,
      })
    )
    const visitsData = response.payload.data
    if (!visitsData.length) {
      toast.error('No visits found.')
      return
    }
    makeVisitsCSV(visitsData)
  }

  return (
    <Box sx={{ m: '2rem 3rem' }}>
      <Box className={classes.headBar}>
        <Box className={classes.heading}>
          {user.role === userRolesConstants.admin && (
            <Tooltip title="Back" placement="left">
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          <Typography className={classes.headingText}>
            {user.role !== userRolesConstants.admin
              ? 'My Patients'
              : 'Patients'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <Button
            variant="text"
            onClick={() => toggleDateRangeModal(true)}
            startIcon={<DownloadOutlinedIcon />}
          >
            Download patients visits in CSV
          </Button>
          <Button
            variant="text"
            onClick={() => createPatientDataInCSV(token, providerId)}
            startIcon={<DownloadOutlinedIcon />}
          >
            Download patients data in CSV
          </Button>
        </Box>
        <DateRangeForm
          toggleDateRangeModal={toggleDateRangeModal}
          dialogTitle="Select Date Range"
          open={openDateRangeModal}
          handleConfirm={async (startDate, endDate) => {
            await DownloadProviderAndPatientsVisits({
              start_date: moment(startDate).startOf('day').utc().format(),
              end_date: moment(endDate).endOf('day').utc().format(),
            })
          }}
        />
      </Box>
      {isEmpty(patientData) && status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : isEmpty(patientData) && status === constants.succeeded ? (
        <NoRecordFound msg="No patient found" />
      ) : (
        <>
          <Grid container spacing={4}>
            <CssBaseline />
            <Grid item xs={12}>
              <TableSearchHeader
                handleClearSearch={handleClearSearch}
                searchText={searchText}
                itemName="Patient Name"
                searchTextHandler={searchTextHandler}
                totalItems={filteredList.length}
              />
              <PatientTable
                dataList={filteredList}
                COLUMNS={COLUMNS}
                hideActions={true}
                showManualAction={true}
                access="patient"
                ManualAction={ManualActions}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default ProviderPatients

export const ManualActions = ({ id, userId }) => {
  const history = useHistory()
  const params = useParams()
  const { user } = useSelector((state) => state.currentUserState)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <DownloadVisitsOfPatientAndProvider id={id} />
      {user.role === userRolesConstants.admin && (
        <Tooltip title="See this patient's Registration Tokens">
          <IconButton
            onClick={() =>
              history.push(
                `/admin/providers/${params.id}/patients/${userId}/registration-tokens`
              )
            }
          >
            <TokenIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

ManualActions.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string,
}

ManualActions.defaultProps = {
  id: '',
  userId: '',
}
