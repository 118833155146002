import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import { constants } from 'utils/constants'
import {
  fetchProviderDegree,
  addProviderDegree,
  updateProviderDegree,
  deleteProviderDegree,
} from 'api-client/ProviderDegreeData/thunks'
import { toast } from 'react-toastify'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const providerDegreeDataSlice = createSlice({
  name: 'providerDegreeData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProviderDegree.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchProviderDegree.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [fetchProviderDegree.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [addProviderDegree.pending]: (state) => {
      state.status = constants.loading
    },
    [addProviderDegree.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data.push(action.payload.data)
      toast('Education Added Successfully.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
      state.error = null
    },
    [addProviderDegree.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [updateProviderDegree.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderDegree.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      const values = action.meta.arg[3]
      state.data.map((row) => {
        if (row.id === action.meta.arg[2]) {
          row.degree = values.degree
          row.school = values.school
          row.state = values.state
          row.concentration = values.concentration
          row.graduation_year = values.graduation_year
        }
        return true
      })
      toast('Provider Education Updated.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
      state.error = null
    },
    [updateProviderDegree.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [deleteProviderDegree.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteProviderDegree.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = state.data.filter((row) => row.id !== action.meta.arg[2])
      toast.success('Education Deleted Successfully.')
      state.error = null
    },
    [deleteProviderDegree.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.data = []
      state.provider = {}
      state.status = constants.idle
      state.error = null
    },
  },
})

export const getProviderDegreeList = (state) =>
  state.providerDegreeDataState.data
export default providerDegreeDataSlice.reducer
