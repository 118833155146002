import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  mainContainer: {
    color: 'rgba(0,0,0,0.7)',
    marginTop: '20px',
  },
  mainCard: {
    boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
    borderRadius: '16px',
    padding: '15px',
  },
  tabsHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    borderBottom: 1,
    borderColor: 'divider',
  },
}))

export default useStyles
