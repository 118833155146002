import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchScheduleExceptions = createAsyncThunk(
  'scheduleExceptions/fetchScheduleExceptions',
  async (requestObj, { rejectWithValue }) => {
    const { token, providerId, params } = requestObj
    try {
      const response = await axios.get(
        `${baseApiUrl}/providerCalendarExceptions/${providerId}`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const addScheduleException = createAsyncThunk(
  'scheduleExceptions/addScheduleException',
  async (requestObj, { rejectWithValue }) => {
    const { token, providerId, body } = requestObj
    try {
      const response = await axios.post(
        `${baseApiUrl}/providerCalendarExceptions/${providerId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      response.data.exceptionsType = requestObj.exceptionsType
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteScheduleException = createAsyncThunk(
  'scheduleExceptions/deleteScheduleException',
  async (requestObj, { rejectWithValue }) => {
    const { token, scheduleExceptionId } = requestObj
    try {
      const response = await axios.delete(
        `${baseApiUrl}/providerCalendarExceptions/${scheduleExceptionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
