import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchAllergies,
  postAllergy,
  updateAllergy,
  deleteAllergy,
} from 'api-client/AllergyData/thunks'

const initialState = {
  data: [],
  status: constants.idle,
  error: null,
}

const allergyDataSlice = createSlice({
  name: 'allergyData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllergies.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchAllergies.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [fetchAllergies.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postAllergy.pending]: (state) => {
      state.status = constants.loading
    },
    [postAllergy.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload.data]
      state.status = constants.succeeded
      state.error = null
      toast.success('Allergy Added')
    },
    [postAllergy.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateAllergy.pending]: (state) => {
      state.status = constants.loading
    },
    [updateAllergy.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          row.allergy = action.meta.arg[2]['allergy']
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Allergy Updated')
    },
    [updateAllergy.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
    [deleteAllergy.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteAllergy.fulfilled]: (state, action) => {
      state.data = state.data.filter((row) => {
        if (row.id === action.meta.arg[1]) {
          return false
        }
        return true
      })
      state.status = constants.succeeded
      state.error = null
      toast.success('Allergy Deleted')
    },
    [deleteAllergy.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getAllergyList = (state) => state.allergyDataState.data
export const allergyStatus = (state) => state.allergyDataState.status

export default allergyDataSlice.reducer
