import { object, string } from 'yup'

export const medicalTrainingSchema = object().shape({
  program: string().label('Program Name').required(),
  hospital: string().label('Hospital Name').required(),
  state: string().label('State').required(),
  program_type: string().label('Program Type').required(),
  concentration: string().label('Concentration'),
  graduation_year: string().label('Graduation Year').required(),
  board_status: string().label('Board Status').required(),
})
