import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ButtonGroup,
} from '@mui/material'
import {
  fetchComplaints,
  deleteComplaint,
  updateComplaint,
  postComplaint,
} from 'api-client/ComplaintData/thunks'
import {
  getAdminComplaintList,
  getProviderComplaintList,
} from 'store/ComplaintData'
import { loggedInUserInfo, loggedInUserToken } from 'store/CurrentUser'
import useStyles from 'views/Provider/Complaint/styles'
import { constants } from 'utils/constants'
import { complaintValidationSchema } from 'schema/ComplaintForm'
import CustomSingleTextModal from 'components/CustomSingleTextModal'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import DefaultComplaints from 'views/Provider/Complaint/DefaultComplaints'
import ProviderComplaints from 'views/Provider/Complaint/ProviderComplaints'
import { COMPLAINT_TYPE } from 'utils/constants/complaintsType'
import DisabledPage from 'views/DisabledPage'
import NoRecordFound from 'components/NoRecordFound'

const ComplaintList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openComplaintModal, setOpenComplaintModal] = useState(false)
  const [complaintsType, setComplaintsType] = useState(COMPLAINT_TYPE.DEFAULT)
  const token = useSelector((state) => loggedInUserToken(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const admin_complaints = useSelector((state) => getAdminComplaintList(state))
  const provider_complaints = useSelector((state) =>
    getProviderComplaintList(state)
  )
  const { status } = useSelector((state) => state.complaintDataState)

  useEffect(() => {
    dispatch(fetchComplaints(token))
  }, [])

  const handleOpenComplaintModal = () => {
    setOpenComplaintModal(true)
  }

  const handleCloseComplaintModal = () => {
    setOpenComplaintModal(false)
  }

  const handleAdd = (type, values) => {
    const matchedComplaints = provider_complaints.filter(
      ({ complaint }) =>
        complaint.toLowerCase() === values.complaint.toLowerCase()
    )
    if (!matchedComplaints.length) {
      const requestBody = [token, values]
      dispatch(postComplaint(requestBody))
    } else {
      toast.error('This complaint already exists.')
    }
  }

  const handleUpdate = (type, complaint_id, values) => {
    if (type === 'provider') {
      const sameComplaint = provider_complaints.find(
        ({ id }) => id === complaint_id
      )
      if (
        sameComplaint.complaint.toLowerCase() ===
          values.complaint.toLowerCase() &&
        sameComplaint.patient_visibility === values.patient_visibility &&
        sameComplaint.provider_visibility === values.provider_visibility
      ) {
        toast.warning('Nothing was changed.')
      } else {
        const otherComplaints = provider_complaints.filter(
          ({ id }) => id !== complaint_id
        )
        const matchedComplaints = otherComplaints.find(
          ({ complaint }) =>
            complaint.toLowerCase() === values.complaint.toLowerCase()
        )
        if (!matchedComplaints) {
          const requestBody = [token, complaint_id, values]
          dispatch(updateComplaint(requestBody))
        } else {
          toast.error('This complaint already exists.')
        }
      }
    } else if (type === 'default') {
      const sameComplaint = admin_complaints.find(
        ({ id }) => id === complaint_id
      )
      if (
        sameComplaint.patient_visibility === values.patient_visibility &&
        sameComplaint.provider_visibility === values.provider_visibility
      ) {
        toast.warning('Nothing was changed.')
      } else {
        const requestBody = [token, complaint_id, values]
        dispatch(updateComplaint(requestBody))
      }
    }
  }

  const handleDelete = (id) => {
    const requestBody = [token, id]
    dispatch(deleteComplaint(requestBody))
  }

  const handleSetDefaultComplaints = () => {
    setComplaintsType(COMPLAINT_TYPE.DEFAULT)
  }

  const handleSetProviderComplaints = () => {
    setComplaintsType(COMPLAINT_TYPE.PROVIDER)
  }

  if (!userInfo.approved_on) return <DisabledPage />

  return (
    <Box sx={{ m: '2rem 3rem' }}>
      <Typography className={classes.headerTitle}>
        Visit Complaints Reason
      </Typography>
      <Box className={classes.buttonContainer}>
        <ButtonGroup variant="contained">
          <Button
            variant="contained"
            className={
              complaintsType === COMPLAINT_TYPE.DEFAULT
                ? classes.activeBtn
                : classes.inActiveBtn
            }
            onClick={handleSetDefaultComplaints}
            color="secondary"
          >
            Default
          </Button>
          <Button
            variant="contained"
            className={
              complaintsType === COMPLAINT_TYPE.PROVIDER
                ? classes.activeBtn
                : classes.inActiveBtn
            }
            onClick={handleSetProviderComplaints}
            color="secondary"
          >
            Custom
          </Button>
        </ButtonGroup>

        {complaintsType === COMPLAINT_TYPE.PROVIDER && (
          <Button
            startIcon={<AddIcon />}
            className={classes.addButton}
            onClick={handleOpenComplaintModal}
            color="secondary"
          >
            Add New
          </Button>
        )}
      </Box>

      {complaintsType === COMPLAINT_TYPE.PROVIDER && (
        <>
          {isEmpty(provider_complaints) && status === constants.loading ? (
            <Box className={classes.loader}>
              <CircularProgress size={60} />
            </Box>
          ) : isEmpty(provider_complaints) && status === constants.succeeded ? (
            <NoRecordFound msg="No complaint found" />
          ) : (
            <ProviderComplaints
              providerComplaints={provider_complaints}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
            />
          )}
        </>
      )}

      {complaintsType === COMPLAINT_TYPE.DEFAULT && (
        <>
          {isEmpty(admin_complaints) && status === constants.loading ? (
            <Box className={classes.loader}>
              <CircularProgress size={60} />
            </Box>
          ) : isEmpty(admin_complaints) && status === constants.succeeded ? (
            <NoRecordFound msg="No complaint found" />
          ) : (
            <DefaultComplaints
              handleUpdate={handleUpdate}
              admin_complaints={admin_complaints}
            />
          )}
        </>
      )}

      <CustomSingleTextModal
        handleCloseModal={handleCloseComplaintModal}
        initialValues={{ complaint: '' }}
        edit={false}
        handleAddOrUpdate={handleAdd}
        dialogTitle="Complaint"
        open={openComplaintModal}
        schema={complaintValidationSchema}
      />
    </Box>
  )
}
export default ComplaintList
