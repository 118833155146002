import React, { useEffect } from 'react'
import useStyles from 'views/Admin/Dashboard/styles'
import { Grid, Container, Box, CircularProgress } from '@mui/material'
import PieChart from 'views/Admin/Dashboard/VisitStatusPieChart'
import YearlyVisitsLineChart from 'views/Admin/Dashboard/YearlyVisitsLineChart'
import PatientCard from 'views/Admin/Dashboard/PatientCard'
import ApprovedProviderCard from 'views/Admin/Dashboard/ApprovedProviderCard'
import UnApprovedProviderCard from 'views/Admin/Dashboard/UnApprovedProviderCard'
import VisitCard from 'views/Admin/Dashboard/VisitCard'

import { useDispatch, useSelector } from 'react-redux'
import {
  fetchUserCount,
  fetchProviderStatusCount,
  fetchVisitStatusCount,
} from 'api-client/DashboardData/thunks'
import { loggedInUserToken } from 'store/CurrentUser'
import {
  getUserCount,
  getProviderStatusCount,
  getVisitStatusCount,
} from 'store/DashboardData'
import { constants } from 'utils/constants'

const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const token = useSelector((state) => loggedInUserToken(state))

  useEffect(() => dispatch(fetchUserCount(token)), [])
  useEffect(() => dispatch(fetchProviderStatusCount(token)), [])
  useEffect(() => dispatch(fetchVisitStatusCount(token)), [])

  const userCount = useSelector((state) => getUserCount(state))
  const providerStatusCount = useSelector((state) =>
    getProviderStatusCount(state)
  )
  const visitStatusCount = useSelector((state) => getVisitStatusCount(state))

  const { userCountSt, visitStatusCountSt, providerStatusCountSt } =
    useSelector((state) => state.dashboardDataState)

  let status = constants.loading

  if (
    userCountSt !== constants.loading &&
    visitStatusCountSt !== constants.loading &&
    providerStatusCountSt !== constants.loading
  )
    status = constants.succeeded

  return (
    <>
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Container
          className={classes.mainContainer}
          component="main"
          maxWidth="full"
        >
          <h1>Dashboard</h1>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            direction="row"
            spacing={4}
          >
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <PatientCard count={userCount.patients} />
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <ApprovedProviderCard count={providerStatusCount.approved} />
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <UnApprovedProviderCard
                  count={providerStatusCount.not_approved}
                />
              </Box>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box>
                <VisitCard count={visitStatusCount.totalVisits} />
              </Box>
            </Grid>

            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
              <YearlyVisitsLineChart />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <PieChart data={visitStatusCount} />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default Dashboard
