import React from 'react'
import { PropTypes } from 'prop-types'
import { Box, Card, Typography } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import useStyles from 'views/Admin/Dashboard/UnApprovedProviderCard/styles'
import { useHistory } from 'react-router-dom'
import { setFilterProviderType } from 'store/ProvidersData'
import { PROVIDER_TYPE_FILTERS } from 'utils/constants/providerTypeFilters'
import { useDispatch } from 'react-redux'

const UnApprovedProviderCard = ({ count }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleCardClick = () => {
    dispatch(setFilterProviderType({ type: PROVIDER_TYPE_FILTERS.UN_APPROVED }))
    history.push('/admin/providers')
  }

  return (
    <Card className={classes.card} onClick={handleCardClick}>
      <Box className={classes.iconWrapper}>
        <AdminPanelSettingsIcon fontSize="large" />
      </Box>
      <Typography variant="h3">{count}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        UnApproved Providers
      </Typography>
    </Card>
  )
}

UnApprovedProviderCard.propTypes = {
  count: PropTypes.number.isRequired,
}

export default UnApprovedProviderCard
