import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  search: {
    width: '350px',
    padding: '4px 4px',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid #e0e0e0',
  },
  searchBox: {
    width: '375px',
  },
  searchHeadeer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.3rem',
    backgroundColor: 'white',
    border: '1px solid #eeeeee',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    padding: '0.6rem',
  },
  totalText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  total: {
    backgroundColor: '#e0e0e0',
    padding: '0.2rem 0.6rem',
    textAlign: 'center',
    borderRadius: '12px',
    maxWidth: '80px',
    marginLeft: '0.4rem',
    fontWeight: 500,
  },
})

export default useStyles
