import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import { useTable, useSortBy } from 'react-table'
import {
  Table as MUITable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Box,
  Tooltip,
  TableContainer,
  Typography,
} from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import useStyles from 'components/BasicTable/styles'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import NoRecordFound from 'components/NoRecordFound'

const Table = ({
  dataList,
  COLUMNS,
  access,
  handleDelete,
  handleOpenModalForEdit,
  hideActions,
  setSortOn,
  initialSort,
  onRowClick,
  extraStyles,
  hideEdit,
  showManualAction = false,
  ManualAction,
  customColumnName,
  CustomColumn,
}) => {
  const classes = useStyles()
  const [id, setId] = React.useState('')
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false)

  const openDeleteConfirmationDialog = (cell) => {
    setId(cell.row.original.id)
    setOpenDeleteConfirmation(true)
  }

  const closeDeleteConfirmationDialog = () => {
    setId('')
    setOpenDeleteConfirmation(false)
  }
  const data = React.useMemo(() => dataList, [dataList])
  const columns = React.useMemo(() => COLUMNS, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: initialSort },
      manualPagination: false,
    },
    useSortBy
  )

  useEffect(() => {
    setSortOn(sortBy)
  }, [sortBy])

  const onConfirm = () => {
    handleDelete(id)
  }

  return dataList.length ? (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <MUITable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup}
                {...headerGroup.getHeaderGroupProps()}
              >
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  #
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    key={column}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <Typography variant="body2" className={classes.headTitle}>
                      {column.render('Header')}
                      {column.canSort && !column.isSorted && (
                        <SwapVertIcon fontSize="small" />
                      )}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )
                      ) : (
                        ''
                      )}
                    </Typography>
                  </TableCell>
                ))}
                {!hideActions && (
                  <TableCell>
                    <Typography
                      variant="body2"
                      className={classes.actionHeader}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                )}
                {showManualAction && (
                  <TableCell>
                    <Typography
                      variant="body2"
                      className={classes.actionHeader}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <TableRow
                  className={classes.row}
                  key={row}
                  onClick={() => onRowClick(row.original.id)}
                  sx={extraStyles}
                  {...row.getRowProps()}
                >
                  <TableCell sx={{ fontWeight: 'bold' }}>{index + 1}</TableCell>
                  {row.cells.map((cell) => (
                    <TableCell key={row} {...cell.getCellProps()}>
                      {cell.column.id === customColumnName ? (
                        <CustomColumn value={cell.value} />
                      ) : (
                        cell.render('Cell')
                      )}
                    </TableCell>
                  ))}
                  {!hideActions && (
                    <TableCell>
                      <Box className={classes.iconBox}>
                        {!hideEdit && (
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              onClick={() => {
                                handleOpenModalForEdit(row.cells[0])
                              }}
                              className="iconButton"
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Delete" placement="top">
                          <IconButton
                            className="iconButton"
                            onClick={() => {
                              openDeleteConfirmationDialog(row.cells[0])
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  )}
                  {showManualAction && (
                    <TableCell>
                      <ManualAction
                        id={row.original.id}
                        destroyTime={row.original.destroyTime}
                        userId={row.original.userId ? row.original.userId : ''}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </MUITable>
        <ConfirmDialogBox
          closeModal={closeDeleteConfirmationDialog}
          open={openDeleteConfirmation}
          dialogTitle={`Confirm Delete ${capitalize(access)}`}
          dialogText={`Are you sure you want to delete ${capitalize(access)}?`}
          handleConfirm={onConfirm}
        />
      </TableContainer>
    </>
  ) : (
    <NoRecordFound msg={`No ${access} found`} />
  )
}

Table.propTypes = {
  dataList: PropTypes.array.isRequired,
  access: PropTypes.string,
  handleDelete: PropTypes.func,
  COLUMNS: PropTypes.array.isRequired,
  handleOpenModalForEdit: PropTypes.func,
  hideActions: PropTypes.bool,
  setSortOn: PropTypes.func,
  initialSort: PropTypes.array,
  onRowClick: PropTypes.func,
  extraStyles: PropTypes.object,
  hideEdit: PropTypes.bool,
  showManualAction: PropTypes.bool,
  ManualAction: PropTypes.func,
  customColumnName: PropTypes.string,
  CustomColumn: PropTypes.func,
}

Table.defaultProps = {
  hideActions: false,
  access: '',
  handleDelete: () => {},
  handleOpenModalForEdit: () => {},
  setSortOn: () => {},
  onRowClick: () => {},
  initialSort: [],
  extraStyles: {},
  hideEdit: false,
  showManualAction: false,
  ManualAction: () => <Box>Manual Action</Box>,
  customColumnName: '',
  CustomColumn: () => <Box>Custom Column</Box>,
}

export default Table
