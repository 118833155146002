import { makeStyles } from '@mui/styles'
import theme from 'config/theme'

const useStyles = makeStyles({
  closeIcon: {
    color: 'rgba(255, 0, 0, 0.5)',
  },
  closeButton: {
    marginLeft: 'auto',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
  },
  topPaper: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: 20,
    backgroundColor: theme.palette.background.default,
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  image: {
    maxHeight: 600,
    display: 'flex',
    justifyContent: 'center',
    objectFit: 'contain',
  },
})

export default useStyles
