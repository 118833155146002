import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchPracticeTerms = createAsyncThunk(
  'providerPracticeTermsData/fetchPracticeTerms',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/providers/${
          requestObj[1] && requestObj[1]
        }/practiceTerms`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updatePracticeTerms = createAsyncThunk(
  'providerPracticeTermsData/updatePracticeTerms',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/providers/${
          requestObj[1] && requestObj[1]
        }/practiceTerms`,
        requestObj[2] && requestObj[2],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
