import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchTrainingBoardStatuses = createAsyncThunk(
  'trainingBoardStatusData/fetchTrainingBoardStatuses',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/trainingBoardStatus`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postTrainingBoardStatus = createAsyncThunk(
  'trainingBoardStatusData/postTrainingBoardStatus',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/trainingBoardStatus`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateTrainingBoardStatus = createAsyncThunk(
  'trainingBoardStatusData/updateTrainingBoardStatus',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/trainingBoardStatus/${requestObj[1] && requestObj[1]}`,
        requestObj[2] && requestObj[2],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteTrainingBoardStatus = createAsyncThunk(
  'trainingBoardStatusData/deleteTrainingBoardStatus',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/trainingBoardStatus/${requestObj[1] && requestObj[1]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
