import React from 'react'
import {
  Typography,
  Toolbar,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  ListItemButton,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { AppBar, Drawer, useStyles } from 'views/NavDrawer/styles'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { Link, useHistory } from 'react-router-dom'
import { DrawerHeader } from 'components/DrawerHeader'
import { useDispatch, useSelector } from 'react-redux'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import PropTypes from 'prop-types'
import { userRolesConstants } from 'utils/constants'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import Logo from 'assets/images/LOGO.png'
import theme from 'config/theme'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import { capitalize } from 'lodash'
import SettingsIcon from '@mui/icons-material/Settings'
const LOGOUT_BOX_COLOR = theme.palette.error.darker

const NavDrawer = ({ routes }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true)
  const [openLogout, setOpenLogout] = React.useState(false)

  const history = useHistory()

  const openLogoutDialog = () => {
    setOpenLogout(true)
  }

  const closeLogoutDialog = () => {
    setOpenLogout(false)
  }

  const handleLogout = () => {
    dispatch(logoutUser())
    history.push('/')
  }
  const classes = useStyles()

  const user = useSelector((state) => loggedInUser(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  let logoRoute
  if (user.role === userRolesConstants.provider) logoRoute = '/provider'
  else if (user.role === userRolesConstants.admin) logoRoute = '/admin'

  let providerDegree = ''

  if (user.role === userRolesConstants.provider) {
    const providerType = userInfo['Provider_type'].type
    const splittedType = providerType.split('(')
    providerDegree = splittedType[1].replace(')', '')
  }

  const handleDrawer = () => {
    setOpen(!open)
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Tooltip title="Go to Dashboard">
            <Link to={logoRoute} className={classes.link}>
              <img
                src={Logo}
                className={classes.logo}
                width="120"
                alt="HealHT LOGO"
              />
            </Link>
          </Tooltip>
          {user.role === userRolesConstants.provider ? (
            <Tooltip title="My Profile">
              <IconButton
                className={classes.personIcon}
                onClick={() => history.push(`/provider/${userInfo.id}/profile`)}
              >
                <PersonRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <PersonRoundedIcon className={classes.personIcon} />
          )}
          <Typography className={classes.welcomeUsername}>
            {user.role === userRolesConstants.provider
              ? `${capitalize(userInfo.first_name)} ${capitalize(
                  userInfo.last_name
                )}, ${providerDegree}`
              : 'Admin'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader></DrawerHeader>
        <List
          className={
            user.role === userRolesConstants.provider && !userInfo.approved_on
              ? classes.disabledList
              : classes.activeList
          }
        >
          {user.role === userRolesConstants.provider && (
            <ListItem
              onClick={() => history.push(`/provider/${userInfo.id}/profile`)}
              disablePadding
              sx={{
                backgroundColor:
                  `/provider/${userInfo.id}/profile` ===
                  history.location.pathname
                    ? 'lightgray'
                    : '',
              }}
            >
              {open ? (
                <ListItemButton>
                  <ListItemIcon className={classes.sidebarIcon}>
                    <PersonRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={'My Profile'} />
                </ListItemButton>
              ) : (
                <Tooltip title="My Profile" placement="right">
                  <ListItemButton>
                    <ListItemIcon className={classes.sidebarIcon}>
                      <PersonRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'My Profile'} />
                  </ListItemButton>
                </Tooltip>
              )}
            </ListItem>
          )}
          {routes &&
            Object.keys(routes).map(
              (key) =>
                routes[key][0] &&
                routes[key][1] && (
                  <Link to={key} key={key} className={classes.link}>
                    {!open ? (
                      <Tooltip title={routes[key][0]} placement="right">
                        <ListItem
                          sx={{
                            backgroundColor:
                              key === history.location.pathname
                                ? 'lightgray'
                                : '',
                          }}
                          disablePadding
                        >
                          <ListItemButton
                            disabled={
                              user.role === userRolesConstants.provider &&
                              !userInfo.approved_on
                            }
                          >
                            <ListItemIcon className={classes.sidebarIcon}>
                              {routes[key][1]}
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              primary={routes[key][0]}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    ) : (
                      <ListItem
                        sx={{
                          backgroundColor:
                            key === history.location.pathname
                              ? 'rgba(100,100,100,0.2)'
                              : '',
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          disabled={
                            user.role === userRolesConstants.provider &&
                            !userInfo.approved_on
                          }
                        >
                          <ListItemIcon className={classes.sidebarIcon}>
                            {routes[key][1]}
                          </ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={routes[key][0]}
                          />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </Link>
                )
            )}
        </List>
        <Divider />
        <List className={classes.logoutList}>
          {!open ? (
            <Tooltip title="Log Out" placement="right">
              <ListItem
                onClick={() => history.push(`/${user?.role}/settings`)}
                sx={{
                  backgroundColor:
                    `/${user?.role}/settings` === history.location.pathname
                      ? 'lightgray'
                      : '',
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon className={classes.sidebarIcon}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ) : (
            <ListItem
              disablePadding
              onClick={() => history.push(`/${user?.role}/settings`)}
              sx={{
                backgroundColor:
                  `/${user?.role}/settings` === history.location.pathname
                    ? 'lightgray'
                    : '',
              }}
            >
              <ListItemButton>
                <ListItemIcon className={classes.sidebarIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
          )}
          {!open ? (
            <Tooltip title="Log Out" placement="right">
              <ListItem onClick={openLogoutDialog} disablePadding>
                <ListItemButton>
                  <ListItemIcon className={classes.sidebarIcon}>
                    <LogoutIcon sx={{ fill: LOGOUT_BOX_COLOR }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: LOGOUT_BOX_COLOR }}
                    primary={'Log Out'}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ) : (
            <ListItem onClick={openLogoutDialog} disablePadding>
              <ListItemButton>
                <ListItemIcon className={classes.sidebarIcon}>
                  <LogoutIcon sx={{ fill: LOGOUT_BOX_COLOR }} />
                </ListItemIcon>
                <ListItemText
                  sx={{ color: LOGOUT_BOX_COLOR }}
                  primary={'Log Out'}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ConfirmDialogBox
            closeModal={closeLogoutDialog}
            open={openLogout}
            dialogTitle="Confirm Logout"
            dialogText="Are you sure you want to logout?"
            handleConfirm={handleLogout}
          />
        </List>
      </Drawer>
    </>
  )
}

NavDrawer.propTypes = {
  routes: PropTypes.object.isRequired,
}

export default NavDrawer
