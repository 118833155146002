import React, { useEffect } from 'react'

import { Paper, Box, CardHeader, Link } from '@mui/material'
import Logo from 'assets/images/LOGO.png'
import { useDispatch } from 'react-redux'
import { refreshStatus } from 'store/PasswordAPIs'

const PasswordChangeFailPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(refreshStatus())
  })
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginTop: 8, mr: 2 }}>
        <img src={Logo} width="200" alt="HealHT LOGO" />
      </Box>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          '& > :not(style)': {
            mt: 10,
            p: 3,
          },
        }}
      >
        <Paper variant="outlined">
          <CardHeader
            titleTypographyProps={{
              variant: 'overline',
              display: 'block',
              fontSize: 20,
              gutterBottom: true,
            }}
            title="Password reset Link has expired"
            subheader={
              <Link href="/forgot-password" variant="body2">
                Request New Link
              </Link>
            }
            subheaderTypographyProps={{ variant: 'overline', fontSize: 15 }}
          />
        </Paper>
      </Box>
    </>
  )
}

export default PasswordChangeFailPage
