import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchUserCount = createAsyncThunk(
  'DashboardData/fetchUserCount',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/reports/usersCount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchVisitStatusCount = createAsyncThunk(
  'DashboardData/fetchVisitStatusCount',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/reports/visitStatusCount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchProviderStatusCount = createAsyncThunk(
  'DashboardData/fetchProviderStatusCount',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/reports/providerStatusCount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const fetchUserVisitsByYear = createAsyncThunk(
  'DashboardData/fetchUserVisitsByYear',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/reports/visitsByYear`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
