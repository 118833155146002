import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loader: {
    color: 'black',
    margin: '0 0 0 10px',
  },
})

export default useStyles
