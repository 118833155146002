import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import FormikTextField from 'components/FormikTextField'
import useStyles from 'views/Provider/Profile/Forms/MedicalTraining/styles'
import { getTrainingProgramList } from 'store/TrainingProgramData'
import { loggedInUserToken } from 'store/CurrentUser'
import { fetchTrainingPrograms } from 'api-client/TrainingProgramData/thunks'
import { getTrainingBoardStatusList } from 'store/TrainingBoardStatusData'
import { fetchTrainingBoardStatuses } from 'api-client/TrainingBoardStatusData/thunks'
import { YEARS } from 'utils/constants/years'
import { getStatesList } from 'store/StatesData'
import { fetchStates } from 'api-client/StatesData/thunks'
import {
  addProviderMedicalTraining,
  updateProviderMedicalTraining,
} from 'api-client/ProviderMedicalTrainingData/thunks'
import { getProvider } from 'store/ProvidersData'
import { medicalTrainingSchema } from 'schema/MedicalTrainingForm'
import { sortBy } from 'lodash'
import { statesMap } from 'utils/constants/states'

const MedicalTrainingsForm = ({
  edit,
  trainingInitialValues,
  isOpenModal,
  handleCloseModal,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const token = useSelector((state) => loggedInUserToken(state))
  const provider = useSelector((state) => getProvider(state))
  const trainingProgramList = useSelector((state) =>
    getTrainingProgramList(state)
  )
  const trainingBoardStatusList = useSelector((state) =>
    getTrainingBoardStatusList(state)
  )
  const statesList = useSelector((state) => getStatesList(state))

  useEffect(() => {
    if (!trainingProgramList.length) {
      dispatch(fetchTrainingPrograms(token))
    }
    if (!trainingBoardStatusList.length) {
      dispatch(fetchTrainingBoardStatuses(token))
    }
    if (!statesList.length) {
      dispatch(fetchStates(token))
    }
  }, [])

  const initialTrainingProgram = trainingProgramList.find(
    ({ id }) => id === trainingInitialValues.training_program_id
  )
  const initialTrainingBoardStatus = trainingBoardStatusList.find(
    ({ id }) => id === trainingInitialValues.training_board_status_id
  )

  const mappedState = statesMap[trainingInitialValues?.state]
  const updatedMappedState = mappedState
    ? `${mappedState}, ${trainingInitialValues?.state}` ?? ''
    : ''

  const initialValues = {
    program: trainingInitialValues?.program ?? '',
    hospital: trainingInitialValues?.hospital ?? '',
    state: updatedMappedState,
    program_type: initialTrainingProgram?.program ?? '',
    concentration: trainingInitialValues?.concentration ?? '',
    graduation_year: trainingInitialValues?.graduation_year ?? '',
    board_status: initialTrainingBoardStatus?.status ?? '',
  }

  const handleSubmit = (values) => {
    const finalTrainingProgram = trainingProgramList.find(
      ({ program }) => program === values.program_type
    )
    const finalTrainingBoardStatus = trainingBoardStatusList.find(
      ({ status }) => status === values.board_status
    )
    const updatedState = values.state.split(',')
    const updatedValues = {}
    updatedValues.program = values.program
    updatedValues.hospital = values.hospital
    updatedValues.state = updatedState[1].trim()
    updatedValues.concentration = values.concentration
    updatedValues.graduation_year = values.graduation_year
    updatedValues.training_program_id = finalTrainingProgram.id
    updatedValues.training_board_status_id = finalTrainingBoardStatus.id

    if (!edit) {
      dispatch(addProviderMedicalTraining([token, provider.id, updatedValues]))
    }
    if (edit) {
      dispatch(
        updateProviderMedicalTraining([
          token,
          provider.id,
          trainingInitialValues.id,
          updatedValues,
        ])
      )
    }
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        {edit ? 'Edit' : 'Add'} Medical Training
      </DialogTitle>

      <DialogContent>
        <Box className={classes.form}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={medicalTrainingSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormikTextField
                      className={classes.field}
                      name="program"
                      label="Program Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="hospital"
                      label="Name Of Hospital"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="state"
                      label="State Of Hospital"
                      select={true}
                      accessor="name"
                      accessor2="code"
                      items={sortBy(statesList, (e) => e.name.toLowerCase())}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="program_type"
                      label="Program"
                      select={true}
                      accessor="program"
                      items={sortBy(trainingProgramList, (e) =>
                        e.program.toLowerCase()
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="concentration"
                      label="Concentration"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="graduation_year"
                      label="Year Of Graduation"
                      select={true}
                      accessor="value"
                      items={YEARS}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField
                      className={classes.field}
                      name="board_status"
                      label="Board Status"
                      select={true}
                      accessor="status"
                      items={sortBy(trainingBoardStatusList, (e) =>
                        e.status.toLowerCase()
                      )}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    {edit ? 'Save' : 'Add'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default MedicalTrainingsForm

MedicalTrainingsForm.propTypes = {
  edit: PropTypes.bool.isRequired,
  trainingInitialValues: PropTypes.object,
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

MedicalTrainingsForm.defaultProps = {
  trainingInitialValues: {},
}
