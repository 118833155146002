import { createSlice } from '@reduxjs/toolkit'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import { constants } from 'utils/constants'
import { getPatientDataAgainstProvider } from 'api-client/PatientData/thunks'
import { toast } from 'react-toastify'

const initialState = {
  patientData: [],
  status: constants.idle,
  error: null,
}

const patientsDataSlice = createSlice({
  name: 'patientsData',
  initialState,
  reducers: {},
  extraReducers: {
    [getPatientDataAgainstProvider.pending]: (state) => {
      state.status = constants.loading
    },
    [getPatientDataAgainstProvider.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data
      state.error = null
    },
    [getPatientDataAgainstProvider.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.data = []
      state.provider = {}
      state.status = constants.idle
      state.error = null
    },
  },
})

export const getPatientsData = (state) => state.patientsDataState.data
export default patientsDataSlice.reducer
