import { Chip } from '@mui/material'
import { style } from 'views/Admin/Speciality/styles'
import React from 'react'
import { DEFAULT_COLUMNS } from 'utils/constants/defaultColumns'
import { sortItemsInSensitive } from 'utils/helperFunctions'

export const COLUMNS = [
  {
    Header: 'Speciality name',
    accessor: 'speciality',
    sortType: (prev, curr, columnId) =>
      sortItemsInSensitive(prev, curr, columnId),
  },
  {
    disableSortBy: true,
    Header: 'Status',
    accessor: 'is_active',
    Cell: (row) => (
      <Chip
        label={row.value ? 'Active' : 'In Active'}
        size="small"
        sx={row.value ? style.activeChip : style.inActiveChip}
      />
    ),
  },
  ...DEFAULT_COLUMNS,
]
