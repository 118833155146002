import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'

import {
  postNewPassword,
  sendForgotPasswordEmail,
  getEmailTokenStatus,
} from 'api-client/PasswordAPIs/thunks'

const initialState = {
  data: '',
  status: constants.idle,
  error: null,
  tokenStatus: constants.idle,
  tokenError: '',
  tokenExpiryTime: 0,
}

const passwordAPIsDataSlice = createSlice({
  name: 'passwordAPIs',
  initialState,
  reducers: {
    refreshTokenStatus(state) {
      state.tokenError = ''
      state.tokenStatus = constants.idle
      state.tokenExpiryTime = 0
    },
    refreshStatus(state) {
      state.data = ''
      state.status = constants.idle
      state.error = null
      state.tokenError = ''
      state.tokenStatus = constants.idle
      state.tokenExpiryTime = 0
    },
  },
  extraReducers: {
    [getEmailTokenStatus.pending]: (state) => {
      state.tokenStatus = constants.loading
      state.tokenError = ''
      state.tokenExpiryTime = 0
    },
    [getEmailTokenStatus.fulfilled]: (state, action) => {
      state.tokenStatus = constants.succeeded
      state.tokenError = ''
      state.tokenExpiryTime = action.payload.data.timeLeftInTokenExpiry
    },
    [getEmailTokenStatus.rejected]: (state, action) => {
      if (action.payload.response && action.payload.response.status === 401) {
        state.tokenError = 'Password Link Expired. Please request another'
      } else if (
        action.payload.response &&
        action.payload.response.status === 400
      ) {
        state.tokenError = action.payload.response.data.msg
      } else {
        state.tokenError = action.payload.message
      }
      state.tokenStatus = constants.failed
      state.tokenExpiryTime = 0
    },
    [postNewPassword.pending]: (state) => {
      state.status = constants.loading
      state.error = ''
      state.data = ''
    },
    [postNewPassword.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.status = constants.succeeded
      state.error = null
    },
    [postNewPassword.rejected]: (state, action) => {
      state.data = ''
      if (action.payload.response && action.payload.response.status === 401) {
        state.error = 'Password Link Expired. Please request another'
      } else if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      state.status = constants.failed
    },
    [sendForgotPasswordEmail.fulfilled]: (state, action) => {
      toast.success(action.payload.data.email)
    },
    [sendForgotPasswordEmail.rejected]: (state, action) => {
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const { refreshTokenStatus, refreshStatus } =
  passwordAPIsDataSlice.actions

export default passwordAPIsDataSlice.reducer
