import React from 'react'
import useStyles from 'views/Provider/Dashboard/styles'
import { useSelector } from 'react-redux'
import { loggedInUserInfo } from 'store/CurrentUser'
import DisabledPage from 'views/DisabledPage'
import { Typography, Box } from '@mui/material'

const Dashboard = () => {
  const classes = useStyles()
  const userInfo = useSelector((state) => loggedInUserInfo(state))

  if (!userInfo.approved_on) return <DisabledPage />

  return (
    <>
      <Box>
        <Typography className={classes.heading}>
          Welcome to Providers Dashboard
        </Typography>
      </Box>
      <Box className={classes.mainContent}>
        <Typography>
          Here you will find analytics on how you use healht with your patients.
        </Typography>
      </Box>
    </>
  )
}

export default Dashboard
