import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Form, Formik } from 'formik'
import { Box, CircularProgress, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import useStyles from 'components/DateRangeForm/styles'
import { dateRangeFormSchema } from 'schema/DateRangeForm'

const DateRangeForm = ({
  toggleDateRangeModal,
  handleConfirm,
  dialogTitle,
  open,
}) => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    startDate: moment(Date.now()),
    endDate: moment(Date.now()),
  }
  const classes = useStyles()

  const handleSubmit = async (values) => {
    setLoading(true)
    await handleConfirm(values.startDate, values.endDate)
    setLoading(false)
    toggleDateRangeModal(false)
  }
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        if (!loading) toggleDateRangeModal(false)
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={dateRangeFormSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <Form className={classes.form}>
              <Box className={classes.fields}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Start Date"
                    disableFuture={true}
                    value={moment(values.startDate)}
                    onChange={(value) => {
                      setFieldValue('startDate', value)
                    }}
                    inputProps={{ readOnly: true }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!!(touched.startDate && errors.startDate)}
                        helperText={
                          touched.startDate && errors.startDate
                            ? errors.startDate
                            : ''
                        }
                        className={classes.field}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="End Date"
                    disableFuture={true}
                    value={moment(values.endDate)}
                    onChange={(value) => {
                      setFieldValue('endDate', value)
                    }}
                    inputProps={{ readOnly: true }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!!(touched.endDate && errors.endDate)}
                        helperText={
                          touched.endDate && errors.endDate
                            ? errors.endDate
                            : ''
                        }
                        className={classes.field}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>

              <Box className={classes.buttonBox}>
                <Button
                  className={classes.cancelButton}
                  variant="contained"
                  color="error"
                  disabled={loading}
                  onClick={() => toggleDateRangeModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.saveButton}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <CircularProgress size={16} color="secondary" />
                    ) : null
                  }
                >
                  Download
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default DateRangeForm

DateRangeForm.propTypes = {
  toggleDateRangeModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
}
