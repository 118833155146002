import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchComplaints,
  postComplaint,
  updateComplaint,
  deleteComplaint,
} from 'api-client/ComplaintData/thunks'
import { sortBy, sortedIndexBy } from 'lodash'

const initialState = {
  data: null,
  admin_complaints: [],
  provider_complaints: [],
  status: constants.idle,
  error: null,
}

const complaintDataSlice = createSlice({
  name: 'complaintData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchComplaints.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchComplaints.fulfilled]: (state, action) => {
      const response = action.payload.data
      state.status = constants.succeeded
      if (response['admin_complaints']) {
        state.admin_complaints = response.admin_complaints
      } else {
        const providerComplaints = response.filter((row) => row.provider_id)
        const adminComplaints = response.filter(
          (row) => row.provider_id === null
        )
        state.provider_complaints = sortBy(providerComplaints, (e) =>
          e.complaint.toLowerCase()
        )
        state.admin_complaints = sortBy(adminComplaints, (e) =>
          e.complaint.toLowerCase()
        )
      }
      state.error = null
    },
    [fetchComplaints.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [postComplaint.pending]: (state) => {
      state.status = constants.loading
    },
    [postComplaint.fulfilled]: (state, action) => {
      const response = action.payload.data
      if (response.provider_id) {
        const sortedIndex = sortedIndexBy(
          state.provider_complaints,
          response,
          (e) => e.complaint.toLowerCase()
        )
        state.provider_complaints.splice(sortedIndex, 0, response)
      } else {
        state.admin_complaints.push(response)
      }
      state.status = constants.succeeded
      state.error = null
      toast.success('Complaint Added')
    },
    [postComplaint.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateComplaint.pending]: (state) => {
      state.status = constants.loading
    },
    [updateComplaint.fulfilled]: (state, action) => {
      const response = action.payload.data
      if (response.provider_id) {
        const deletedRowindex = state.provider_complaints.findIndex(
          (e) => e.id === response.id
        )
        state.provider_complaints.splice(deletedRowindex, 1)
        const sortedIndex = sortedIndexBy(
          state.provider_complaints,
          response,
          (e) => e.complaint.toLowerCase()
        )
        state.provider_complaints.splice(sortedIndex, 0, response)
      } else {
        state.admin_complaints = state.admin_complaints.filter((row) => {
          if (row.id === response.id) {
            row.complaint = response.complaint
            row.patient_visibility = response.patient_visibility
            row.provider_visibility = response.provider_visibility
          }
          return true
        })
      }
      state.status = constants.succeeded
      state.error = null
      toast.success('Complaint Updated')
    },
    [updateComplaint.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.admin_complaints = []
      state.provider_complaints = []
      state.status = constants.idle
      state.error = null
    },

    [deleteComplaint.pending]: (state) => {
      state.status = constants.loading
    },
    [deleteComplaint.fulfilled]: (state, action) => {
      const response = action.payload.data
      if (response.provider_id) {
        state.provider_complaints = state.provider_complaints.filter(
          (row) => row.id !== response.id
        )
      } else {
        state.admin_complaints = state.admin_complaints.filter(
          (row) => row.id !== response.id
        )
      }
      state.status = constants.succeeded
      state.error = null
      toast.success('Complaint Deleted')
    },
    [deleteComplaint.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getAdminComplaintList = (state) =>
  state.complaintDataState.admin_complaints
export const getProviderComplaintList = (state) =>
  state.complaintDataState.provider_complaints
export const complaintStatus = (state) => state.complaintDataState.status

export default complaintDataSlice.reducer
