export const constants = Object.freeze({
  idle: 'idle',
  loading: 'loading',
  failed: 'failed',
  succeeded: 'succeeded',
})

export const isLinkRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const isValidLink = (value) => isLinkRegex.test(value)

export const userRolesConstants = Object.freeze({
  admin: 'admin',
  provider: 'provider',
  patient: 'patient',
})

export const dateTimeFormat = 'MMM DD, YYYY hh:mm:ss A'
export const dateFormat = 'MMM DD, YYYY'

export const monthFullNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const Day = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const SERVER_TIME_FORMAT = 'HH:mm:ss'
export const WEB_TIME_FORMAT = 'hh:mm A'
export const SERVER_DATE_FORMAT = 'yyyy-MM-DD'

export const ApiRequestMethod = Object.freeze({
  POST: 'post',
  GET: 'get',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete',
})

export const RestoreProviderInfoMsg =
  'Please restore the provider to perform any action.'
