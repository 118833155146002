import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@mui/material'

import useStyles from 'views/Admin/Dashboard/ApprovedProviderCard/styles'
import ProviderIcon from 'assets/images/provider-icon-48x48.png'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFilterProviderType } from 'store/ProvidersData'
import { PROVIDER_TYPE_FILTERS } from 'utils/constants/providerTypeFilters'

const ApprovedProviderCard = ({ count }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleCardClick = () => {
    dispatch(setFilterProviderType({ type: PROVIDER_TYPE_FILTERS.APPROVED }))
    history.push('/admin/providers')
  }
  return (
    <Card className={classes.card} onClick={handleCardClick}>
      <Box className={classes.iconWrapper}>
        <img
          src={ProviderIcon}
          className={classes.darkIcon}
          width="30"
          height="27"
          key={0}
          alt=""
        />
      </Box>
      <Typography variant="h3">{count}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Approved Providers
      </Typography>
    </Card>
  )
}

ApprovedProviderCard.propTypes = {
  count: PropTypes.number.isRequired,
}

export default ApprovedProviderCard
