import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchProviderDegree = createAsyncThunk(
  'providerDegreeData/fetchProviderDegree',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/providers/${requestObj[1]}/degrees`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const addProviderDegree = createAsyncThunk(
  'providerDegreeData/addProviderDegree',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/providers/${requestObj[1]}/degrees`,
        { ...requestObj[2], provider_id: requestObj[1].id },
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteProviderDegree = createAsyncThunk(
  'providerDegreeData/deleteProviderDegree',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/providers/${requestObj[1]}/degrees/${requestObj[2]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProviderDegree = createAsyncThunk(
  'providerDegreeData/updateProviderDegree',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/providers/${requestObj[1]}/degrees/${requestObj[2]}`,
        requestObj[3],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
