import React from 'react'
import { PropTypes } from 'prop-types'
// material
import { styled, alpha } from '@mui/material/styles'
import { Card, Typography } from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import theme from 'config/theme'

const RootStyle = styled(Card)(() => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  borderRadius: '16px',
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}))

const IconWrapperStyle = styled('div')(() => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}))

const PatientCard = ({ count }) => (
  <RootStyle>
    <IconWrapperStyle>
      <GroupsIcon fontSize="large" />
    </IconWrapperStyle>
    <Typography variant="h3">{count}</Typography>
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      Patients
    </Typography>
  </RootStyle>
)

PatientCard.propTypes = {
  count: PropTypes.number.isRequired,
}

export default PatientCard
