import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  Typography,
  CssBaseline,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import ComaplaintTable from 'components/BasicTable'
import { getAdminComplaintList } from 'store/ComplaintData'
import useStyles from 'views/Admin/Complaint/styles'
import { loggedInUserToken } from 'store/CurrentUser'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchComplaints,
  deleteComplaint,
  updateComplaint,
  postComplaint,
} from 'api-client/ComplaintData/thunks'
import { ApiRequestMethod, constants } from 'utils/constants'
import { COLUMNS } from 'views/Admin/Complaint/columns'
import CustomSingleTextModal from 'components/CustomSingleTextModal'
import { complaintValidationSchema } from 'schema/ComplaintForm'
import AddIcon from '@mui/icons-material/Add'
import TableSearchHeader from 'components/TableSearchHeader'
import NoRecordFound from 'components/NoRecordFound'

const ComplaintList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const [complaintText, setComplaintText] = useState('')
  const [complaintId, setComplaintId] = useState(false)
  const [openComplaintModal, setOpenComplaintModal] = useState(false)
  const [sortOn, setSortOn] = useState([{ id: 'complaint', asc: true }])
  const token = useSelector((state) => loggedInUserToken(state))
  const admin_complaints = useSelector((state) => getAdminComplaintList(state))
  const { status } = useSelector((state) => state.complaintDataState)

  const filteredList = admin_complaints
    ? searchText
      ? admin_complaints.filter((item) =>
          item.complaint.toLowerCase().includes(searchText.toLowerCase())
        )
      : admin_complaints
    : []

  useEffect(() => {
    dispatch(fetchComplaints(token))
  }, [])

  const handleOpenComplaintModal = () => {
    setOpenComplaintModal(true)
  }

  const handleOpenComplaintModalForEdit = (cell) => {
    setComplaintText(cell.value)
    setComplaintId(cell.row.original.id)
    setOpenComplaintModal(true)
  }

  const handleCloseComplaintModal = () => {
    setComplaintId('')
    setComplaintText('')
    setOpenComplaintModal(false)
  }

  const searchTextHandler = (e) => {
    setSearchText(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
  }

  const handleAddOrUpdate = (type, values) => {
    const matchedComplaints = admin_complaints.filter(
      ({ complaint }) =>
        complaint.toLowerCase() === values.complaint.toLowerCase()
    )
    if (!matchedComplaints.length && type === ApiRequestMethod.PATCH) {
      const requestBody = [token, complaintId, values]
      dispatch(updateComplaint(requestBody))
    } else if (!matchedComplaints.length && type === ApiRequestMethod.POST) {
      const requestBody = [token, values]
      dispatch(postComplaint(requestBody))
    } else {
      toast.error('This complaint already exists.')
    }
  }

  const handleDelete = (id) => {
    const requestBody = [token, id]
    dispatch(deleteComplaint(requestBody))
  }

  return (
    <Box sx={{ m: '2rem 3rem' }}>
      <Box className={classes.pageHeader}>
        <Typography className={classes.headerTitle}>
          Provider Complaints
        </Typography>
        <Box>
          <Button
            startIcon={<AddIcon />}
            className={classes.addButton}
            onClick={handleOpenComplaintModal}
            color="secondary"
          >
            Add New
          </Button>
        </Box>
      </Box>

      {isEmpty(admin_complaints) && status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : isEmpty(admin_complaints) && status === constants.succeeded ? (
        <NoRecordFound msg="No complaint found" />
      ) : (
        <>
          <Grid container spacing={4}>
            <CssBaseline />
            <Grid item xs={12}>
              <TableSearchHeader
                handleClearSearch={handleClearSearch}
                searchText={searchText}
                itemName="Complaint"
                searchTextHandler={searchTextHandler}
                totalItems={filteredList.length}
              />
              <ComaplaintTable
                dataList={filteredList}
                COLUMNS={COLUMNS}
                access="complaint"
                setSortOn={setSortOn}
                initialSort={sortOn}
                handleDelete={handleDelete}
                handleOpenModalForEdit={handleOpenComplaintModalForEdit}
              />
            </Grid>
          </Grid>
        </>
      )}
      <CustomSingleTextModal
        handleCloseModal={handleCloseComplaintModal}
        initialValues={{ complaint: complaintText }}
        edit={!!complaintId}
        handleAddOrUpdate={handleAddOrUpdate}
        dialogTitle="Complaint"
        open={openComplaintModal}
        schema={complaintValidationSchema}
      />
    </Box>
  )
}
export default ComplaintList
