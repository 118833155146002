import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import {
  IconButton,
  Button,
  Typography,
  Paper,
  MobileStepper,
  Box,
} from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import useStyles from 'components/Carousal/styles'

function Carousal({ files, fileIndex, close }) {
  const theme = useTheme()
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(fileIndex)
  const maxSteps = files.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ maxWidth: 650, flexGrow: 1 }}>
      <Paper square elevation={0} className={classes.topPaper}>
        <Typography>PHOTO</Typography>

        <IconButton className={classes.closeButton} onClick={close}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Paper>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {files.map((step, index) => (
          <Box
            key={`step.file_category ${index + 1}`}
            className={classes.imageBox}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                className={classes.image}
                src={step.thumbnail_key}
                alt={step.file_category}
              />
            ) : null}
          </Box>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            color="secondary"
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            color="secondary"
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  )
}

Carousal.propTypes = {
  files: PropTypes.array.isRequired,
  fileIndex: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
}

export default Carousal
