import React, { useRef, useState } from 'react'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SyncIcon from '@mui/icons-material/Sync'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteProvider,
  updateProviderState,
  updateProviderProfilePic,
  updateProvider,
  restoreProvider,
} from 'api-client/ProvidersData/thunks'
import { RestoreProviderInfoMsg, userRolesConstants } from 'utils/constants'
import useStyles from 'views/Provider/Profile/Forms/ProfileCard/styles'
import { loggedInUser, loggedInUserToken } from 'store/CurrentUser'
import { getProvider, sendEmailForProfileCompletion } from 'store/ProvidersData'
import palette from 'config/palette'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import IOSSwitch from 'components/Switch'

const ProfileCard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputRef = useRef()

  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [profileImageFile, setProfileImageFile] = useState(null)
  const [openDeleteProviderDialog, setOpenDeleteProviderDialog] =
    useState(false)
  const [openProviderApprovalModal, setOpenProviderApprovalModal] =
    useState(false)
  const [openRestoreProviderModal, setOpenRestoreProviderModal] =
    useState(false)
  const token = useSelector((state) => loggedInUserToken(state))
  const currentUser = useSelector((state) => loggedInUser(state))
  const provider = useSelector((state) => getProvider(state))

  const handleOpenDeleteProviderDialog = () => {
    setOpenDeleteProviderDialog(true)
  }

  const handleCloseDeleteProviderDialog = () => {
    setOpenDeleteProviderDialog(false)
  }

  const handleOpenRestoreProviderDialog = () => {
    setOpenRestoreProviderModal(true)
  }

  const handleCloseRestoreProviderDialog = () => {
    setOpenRestoreProviderModal(false)
  }

  const handleOpenProviderApprovalDialog = () => {
    setOpenProviderApprovalModal(true)
  }

  const handleCloseProviderApprovalDialog = () => {
    setOpenProviderApprovalModal(false)
  }

  const handleImageChange = (event) => {
    const image = event.target.files[0] && event.target.files[0]
    setProfileImageFile(image)
    if (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => {
        setPreviewImage(reader.result)
      }
    }
  }

  const handleImageUpload = () => {
    if (profileImageFile) {
      dispatch(updateProviderProfilePic([token, profileImageFile]))
    }
    setPreviewImage(null)
  }

  const handleDeleteProvider = async () => {
    setLoading(true)
    const requestBody = [token, provider.id]
    await dispatch(deleteProvider(requestBody))
    handleCloseDeleteProviderDialog()
    setLoading(false)
  }

  const handleRestoreProvider = async () => {
    setLoading(true)
    await dispatch(restoreProvider({ token, providerId: provider?.id }))
    handleCloseRestoreProviderDialog()
    setLoading(false)
  }

  const handleApproveProvider = () => {
    dispatch(
      updateProviderState([
        token,
        {
          provider_id: provider.id,
          is_approved: '1',
          is_active: provider.is_active.toString(),
        },
      ])
    )
  }

  const toggleProviderActiveStatus = (isActive) => {
    dispatch(
      updateProviderState([
        token,
        {
          provider_id: provider.id,
          is_approved: provider.approved_on ? '1' : '0',
          is_active: isActive,
        },
      ])
    )
  }

  const handleSendEmailForProfileCompletion = () => {
    dispatch(sendEmailForProfileCompletion([token, provider.id]))
  }

  const handleSwitchProfileView = (e) => {
    dispatch(
      updateProvider([
        token,
        provider.id,
        { is_profile_private: e.target.checked === true ? 1 : 0 },
      ])
    )
  }

  return (
    <Card elevation={3} className={classes.userDetails}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.uploadProfile}>
          {currentUser.role === userRolesConstants.provider ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <IconButton
                  className={classes.editIconBtn}
                  onClick={() => inputRef.current.click()}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    ref={inputRef}
                    onChange={handleImageChange}
                  />
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            >
              <Avatar
                alt="user-profile"
                className={classes.avatar}
                src={
                  previewImage
                    ? previewImage
                    : provider.profile_pic
                    ? provider.profile_pic
                    : null
                }
              />
            </Badge>
          ) : (
            <Avatar
              alt="user-profile"
              className={classes.avatar}
              src={
                previewImage
                  ? previewImage
                  : provider.profile_pic
                  ? provider.profile_pic
                  : null
              }
            />
          )}

          {previewImage && (
            <Box className={classes.profilePicActions}>
              <Tooltip title="cancel">
                <IconButton onClick={() => setPreviewImage(null)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="upload">
                <IconButton onClick={handleImageUpload}>
                  <CloudUploadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box sx={{ ml: '2rem', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ mr: '8px', fontWeight: 500, fontSize: '18px' }}>
              {provider?.first_name} {provider?.last_name}{' '}
              <span
                className={
                  provider.is_active === 1
                    ? classes.activeStatus
                    : classes.inActiveStatus
                }
              >
                ({provider.is_active === 1 ? 'Active' : 'In-Active'})
              </span>
            </Typography>
          </Box>
          <Typography variant="body2">{provider?.email}</Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 500 }}
            color={
              provider?.approved_on ? palette.primary.main : palette.error.main
            }
          >
            {provider?.approved_on ? 'Approved' : 'Not Approved'}
          </Typography>

          {provider?.approved_on && (
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
              <FormGroup>
                <FormControlLabel
                  disabled={provider?.destroyTime}
                  title={provider?.destroyTime ? RestoreProviderInfoMsg : null}
                  sx={{ ml: 0 }}
                  labelPlacement="start"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2">
                        Make profile private
                      </Typography>
                      <Typography
                        className={classes.questionMark}
                        title="Exclude your profile from search results. QR Code can still be used by patients to see your profile and join your practice."
                      >
                        ?
                      </Typography>
                    </Box>
                  }
                  control={
                    <IOSSwitch
                      className={classes.switch}
                      checked={provider?.is_profile_private}
                      onChange={(e) => handleSwitchProfileView(e)}
                    />
                  }
                />
              </FormGroup>
            </Box>
          )}
        </Box>

        {currentUser.role === userRolesConstants.admin &&
          !provider?.destroyTime && (
            <Box className={classes.adminActions}>
              <Box className={classes.buttonGroup}>
                {!provider.approved_on && (
                  <Button
                    color="primary"
                    onClick={handleOpenProviderApprovalDialog}
                  >
                    Approve
                  </Button>
                )}
                <Button
                  color={provider.is_active === 1 ? 'error' : 'secondary'}
                  onClick={() =>
                    toggleProviderActiveStatus(
                      provider.is_active === 1 ? '0' : '1'
                    )
                  }
                >
                  {provider.is_active === 1 ? 'Set as inactive' : 'Set Active'}
                </Button>
                <Button
                  color="error"
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <CircularProgress size={18} color="error" />
                    ) : null
                  }
                  onClick={handleOpenDeleteProviderDialog}
                >
                  Delete Provider
                </Button>
              </Box>
              {!provider.approved_on && (
                <Box className={classes.reminderBtn}>
                  <Button
                    startIcon={<NotificationsIcon />}
                    color="secondary"
                    onClick={handleSendEmailForProfileCompletion}
                  >
                    Send email for completing profile
                  </Button>
                </Box>
              )}
            </Box>
          )}
        {currentUser.role === userRolesConstants.admin &&
          provider?.destroyTime && (
            <Box sx={{ minWidth: '160px' }}>
              <Button
                disabled={loading}
                startIcon={
                  loading ? (
                    <CircularProgress size={18} color="secondary" />
                  ) : (
                    <SyncIcon />
                  )
                }
                color="secondary"
                onClick={handleOpenRestoreProviderDialog}
              >
                Restore Provider
              </Button>
            </Box>
          )}
      </CardContent>
      <ConfirmDialogBox
        closeModal={handleCloseDeleteProviderDialog}
        open={openDeleteProviderDialog}
        dialogTitle="Confirm Delete Provider"
        dialogText="Are you sure you want to delete this provider?"
        handleConfirm={handleDeleteProvider}
      />
      <ConfirmDialogBox
        closeModal={handleCloseProviderApprovalDialog}
        open={openProviderApprovalModal}
        dialogTitle="Confirm Provider Approval"
        dialogText="Are you sure you want to Approve this provider?"
        handleConfirm={handleApproveProvider}
      />
      <ConfirmDialogBox
        closeModal={handleCloseRestoreProviderDialog}
        open={openRestoreProviderModal}
        dialogTitle="Confirm Restore Provider"
        dialogText="Are you sure you want to restore this provider?"
        handleConfirm={handleRestoreProvider}
      />
    </Card>
  )
}

export default ProfileCard
