import moment from 'moment/moment'
import { dateTimeFormat } from 'utils/constants'

export const COLUMNS = [
  {
    Header: 'Registration Token',
    accessor: 'registration_token',
    disableSortBy: true,
  },
  {
    Header: ' Platform',
    accessor: 'platform',
  },
  {
    Header: 'Last Updated at',
    accessor: 'updatedAt',
    Cell: (row) => moment(new Date(row.value)).format(dateTimeFormat),
  },
]
