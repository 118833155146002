import * as Yup from 'yup'

export const initialValues = { password: '', passwordConfirmation: '' }

export const validationSchema = Yup.object({
  password: Yup.string('')
    .min(8, 'Password must contain at least 8 characters')
    .required('Password is required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  ),
})
