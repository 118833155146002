import React, { useState, useEffect } from 'react'
import useStyles from 'views/Admin/VisitHints/styles'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Card,
  TextField,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchVisitHints,
  updateVisitHints,
} from 'api-client/VisitHintsData/thunks'
import { constants } from 'utils/constants'
import { toast } from 'react-toastify'

const VisitHints = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.currentUserState)
  const { visitHints, status, isSubmitting } = useSelector(
    (state) => state.visitHintsDataState
  )

  const [text, setText] = useState(visitHints)

  useEffect(() => {
    dispatch(fetchVisitHints(token))
  }, [])

  useEffect(() => {
    setText(visitHints)
  }, [visitHints])

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const handleSave = () => {
    if (text === visitHints) {
      toast.warning('Nothing was changed.')
    } else {
      dispatch(updateVisitHints([token, { visit_hints: text }]))
    }
  }

  return (
    <>
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={{ m: '2rem 3rem' }}>
          <Box>
            <Typography className={classes.rootHeading}>Visit Hints</Typography>
            <Typography variant="body2" className={classes.subHeading}>
              Instruct patients to include necessary/required details in the
              message.
            </Typography>
          </Box>

          <Box>
            <Card className={classes.card} elevation={0}>
              <TextField
                multiline
                value={text}
                onChange={handleChange}
                rows={16}
                fullWidth
                sx={{ '& fieldset': { border: 'none' } }}
                InputProps={{
                  classes: {
                    input: classes.bigFont,
                  },
                }}
              />
            </Card>

            <Box className={classes.buttonBox}>
              <Button
                variant="contained"
                onClick={handleSave}
                color="secondary"
                sx={{ padding: '0.4rem 3rem' }}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default VisitHints
