import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  notFoundBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  nothingFound: {
    marginTop: '1.2rem',
    textAlign: 'center',
    padding: '1rem',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: '#757575',
  },
})

export default useStyles
