import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import { dateFormat } from 'utils/constants'
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { complaintValidationSchema } from 'schema/ComplaintForm'
import PropTypes from 'prop-types'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import useStyles from 'views/Provider/Complaint/CustomComplaintTable/styles'

const CustomComplaintTable = ({
  complaintList,
  complaintType,
  handleDelete,
  handleUpdate,
}) => {
  const classes = useStyles()
  const [providerVisible, setProviderVisible] = useState(false)
  const [patientVisible, setPatientVisible] = useState(false)
  const [editComplaintId, setEditComplaintId] = useState('')
  const [deletedComplaintId, setDeletedComplaintId] = useState('')
  const [editedComplaintText, setEditedComplaintText] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const onConfirm = () => {
    handleDelete(deletedComplaintId)
  }

  const openDeleteConfirmationDialog = (id) => {
    setDeletedComplaintId(id)
  }

  const closeDeleteConfirmationDialog = () => {
    setDeletedComplaintId('')
  }

  const ValidateComplaint = async () => {
    setErrorMsg('')
    try {
      await complaintValidationSchema.validate({
        complaint: editedComplaintText,
      })
    } catch (err) {
      setErrorMsg(err.message)
    }
  }

  const handleUpdateComplaints = () => {
    const values = {}
    if (complaintType === 'provider' && !errorMsg) {
      values.complaint = editedComplaintText.trim()
      values.patient_visibility = patientVisible
      values.provider_visibility = providerVisible
      handleUpdate('provider', editComplaintId, values)
      setEditComplaintId('')
      setEditedComplaintText('')
    } else {
      values.patient_visibility = patientVisible
      values.provider_visibility = providerVisible
      handleUpdate('default', editComplaintId, values)
      setEditComplaintId('')
    }
  }

  const handleEditComplaint = (row) => {
    setEditComplaintId(row.id)
    setEditedComplaintText(row.complaint)
    setProviderVisible(row.provider_visibility)
    setPatientVisible(row.patient_visibility)
  }

  const handleClearEdit = () => {
    setEditComplaintId('')
    setEditedComplaintText('')
    setErrorMsg('')
    setProviderVisible(false)
    setPatientVisible(false)
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>#</TableCell>
              <TableCell className={classes.cell}>Complaint name</TableCell>
              <TableCell className={classes.cell}>Patient</TableCell>
              <TableCell className={classes.cell}>Provider</TableCell>
              <TableCell className={classes.cell}>Created at</TableCell>
              <TableCell className={classes.cell}>Updated at</TableCell>
              <TableCell className={classes.cell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {complaintList?.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:hover': { backgroundColor: '#e3f2fd' } }}
              >
                <TableCell>
                  <Typography variant="body2">{index + 1}</Typography>
                </TableCell>
                <TableCell>
                  {editComplaintId === row.id && complaintType !== 'default' ? (
                    <>
                      <TextField
                        autoFocus
                        size="small"
                        value={editedComplaintText}
                        onChange={(e) => setEditedComplaintText(e.target.value)}
                        onKeyUp={ValidateComplaint}
                      />
                      {errorMsg && (
                        <Typography
                          sx={{
                            marginTop: '3px',
                            fontSize: '12px',
                            color: 'red',
                          }}
                        >
                          {errorMsg}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2">{row.complaint}</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {editComplaintId === row.id ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={patientVisible}
                            onChange={(e) =>
                              setPatientVisible(e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {patientVisible ? 'Visible' : 'Not visible'}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ) : row.patient_visibility ? (
                    'Visible'
                  ) : (
                    'Not visible'
                  )}
                </TableCell>
                <TableCell>
                  {editComplaintId === row.id ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={providerVisible}
                            onChange={(e) =>
                              setProviderVisible(e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {providerVisible ? 'Visible' : 'Not visible'}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ) : row.provider_visibility ? (
                    'Visible'
                  ) : (
                    'Not visible'
                  )}
                </TableCell>
                <TableCell>
                  {moment(new Date(row.createdAt)).format(dateFormat)}
                </TableCell>
                <TableCell>
                  {moment(new Date(row.updatedAt)).format(dateFormat)}
                </TableCell>
                <TableCell>
                  <Box>
                    {editComplaintId === row.id ? (
                      <>
                        <Tooltip title="Save" placement="top">
                          <IconButton
                            className="iconButton"
                            onClick={handleUpdateComplaints}
                          >
                            <BookmarkAddedOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel" placement="top">
                          <IconButton
                            className="iconButton"
                            onClick={handleClearEdit}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            className="iconButton"
                            onClick={() => handleEditComplaint(row)}
                          >
                            <ModeEditOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        {complaintType !== 'default' && (
                          <Tooltip title="Delete" placement="top">
                            <IconButton
                              className="iconButton"
                              onClick={() =>
                                openDeleteConfirmationDialog(row.id)
                              }
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmDialogBox
        closeModal={closeDeleteConfirmationDialog}
        open={!!deletedComplaintId}
        dialogTitle={`Confirm Delete Complaint`}
        dialogText="Are you sure you want to delete complaint?"
        handleConfirm={onConfirm}
      />
    </>
  )
}

export default CustomComplaintTable

CustomComplaintTable.propTypes = {
  complaintList: PropTypes.array.isRequired,
  complaintType: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
}

CustomComplaintTable.defaultProps = {
  handleDelete: () => {},
}
