import React, { useEffect } from 'react'
import { Card, CardHeader, Grid, Button, CircularProgress } from '@mui/material'
import useStyles from 'views/Provider/WeeklySchedule/styles'
import Schedule from 'views/Provider/WeeklySchedule/Schedule'
import { Formik, Form, FieldArray } from 'formik'
import { weeklyScheduleSchema } from 'schema/schedule'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPrroviderHours,
  updateProviderHours,
} from 'api-client/ProviderHours/thunks'
import {
  constants,
  SERVER_TIME_FORMAT,
  userRolesConstants,
} from 'utils/constants'
import { Box } from '@mui/system'
import moment from 'moment'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import { useParams } from 'react-router-dom'

const formSchema = Yup.object().shape({
  schedule: weeklyScheduleSchema,
})

const WorkingSchedule = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const user = useSelector((state) => loggedInUser(state))
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const { token } = useSelector((state) => state.currentUserState)

  let providerId = ''
  if (user.role === userRolesConstants.admin) {
    providerId = params.id
  } else providerId = userInfo.id

  const { data, status, isSubmitting } = useSelector(
    (state) => state.providerHoursState
  )

  useEffect(() => {
    dispatch(fetchPrroviderHours({ token, providerId }))
  }, [])

  const handleSubmit = (values) => {
    const updatedSchedule = values.schedule.map((day) => {
      const newDay = { ...day }
      if (newDay.is_open) {
        newDay.open_time = newDay.open_time.utc().format(SERVER_TIME_FORMAT)
        newDay.close_time = newDay.close_time.utc().format(SERVER_TIME_FORMAT)
      }
      return newDay
    })

    dispatch(
      updateProviderHours({
        token,
        providerId,
        body: {
          provider_schedule: updatedSchedule,
          offset: moment().utcOffset(),
        },
      })
    )
  }

  return (
    <Card elevation={0} className={classes.ok}>
      <CardHeader
        sx={{ m: 2, mb: 3 }}
        title="Recurring Weekly Schedule"
        subheader="Configure the weekly working hours schedule"
      />
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Formik
          initialValues={{ schedule: data }}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Grid container direction="row" align="center" justify="center">
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                  columnSpacing={2}
                  rowSpacing={3.5}
                  sx={{ ml: 3, mr: 3 }}
                >
                  <FieldArray
                    name="schedule"
                    render={() =>
                      values.schedule.map((schedule, index) => (
                        <Schedule
                          key={schedule.id}
                          schedule={schedule}
                          index={index}
                          setFieldValue={setFieldValue}
                        />
                      ))
                    }
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Button
                  className={classes.btnSaveSchedule}
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save Schedule
                  {isSubmitting ? (
                    <Box>
                      <CircularProgress
                        className={classes.saveBtnloader}
                        size={15}
                      />
                    </Box>
                  ) : null}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  )
}

export default WorkingSchedule
