import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  visitsChartCard: {
    boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
    borderRadius: '16px',
  },
  cardHeader: {
    margin: '24px',
    marginBottom: '8px',
  },
  selectYear: {
    minWidth: 120,
    float: 'right',
    marginRight: '60px',
    marginTop: '-90px',
  },
  chartBox: {
    padding: '8px 16px',
  },
  notFoundVisit: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 378,
  },
  notFoundText: {
    fontSize: '24px',
    color: '#9e9e9e',
  },
})

export default useStyles
