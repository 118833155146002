import React, { useState } from 'react'
import {
  Card,
  Box,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useStyles } from 'views/ResetPassword/styles'
import { Formik, Form } from 'formik'
import FormikTextField from 'components/FormikTextField'
import { ResetPasswordSchema } from 'schema/ResetPasswordForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  logoutUser,
  resetCurrentUserPassword,
} from 'api-client/CurrentUser/thunks'
import { useHistory } from 'react-router-dom'

const ResetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const initialValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  }
  const [currPassVisibility, setCurrPassVisibility] = useState(false)
  const [newPassVisibility, setNewPassVisibility] = useState(false)
  const [confirmPassVisibility, setConfirmPassVisibility] = useState(false)

  const { token } = useSelector((state) => state.currentUserState)

  const handleSubmit = async ({ current_password, new_password }) => {
    const response = await dispatch(
      resetCurrentUserPassword({
        token,
        reqData: { current_password, new_password },
      })
    )
    if (response.payload.statusCode === 200) {
      dispatch(logoutUser())
      history.push('/')
    }
  }
  return (
    <Container>
      <Typography variant="h5" className={classes.heading}>
        Settings
      </Typography>
      <Card elevation={0} className={classes.card}>
        <CardContent sx={{ px: '2rem' }}>
          <Typography variant="h6" sx={{ fontSize: '18px', color: '#616161' }}>
            Update Password
          </Typography>
          <Box className={classes.form}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={ResetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <Grid container spacing={2} marginTop="1rem">
                    <Grid item sm={12}>
                      <FormikTextField
                        className={classes.field}
                        name="current_password"
                        label="Current Password"
                        size="large"
                        type={currPassVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ mr: '0.5rem' }}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setCurrPassVisibility(!currPassVisibility)
                                }
                              >
                                {currPassVisibility ? (
                                  <VisibilityOffIcon fontSize="small" />
                                ) : (
                                  <VisibilityIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <FormikTextField
                        className={classes.field}
                        name="new_password"
                        label="New Password"
                        size="large"
                        type={newPassVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ mr: '0.5rem' }}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setNewPassVisibility(!newPassVisibility)
                                }
                              >
                                {newPassVisibility ? (
                                  <VisibilityOffIcon fontSize="small" />
                                ) : (
                                  <VisibilityIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <FormikTextField
                        className={classes.field}
                        name="confirm_password"
                        label="Confirm Password"
                        size="large"
                        type={confirmPassVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ mr: '0.5rem' }}
                            >
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  setConfirmPassVisibility(
                                    !confirmPassVisibility
                                  )
                                }
                              >
                                {confirmPassVisibility ? (
                                  <VisibilityOffIcon fontSize="small" />
                                ) : (
                                  <VisibilityIcon fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item sm={12} textAlign="end">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </CardContent>
      </Card>
    </Container>
  )
}

export default ResetPassword
