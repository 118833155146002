import React, { useState } from 'react'
import { CardMedia, Grid, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { getProvider } from 'store/ProvidersData'
import ImageCarousal from 'components/Carousal'
import useStyles from 'views/Provider/Profile/Forms/ImagesAndVideos/styles'
import Dialog from '@mui/material/Dialog'

const ImagesAndVideos = () => {
  const classes = useStyles()
  const [imageIndex, setImageIndex] = React.useState(0)
  const [openImageCarousalModal, setOpenImageCarousalModal] = useState(false)
  const provider = useSelector((state) => getProvider(state))
  const files = provider.Provider_files

  const handleOpenImageCarousalModal = (index) => {
    setImageIndex(parseInt(index))
    setOpenImageCarousalModal(true)
  }
  const handleCloseImageCarousalModal = () => setOpenImageCarousalModal(false)

  return (
    <>
      <Dialog
        open={openImageCarousalModal}
        onClose={handleCloseImageCarousalModal}
      >
        <ImageCarousal
          files={
            provider.Provider_files && provider.Provider_files.length
              ? provider.Provider_files
              : []
          }
          fileIndex={imageIndex}
          close={handleCloseImageCarousalModal}
        />
      </Dialog>

      {files && files.length ? (
        <Grid item xs={12}>
          <Stack spacing={3} direction="row" disablePadding>
            {Object.keys(files).map((index) => (
              <CardMedia
                key={index}
                className={classes.imageCardMedia}
                onClick={() => handleOpenImageCarousalModal(index)}
                component="img"
                height="194"
                alt={`${index + 1}`}
                image={`${files[index].thumbnail_key}`}
              />
            ))}
          </Stack>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.value}>
            Not Added.
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default ImagesAndVideos
