import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchProviderMedicalTraining = createAsyncThunk(
  'providerMedicalTrainingData/fetchProviderMedicalTraining',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/providers/${requestObj[1]}/trainings`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const addProviderMedicalTraining = createAsyncThunk(
  'providerMedicalTrainingData/addProviderMedicalTraining',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/providers/${requestObj[1]}/trainings`,
        { ...requestObj[2], provider_id: requestObj[1].id },
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteProviderMedicalTraining = createAsyncThunk(
  'providerMedicalTrainingData/deleteProviderMedicalTraining',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/providers/${requestObj[1]}/trainings/${requestObj[2]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateProviderMedicalTraining = createAsyncThunk(
  'providerMedicalTrainingData/updateProviderMedicalTraining',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/providers/${requestObj[1]}/trainings/${requestObj[2]}`,
        requestObj[3],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
