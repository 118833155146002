import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  heading: {
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#616161',
  },
  card: {
    marginTop: '1rem',
    border: '1px solid #e0e0e0',
    borderRadius: '0.75rem',
  },
  field: {
    width: '100%',
  },
  form: {
    marginTop: '0.5rem',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '15px',
  },
  singleInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
