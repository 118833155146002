import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useStyles from 'components/Pagination/styles'
import { Grid, MenuItem, Select } from '@mui/material'

const Pagination = ({
  pageChangeHandler,
  totalPages,
  rowsPerPageHandler,
  rowsPerPage,
  currentPage,
  selectPageSize,
  pageSizes,
}) => {
  const classes = useStyles()
  const noOfPages = totalPages
  const PAGE_SIZES = pageSizes

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoNext, setCanGoNext] = useState(true)
  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false)
    } else {
      setCanGoNext(true)
    }
    if (currentPage === 1) {
      setCanGoBack(false)
    } else {
      setCanGoBack(true)
    }
  }, [noOfPages, currentPage])

  // To set the starting index of the page
  useEffect(() => {
    pageChangeHandler(currentPage)
  }, [currentPage])

  return (
    <>
      {totalPages ? (
        <Grid container spacing={0} direction="column">
          {noOfPages > 1 ? (
            <div className={classes.pagination}>
              <div className={classes.pagebuttons}>
                <button
                  className={classes.pageBtn}
                  onClick={() => pageChangeHandler(1)}
                  disabled={!canGoBack}
                >
                  {'<<'}
                </button>
                <button
                  className={classes.pageBtn}
                  onClick={() => pageChangeHandler(currentPage - 1)}
                  disabled={!canGoBack}
                >
                  {'<'}
                </button>
                <button
                  className={classes.pageBtn}
                  onClick={() => pageChangeHandler(currentPage + 1)}
                  disabled={!canGoNext}
                >
                  {'>'}
                </button>
                <button
                  className={classes.pageBtn}
                  onClick={() => pageChangeHandler(totalPages)}
                  disabled={!canGoNext}
                >
                  {'>>'}
                </button>
                <span className={classes.pagesSpan}>
                  Page <strong> {`${currentPage} of ${totalPages}`}</strong>
                </span>
                {selectPageSize ? (
                  <Select
                    value={rowsPerPage}
                    className={classes.paginationSelectBox}
                    onChange={(e) => {
                      pageChangeHandler(1)
                      rowsPerPageHandler(e.target.value)
                    }}
                  >
                    {PAGE_SIZES.map((pgSize) => (
                      <MenuItem key={pgSize} value={pgSize}>
                        Show {pgSize}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </div>
            </div>
          ) : selectPageSize ? (
            <div className={classes.pagination}>
              <div className={classes.pagebuttons}>
                <Select
                  value={rowsPerPage}
                  className={classes.paginationSelectBox}
                  onChange={(e) => {
                    pageChangeHandler(1)
                    rowsPerPageHandler(e.target.value)
                  }}
                >
                  {PAGE_SIZES.map((pgSize) => {
                    if (totalPages < currentPage) pageChangeHandler(1)
                    return (
                      <MenuItem key={pgSize} value={pgSize}>
                        Show {pgSize}
                      </MenuItem>
                    )
                  })}
                </Select>
              </div>
            </div>
          ) : null}
        </Grid>
      ) : null}
    </>
  )
}

Pagination.defaultProps = {
  selectPageSize: true,
  pageSizes: ['5', '10', '20'],
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageChangeHandler: PropTypes.func.isRequired,
  rowsPerPageHandler: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.string.isRequired,
  selectPageSize: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.string),
}

export default Pagination
