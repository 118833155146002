import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material'
import useStyles from 'views/Admin/RegistrationTokens/styles'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserRegistrationTokensById } from 'api-client/RegistrationTokens/thunks'
import { constants } from 'utils/constants'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { isEmpty } from 'lodash'
import NoRecordFound from 'components/NoRecordFound'
import TableSearchHeader from 'components/TableSearchHeader'
import RegistrationTokenTable from 'components/BasicTable'
import { COLUMNS } from 'views/Admin/RegistrationTokens/columns'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
const RegistrationTokens = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const userId = params.userId ? params.userId : params.id
  const [searchText, setSearchText] = useState('')

  const { token } = useSelector((state) => state.currentUserState)
  const { registrationTokens, status } = useSelector(
    (state) => state.registrationTokensState
  )

  const filteredList = registrationTokens.length
    ? searchText
      ? registrationTokens.filter((item) =>
          item.platform.toLowerCase().includes(searchText.toLowerCase())
        )
      : registrationTokens
    : []

  useEffect(() => {
    dispatch(fetchUserRegistrationTokensById({ userId, token }))
  }, [dispatch, userId])

  const searchTextHandler = (e) => {
    setSearchText(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
  }

  return (
    <Box>
      <Box className={classes.heading}>
        <Tooltip title="Back" placement="left">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" className={classes.headingText}>
          Registration Tokens
        </Typography>
      </Box>
      {isEmpty(registrationTokens) && status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={50} />
        </Box>
      ) : isEmpty(registrationTokens) && status === constants.succeeded ? (
        <NoRecordFound msg="No registration token found" />
      ) : (
        <>
          <Grid container spacing={4}>
            <CssBaseline />
            <Grid item xs={12}>
              <TableSearchHeader
                handleClearSearch={handleClearSearch}
                searchText={searchText}
                itemName="Platform"
                searchTextHandler={searchTextHandler}
                totalItems={filteredList.length}
              />
              <RegistrationTokenTable
                dataList={filteredList}
                COLUMNS={COLUMNS}
                hideActions={true}
                access="registration token"
                customColumnName="registration_token"
                CustomColumn={CustomTokenColumn}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default RegistrationTokens

export const CustomTokenColumn = (props) => {
  const { value } = props
  const [isVisible, setIsVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleRegistrationTokenVisibility = () => {
    setIsVisible(!isVisible)
  }
  return (
    <Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.35rem',
            p: '0.5rem',
          }}
        >
          <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
            {isVisible
              ? value
              : value.slice(0, 4) + '******************' + value.slice(-4)}
          </Typography>
          <Tooltip title={isVisible ? 'Hide' : 'show'}>
            <IconButton
              size="small"
              onClick={toggleRegistrationTokenVisibility}
            >
              {isVisible ? (
                <VisibilityOffIcon fontSize="small" />
              ) : (
                <VisibilityIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Popover>
      <Typography
        variant="body2"
        onMouseEnter={handleOpen}
        sx={{ maxWidth: '150px' }}
      >
        {value.slice(0, 4) + '******************' + value.slice(-4)}
      </Typography>
    </Box>
  )
}

CustomTokenColumn.propTypes = {
  value: PropTypes.string.isRequired,
}
