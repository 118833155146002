import React from 'react'
import { useField } from 'formik'
import { MenuItem, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PropTypes from 'prop-types'

const FormikTextField = ({
  isIcon,
  select,
  accessor,
  accessor2,
  items,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''

  if (select && items.length) {
    return (
      <TextField
        select
        size="small"
        fullWidth
        helperText={errorText}
        error={!!errorText}
        {...field}
        {...props}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id ? item.id : item[`${accessor}`]}
            value={
              accessor2
                ? `${item[`${accessor}`]}, ${item[`${accessor2}`]}`
                : item[`${accessor}`]
            }
          >
            {accessor2
              ? `${item[`${accessor}`]}, ${item[`${accessor2}`]}`
              : item[`${accessor}`]}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  if (isIcon) {
    return (
      <TextField
        size="small"
        helperText={errorText}
        error={!!errorText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
        {...field}
        {...props}
      />
    )
  }

  return (
    <TextField
      size="small"
      helperText={errorText}
      error={!!errorText}
      {...field}
      {...props}
    />
  )
}

FormikTextField.propTypes = {
  isIcon: PropTypes.bool,
  select: PropTypes.bool,
  accessor: PropTypes.string,
  accessor2: PropTypes.string,
  items: PropTypes.array,
}

FormikTextField.defaultProps = {
  isIcon: false,
  select: false,
  accessor: '',
  accessor2: '',
  items: [],
}

export default FormikTextField
