import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchVisitHints = createAsyncThunk(
  'visitHintsData/fetchVisitHints',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/visitHints`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateVisitHints = createAsyncThunk(
  'visitHintsData/updateVisitHints',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/visitHints`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
