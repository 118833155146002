import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const postNewPassword = createAsyncThunk(
  'passwordAPIs/postNewPassword',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/users/setNewPassword`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const sendForgotPasswordEmail = createAsyncThunk(
  'passwordAPIs/sendForgotPasswordEmail',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/users/forgotPassword/sendEmail`,
        requestObj,
        {}
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getEmailTokenStatus = createAsyncThunk(
  'passwordAPIs/getEmailTokenStatus',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/users/setNewPassword/checkTokenStatus`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
