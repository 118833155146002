import React from 'react'
import PropTypes from 'prop-types'
import { merge } from 'lodash'
import ReactApexChart from 'react-apexcharts'
import { styled } from '@mui/material/styles'
import { Card, CardHeader } from '@mui/material'
import theme from 'config/theme'
import { fNumber } from 'utils/helperFunctions'
import BaseOptionChart from 'components/ApexCharts/BaseOptionChart'

const CHART_HEIGHT = 370
const LEGEND_HEIGHT = 82

const ChartWrapperStyle = styled('div')(() => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.25rem',
  },
}))

const PieChart = ({ data }) => {
  const CHART_DATA = [
    data['CANCELLED'],
    data['CLOSED'],
    data['AWAITING RESPONSE'],
    data['UNATTENDED'],
    data['IN PROGRESS'],
  ]

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.grey[400],
      theme.palette.secondary.light,
      theme.palette.info.light,
    ],
    labels: [
      'Cancelled',
      'Closed',
      'Awaiting Response',
      'Un Attended',
      'In Progress',
    ],
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: true,
      horizontalAlign: 'left',
      fontSize: '13px',
      fontWeight: 400,
      itemMargin: {
        horizontal: 8,
        vertical: 2,
      },
      formatter: (seriesName, opts) => [
        seriesName,
        ' - ',
        opts.w.globals.series[opts.seriesIndex],
      ],
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      style: {
        colors: [
          theme.palette.warning.darker,
          theme.palette.error.darker,
          theme.palette.grey[700],
          theme.palette.secondary.darker,
          theme.palette.info.darker,
        ],
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { customScale: 1.2, donut: { labels: { show: false } } },
    },
  })

  return (
    <Card
      sx={{
        boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
        borderRadius: '16px',
      }}
    >
      <CardHeader sx={{ m: '1rem 2rem' }} title="Visits Status" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="pie"
          series={CHART_DATA}
          options={chartOptions}
          height={275}
        />
      </ChartWrapperStyle>
    </Card>
  )
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PieChart
