import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  headBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  headingText: {
    color: 'rgba(0,0,0,0.7)',
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  loader: {
    marginTop: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
