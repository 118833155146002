import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants, SERVER_TIME_FORMAT } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchPrroviderHours,
  updateProviderHours,
} from 'api-client/ProviderHours/thunks'
import moment from 'moment'

const initialState = {
  data: [],
  status: constants.idle,
  isSubmitting: false,
  error: null,
}

const providerHoursSlice = createSlice({
  name: 'providerHours',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPrroviderHours.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchPrroviderHours.fulfilled]: (state, action) => {
      state.status = constants.succeeded

      const todayDate = new Date().getDate()
      const formattedData = action.payload.data.map((dailyHours) => {
        const updatedDailyHours = { ...dailyHours }
        updatedDailyHours.close_time =
          dailyHours.close_time &&
          moment
            .utc(updatedDailyHours.close_time, SERVER_TIME_FORMAT)
            .local()
            .set('date', todayDate)
        updatedDailyHours.open_time =
          dailyHours.open_time &&
          moment
            .utc(updatedDailyHours.open_time, SERVER_TIME_FORMAT)
            .local()
            .set('date', todayDate)
        return updatedDailyHours
      })
      state.data = formattedData
      state.error = null
    },
    [fetchPrroviderHours.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = []
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
        toast.error(state.error)
      } else {
        state.error = action.payload.message
        toast.error(state.error)
      }
    },
    [updateProviderHours.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderHours.fulfilled]: (state, action) => {
      const todayDate = new Date().getDate()
      const formattedData = action.payload.data.map((dailyHours) => {
        const updatedDailyHours = { ...dailyHours }
        updatedDailyHours.close_time =
          dailyHours.close_time &&
          moment
            .utc(updatedDailyHours.close_time, SERVER_TIME_FORMAT)
            .local()
            .set('date', todayDate)
        updatedDailyHours.open_time =
          dailyHours.open_time &&
          moment
            .utc(updatedDailyHours.open_time, SERVER_TIME_FORMAT)
            .local()
            .set('date', todayDate)
        return updatedDailyHours
      })
      state.data = formattedData
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Weekly Schedule Updated')
    },
    [updateProviderHours.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      state.isSubmitting = false
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = []
      state.status = constants.idle
      state.error = null
    },
  },
})

export default providerHoursSlice.reducer
