import { createTheme } from '@mui/material/styles'
import palette from 'config/palette'

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette,
})

export default theme
