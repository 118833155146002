import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardHeader,
  Box,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import _, { isEmpty, merge } from 'lodash'
import ReactApexChart from 'react-apexcharts'
import theme from 'config/theme'
import BaseOptionChart from 'components/ApexCharts/BaseOptionChart'
import { monthFullNames } from 'utils/constants'
import getDataByMonth from 'views/Admin/Dashboard/YearlyVisitsLineChart/yearlyDataByMonth'
import { fetchUserVisitsByYear } from 'api-client/DashboardData/thunks'
import { getVisitsByYear } from 'store/DashboardData'
import { loggedInUserToken } from 'store/CurrentUser'
import useStyles from 'views/Admin/Dashboard/YearlyVisitsLineChart/styles'
import { VISITS_YEARS } from 'utils/constants/years'

const YearlyVisitsLineChart = () => {
  const classes = useStyles()
  const [selectYear, setSelectYear] = React.useState(new Date().getFullYear())
  const dispatch = useDispatch()
  const token = useSelector((state) => loggedInUserToken(state))
  const visitsByYear = useSelector((state) => getVisitsByYear(state))
  const dataByMonth = getDataByMonth(visitsByYear)
  const CHART_DATA = [
    {
      name: 'Visits',
      type: 'bar',
      data: dataByMonth,
    },
  ]
  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.info.dark],
    stroke: { width: [1] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid'] },
    labels: monthFullNames,
    xaxis: {
      labels: {
        format: 'MM',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (_.isUndefined(y) !== 'undefined') {
            return `${y.toFixed(0)} visits`
          }
          return y
        },
      },
    },
  })

  useEffect(
    () => dispatch(fetchUserVisitsByYear([token, { year: selectYear }])),
    [selectYear]
  )

  const handleChange = (event) => {
    setSelectYear(event.target.value)
  }

  return (
    <Card className={classes.visitsChartCard}>
      <CardHeader
        className={classes.cardHeader}
        title="Total Visits"
        subheader="By Month"
      />
      <Box>
        <FormControl className={classes.selectYear}>
          <InputLabel id="demo-simple-select-label">Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectYear}
            label="Year"
            onChange={handleChange}
          >
            {VISITS_YEARS.map(({ value }) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {!isEmpty(visitsByYear) ? (
        <Box className={classes.chartBox} dir="ltr">
          <ReactApexChart
            type="line"
            series={CHART_DATA}
            options={chartOptions}
            height={350}
          />
        </Box>
      ) : (
        <Box className={classes.notFoundVisit}>
          <Typography className={classes.notFoundText}>
            There are no visits for the selected year.
          </Typography>
        </Box>
      )}
    </Card>
  )
}

export default YearlyVisitsLineChart
