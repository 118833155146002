import { object, string, date, number } from 'yup'
import { Gender, AddressTypes } from 'utils/constants/schemaConstants'

export const personalDetailsSchema = object().shape({
  first_name: string()
    .label('First Name')
    .required()
    .matches(/^[a-zA-Z]+$/, 'Only characters A-Z and a-z are allowed'),
  last_name: string()
    .label('Last Name')
    .required()
    .matches(/^[a-zA-Z]+$/, 'Only characters A-Z and a-z are allowed'),

  gender: string().label('Gender').oneOf(Object.values(Gender)).required(),
  date_of_birth: date()
    .typeError('Invalid date of birth')
    .label('Date of Birth')
    .required(),
  address_type: string()
    .label('Address Type')
    .oneOf(Object.values(AddressTypes))
    .required(),
  phone_number: number()
    .typeError('Invalid Phone Number')
    .test('len', 'Phone number must be 11 digits long', (val) =>
      val ? val.toString().length === 11 : false
    )
    .label('Phone Number')
    .required(),
  address_line: string().label('Address Line 1').required(),
  address_line_2: string().label('Address Line 2'),
  city: string().label('City').required(),
  state: string().label('State').required(),
  zip_code: string().label('Zip Code').required(),
})
