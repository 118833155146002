import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchStates = createAsyncThunk(
  'statesData/fetchStates',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/states`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
