import React, { useState } from 'react'
import { Box, CssBaseline, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import TableSearchHeader from 'components/TableSearchHeader'
import CustomComplaintTable from 'views/Provider/Complaint/CustomComplaintTable'
import NoRecordFound from 'components/NoRecordFound'

const DefaultComplaints = ({ admin_complaints, handleUpdate }) => {
  const [searchText, setSearchText] = useState('')

  const filteredList = admin_complaints
    ? searchText
      ? admin_complaints.filter((item) =>
          item.complaint.toLowerCase().includes(searchText.toLowerCase())
        )
      : [...admin_complaints]
    : []

  const searchTextHandler = (e) => {
    setSearchText(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchText('')
  }
  return (
    <Box>
      <TableSearchHeader
        handleClearSearch={handleClearSearch}
        searchText={searchText}
        itemName="Complaint"
        searchTextHandler={searchTextHandler}
        totalItems={filteredList.length}
      />
      <Grid container spacing={4}>
        <CssBaseline />
        <Grid item xs={12}>
          {filteredList.length ? (
            <CustomComplaintTable
              complaintList={filteredList}
              complaintType="default"
              handleUpdate={handleUpdate}
            />
          ) : (
            <NoRecordFound msg="No complaint found" />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default DefaultComplaints

DefaultComplaints.propTypes = {
  admin_complaints: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired,
}
