import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchAllergies = createAsyncThunk(
  'allergyData/fetchAllergies',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseApiUrl}/allergies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postAllergy = createAsyncThunk(
  'allergyData/postAllergy',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseApiUrl}/allergies`,
        requestObj[1] && requestObj[1],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateAllergy = createAsyncThunk(
  'allergyData/updateAllergy',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${baseApiUrl}/allergies/${requestObj[1] && requestObj[1]}`,
        requestObj[2] && requestObj[2],
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteAllergy = createAsyncThunk(
  'allergyData/deleteAllergy',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${baseApiUrl}/allergies/${requestObj[1] && requestObj[1]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0] && requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
