import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchPracticeTerms,
  postPracticeTerms,
  updatePracticeTerms,
} from 'api-client/PracticeTermsData/thunks'

const initialState = {
  data: '',
  status: constants.idle,
  isSubmitting: false,
  error: null,
}

const practiceTermsDataSlice = createSlice({
  name: 'practiceTermsData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPracticeTerms.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchPracticeTerms.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data.configuration_value
      state.error = null
    },
    [fetchPracticeTerms.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = ''
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
        toast.error(state.error)
      } else {
        state.error = action.payload.message
        toast.error(state.error)
      }
    },
    [postPracticeTerms.pending]: (state) => {
      state.status = constants.loading
    },
    [postPracticeTerms.fulfilled]: (state, action) => {
      state.data = action.payload.data.configuration_value
      state.status = constants.succeeded
      state.error = null
      toast.success('Default Practice Terms Added')
    },
    [postPracticeTerms.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updatePracticeTerms.pending]: (state) => {
      state.isSubmitting = true
    },
    [updatePracticeTerms.fulfilled]: (state, action) => {
      state.data = action.meta.arg[1]['practice_terms']
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Default Practice Terms Updated')
    },
    [updatePracticeTerms.rejected]: (state, action) => {
      state.status = constants.failed
      state.isSubmitting = false
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = ''
      state.status = constants.idle
      state.error = null
    },
  },
})

export default practiceTermsDataSlice.reducer
