import { makeStyles } from '@mui/styles'
import theme from 'config/theme'

const useStyles = makeStyles(() => ({
  mainContainer: {
    color: 'rgba(0,0,0,0.7)',
    marginTop: '60px',
  },
  mainCard: {
    boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
    borderRadius: '16px',
    paddingBottom: '30px',
  },
  selectBox: { float: 'right', marginRight: '100px', marginTop: '-55px' },
  loader: {
    marginTop: '140px',
    marginBottom: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  inActiveBtn: {
    padding: '6px 20px',
    backgroundColor: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
    },
  },
  activeBtn: {
    padding: '6px 20px',
    backgroundColor: theme.palette.secondary.main,
  },
  buttonContainer: {
    padding: '0.1rem 1.5rem',
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export default useStyles

export const style = {
  inActiveChip: {
    color: '#de4e7b',
    backgroundColor: '#F8ECEE',
    padding: '0.1rem 0.4rem',
  },
  activeChip: {
    color: '#2D9369',
    backgroundColor: '#e8f5e9',
    padding: '0.2rem 0.8rem',
  },
}
