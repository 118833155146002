import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import {
  fetchProvidersData,
  fetchSingleProvider,
  updateProviderState,
  deleteProvider,
  updateProvider,
  updateProviderProfilePic,
  restoreProvider,
} from 'api-client/ProvidersData/thunks'
import { logoutUser } from 'api-client/CurrentUser/thunks'
import moment from 'moment'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

const initialState = {
  data: [],
  provider: {},
  totalPages: 0,
  totalElements: 0,
  status: constants.idle,
  filterProviderType: null,
  error: null,
}

export const sendEmailForProfileCompletion = createAsyncThunk(
  'email/sendEmailForProfileCompletion',
  async (requestObj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseApiUrl}/admin/sendEmail/${requestObj[1]}`,
        {
          headers: {
            Authorization: `Bearer ${requestObj[0]}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const providersDataSlice = createSlice({
  name: 'providersData',
  initialState,
  reducers: {
    setFilterProviderType: (state, action) => {
      state.filterProviderType = action.payload.type
    },
    clearFilterProviderType: (state) => {
      state.filterProviderType = null
    },
  },
  extraReducers: {
    [fetchSingleProvider.pending]: (state) => {
      state.status = constants.loading
      state.provider = {}
    },
    [fetchSingleProvider.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      const providerData = action.payload.data
      if (providerData.rating && providerData.total_ratings) {
        providerData.rating_stars = providerData.rating.toFixed(1)
        providerData.rating_stars += ' (' + providerData.total_ratings + ')'
      } else providerData.rating_stars = 'No Rating Given'
      state.provider = providerData
      state.error = null
    },
    [fetchSingleProvider.rejected]: (state, action) => {
      state.status = constants.failed
      state.provider = {}
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [fetchProvidersData.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchProvidersData.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      const updatedData = action.payload.data.providers.map((val) => {
        if (!val.last_name) val.full_name = val.first_name
        else val.full_name = val.last_name + ', ' + val.first_name
        if (val.rating && val.total_ratings) {
          val.rating_stars = val.rating.toFixed(1)
          val.rating_stars += ' (' + val.total_ratings + ')'
        } else val.rating_stars = 'No Rating Given'
        return val
      })
      state.totalPages = action.payload.data.total_pages
      state.totalElements = action.payload.data.total_elements
      state.data = updatedData
      state.error = null
    },
    [fetchProvidersData.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [updateProviderState.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderState.fulfilled]: (state, action) => {
      const payload = action.meta.arg[1]
      state.provider.approved_on =
        payload.is_approved === '1' ? moment(Date.now()).utc().format() : null
      state.provider.is_active = parseInt(payload.is_active)
      toast('Provider Status Updated', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
    },
    [updateProviderState.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
    [sendEmailForProfileCompletion.pending]: (state) => {
      state.status = constants.loading
    },
    [sendEmailForProfileCompletion.fulfilled]: () => {
      toast('Email sent successfully.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
    },
    [sendEmailForProfileCompletion.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [deleteProvider.fulfilled]: (state) => {
      state.provider.destroyTime = moment(Date.now()).utc().format()
      toast.success('Provider Deleted!')
    },
    [deleteProvider.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [restoreProvider.fulfilled]: (state, action) => {
      const restoredProviderId = action.meta.arg.providerId
      state.data.every((user) => {
        if (user.id === restoredProviderId) {
          user.destroyTime = null
          return false
        }
        return true
      })
      toast.success('Provider Restored!')
      state.provider.destroyTime = null
    },
    [restoreProvider.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [updateProvider.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProvider.fulfilled]: (state, action) => {
      state.provider = { ...action.payload.data }
      const reqBody = action.meta.arg[2]
      let toastMsg = ''
      if ('is_profile_private' in reqBody && reqBody.is_profile_private)
        toastMsg = 'Profile is private now.'
      else if ('is_profile_private' in reqBody && !reqBody.is_profile_private)
        toastMsg = 'Profile is public now.'
      else toastMsg = 'Provider details updated.'

      toast(toastMsg, {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
    },
    [updateProvider.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [updateProviderProfilePic.pending]: (state) => {
      state.status = constants.loading
    },
    [updateProviderProfilePic.fulfilled]: (state, action) => {
      state.provider.profile_pic = action.payload.data.photo_url
      toast('Profile Picture Updated.', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_RIGHT,
        pauseOnHover: true,
        autoClose: 3000,
      })
    },
    [updateProviderProfilePic.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.data = []
      state.provider = {}
      state.status = constants.idle
      state.filterProviderType = null
      state.error = null
    },
  },
})

export const getDataList = (state) => state.providersDataState.data
export const getProvider = (state) => state.providersDataState.provider
export const getFilterProviderType = (state) =>
  state.providersDataState.filterProviderType
export const getTotalPages = (state) => state.providersDataState.totalPages
export const getTotalElements = (state) =>
  state.providersDataState.totalElements

export const { setFilterProviderType, clearFilterProviderType } =
  providersDataSlice.actions
export default providersDataSlice.reducer
