import { DEFAULT_COLUMNS } from 'utils/constants/defaultColumns'
import { sortItemsInSensitive } from 'utils/helperFunctions'

export const COLUMNS = [
  {
    Header: 'Complaint name',
    accessor: 'complaint',
    sortType: (prev, curr, columnId) =>
      sortItemsInSensitive(prev, curr, columnId),
  },
  ...DEFAULT_COLUMNS,
]
