import { makeStyles } from '@mui/styles'
import theme from 'config/theme'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles({
  darkIcon: {
    filter: 'opacity(0.9) drop-shadow(0 0 0 black)',
    color: theme.palette.info.dark,
  },
  card: {
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    borderRadius: '16px',
    color: theme.palette.info.darker,
    backgroundColor: theme.palette.info.lighter,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconWrapper: {
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.info.dark,
      0
    )} 0%, ${alpha(theme.palette.info.dark, 0.24)} 100%)`,
  },
})

export default useStyles
