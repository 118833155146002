import { useSelector } from 'react-redux'
import React from 'react'
import { loggedInUserToken } from 'store/CurrentUser'
import { IconButton, Tooltip } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import PropTypes from 'prop-types'
import { createPatientDataInCSV } from 'utils/helperFunctions'

const DownloadPatientsOfProvider = ({ id }) => {
  const token = useSelector((state) => loggedInUserToken(state))

  return (
    <Tooltip title="Download this provider's patients data in CSV">
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          createPatientDataInCSV(token, id)
        }}
      >
        <DownloadOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

DownloadPatientsOfProvider.propTypes = {
  id: PropTypes.string.isRequired,
}

export default DownloadPatientsOfProvider
