import React, { useState, useEffect } from 'react'
import useStyles from 'views/Provider/VisitHints/styles'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TextField,
  Card,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchVisitHints,
  updateVisitHints,
} from 'api-client/VisitHintsData/thunks'
import { constants } from 'utils/constants'
import { loggedInUserInfo } from 'store/CurrentUser'
import DisabledPage from 'views/DisabledPage'
import ConfirmDialogBox from 'components/ConfirmDialogBox'
import { HINTS_TYPE } from 'utils/constants/visitHintsType'
import { toast } from 'react-toastify'

const VisitHints = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.currentUserState)
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const { visitHints, providerVisitHints, status, isSubmitting } = useSelector(
    (state) => state.visitHintsDataState
  )

  const [hintText, setHintText] = useState(visitHints)
  const [hintsType, setHintsType] = useState(HINTS_TYPE.DEFAULT)

  useEffect(() => {
    dispatch(fetchVisitHints(token))
  }, [])

  useEffect(() => {
    if (hintsType === HINTS_TYPE.DEFAULT) setHintText(visitHints)
    if (hintsType === HINTS_TYPE.CUSTOMIZED) setHintText(providerVisitHints)
  }, [visitHints, providerVisitHints])

  const handleChangeText = (e) => {
    setHintText(e.target.value)
  }

  const handleChangeHints = (e) => {
    setHintsType(e.target.value)
    if (e.target.value === HINTS_TYPE.DEFAULT) {
      setHintText(visitHints)
    } else {
      setHintText(providerVisitHints)
    }
  }

  const handleSave = () => {
    if (hintText === providerVisitHints) {
      toast.warning('Nothing was changed.')
    } else dispatch(updateVisitHints([token, { visit_hints: hintText }]))
  }

  const [openEditTerms, setOpenEditTerms] = React.useState(false)

  const openEditTermsDialog = () => {
    setOpenEditTerms(true)
  }
  const closeEditTermsDialog = () => {
    setOpenEditTerms(false)
  }

  const handleEditConfirm = () => {
    setHintText(visitHints)
    setHintsType(HINTS_TYPE.CUSTOMIZED)
    setOpenEditTerms(false)
  }

  if (!userInfo.approved_on) return <DisabledPage />

  return (
    <>
      {status === constants.loading ? (
        <Box className={classes.loader}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box sx={{ m: '2rem 3rem' }}>
          <Box>
            <Typography className={classes.rootHeading}>Visit Hints</Typography>
            <Typography variant="body2" className={classes.subHeading}>
              Instruct patients to include necessary/required details in the
              message.
            </Typography>
          </Box>

          <Card className={classes.card} elevation={0}>
            <TextField
              multiline
              value={hintText}
              onChange={handleChangeText}
              rows={16}
              fullWidth
              sx={{ '& fieldset': { border: 'none' } }}
              InputProps={{
                classes: {
                  input: classes.bigFont,
                },
              }}
              disabled={hintsType === HINTS_TYPE.DEFAULT}
            />
          </Card>

          <Box className={classes.actionBox}>
            <FormControl>
              <RadioGroup
                className={classes.radioGroup}
                row
                value={hintsType}
                onChange={handleChangeHints}
              >
                <FormControlLabel
                  value="default"
                  control={<Radio />}
                  label="Default"
                />
                <FormControlLabel
                  value="customized"
                  control={<Radio />}
                  label="Customized"
                />
              </RadioGroup>
            </FormControl>

            <Box>
              {hintsType === HINTS_TYPE.CUSTOMIZED && (
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={openEditTermsDialog}
                    className={classes.copyButton}
                    disabled={isSubmitting}
                  >
                    Copy Default
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    className={classes.saveButton}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <ConfirmDialogBox
            dialogText="Are you sure you want to copy deafult visit hints?"
            handleConfirm={handleEditConfirm}
            closeModal={closeEditTermsDialog}
            dialogTitle="Edit Default Terms"
            open={openEditTerms}
          />
        </Box>
      )}
    </>
  )
}

export default VisitHints
