import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Button,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import FormikTextField from 'components/FormikTextField'
import { loggedInUserToken } from 'store/CurrentUser'
import { updateProvider } from 'api-client/ProvidersData/thunks'
import { getProvider } from 'store/ProvidersData'
import { hobbiesAndPersonalInterestsSchema } from 'schema/HobbiesAndPersonalInterestsForm'
import useStyles from 'views/Provider/Profile/Forms/HobbiesAndPersonalInterests/styles'

const HobbiesAndPersonalInterestsForm = ({ isOpenModal, handleCloseModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const token = useSelector((state) => loggedInUserToken(state))
  const provider = useSelector((state) => getProvider(state))
  const initialValues = { hobbies: provider.hobbies ?? '' }

  const handleSubmit = (values) => {
    dispatch(updateProvider([token, provider.id, values]))
    handleCloseModal()
  }

  return (
    <Dialog open={isOpenModal} onClose={handleCloseModal} fullWidth>
      <DialogTitle className={classes.dialogHeading}>
        Edit Hobbies And Personal Interests
      </DialogTitle>
      <DialogContent>
        <Box className={classes.form}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={hobbiesAndPersonalInterestsSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <FormikTextField
                      className={classes.field}
                      name="hobbies"
                      label="Hobbies And Personal Interests"
                      multiline
                      rows={5}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.buttonBox}>
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.cancelButton}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.saveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default HobbiesAndPersonalInterestsForm

HobbiesAndPersonalInterestsForm.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}
