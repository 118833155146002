import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'

import {
  fetchUserCount,
  fetchProviderStatusCount,
  fetchVisitStatusCount,
  fetchUserVisitsByYear,
} from 'api-client/DashboardData/thunks'

const initialState = {
  userCount: {},
  userCountSt: constants.idle,

  visitStatusCount: {},
  visitStatusCountSt: constants.idle,

  providerStatusCount: {},
  providerStatusCountSt: constants.idle,

  visitsByYear: {},
  visitsByYearSt: constants.idle,

  error: null,
}

const dashboardDataSlice = createSlice({
  name: 'medicationData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserCount.pending]: (state) => {
      state.userCountSt = constants.loading
    },
    [fetchUserCount.fulfilled]: (state, action) => {
      state.userCountSt = constants.succeeded
      state.userCount = action.payload.data
      state.error = null
    },
    [fetchUserCount.rejected]: (state, action) => {
      state.userCountSt = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
    },
    [fetchProviderStatusCount.pending]: (state) => {
      state.providerStatusCountSt = constants.loading
    },
    [fetchProviderStatusCount.fulfilled]: (state, action) => {
      state.providerStatusCountSt = constants.succeeded
      state.providerStatusCount = action.payload.data
      state.error = null
    },
    [fetchProviderStatusCount.rejected]: (state, action) => {
      state.providerStatusCountSt = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
    },
    [fetchVisitStatusCount.pending]: (state) => {
      state.visitStatusCountSt = constants.loading
    },
    [fetchVisitStatusCount.fulfilled]: (state, action) => {
      state.visitStatusCountSt = constants.succeeded
      state.visitStatusCount = action.payload.data
      state.error = null
    },
    [fetchVisitStatusCount.rejected]: (state, action) => {
      state.visitStatusCountSt = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
    },
    [fetchUserVisitsByYear.pending]: (state) => {
      state.visitsByYearSt = constants.loading
    },
    [fetchUserVisitsByYear.fulfilled]: (state, action) => {
      state.visitsByYearSt = constants.succeeded
      state.visitsByYear = action.payload.data
      state.error = null
    },
    [fetchUserVisitsByYear.rejected]: (state, action) => {
      state.visitsByYearSt = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },
  },
})

export const getUserCount = (state) => state.dashboardDataState.userCount
export const getUserCountSt = (state) => state.dashboardDataState.userCountSt

export const getVisitStatusCount = (state) =>
  state.dashboardDataState.visitStatusCount
export const getVisitStatusCountSt = (state) =>
  state.dashboardDataState.visitStatusCountSt

export const getProviderStatusCount = (state) =>
  state.dashboardDataState.providerStatusCount
export const getProviderStatusCountSt = (state) =>
  state.dashboardDataState.providerStatusCountSt

export const getVisitsByYear = (state) => state.dashboardDataState.visitsByYear
export const getgetVisitsByYearSt = (state) =>
  state.dashboardDataState.visitsByYearSt

export default dashboardDataSlice.reducer
