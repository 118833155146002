import React from 'react'
import useStyles from 'views/Provider/Settings/styles'
import { Box, Tab, IconButton, Tooltip } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import WorkingHours from 'views/Provider/WeeklySchedule'
import ScheduleIcon from '@mui/icons-material/Schedule'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { loggedInUser, loggedInUserInfo } from 'store/CurrentUser'
import { useSelector } from 'react-redux'
import DisabledPage from 'views/DisabledPage'
import ScheduleExceptions from 'views/Provider/ScheduleExceptions'
import { userRolesConstants } from 'utils/constants'
import { useHistory } from 'react-router-dom'

const Settings = () => {
  const classes = useStyles()
  const history = useHistory()

  const [settingsTab, setSettingsTab] = React.useState('1')
  const userInfo = useSelector((state) => loggedInUserInfo(state))
  const user = useSelector((state) => loggedInUser(state))

  const handleTabChange = (event, page) => {
    setSettingsTab(page)
  }

  if (user.role === userRolesConstants.provider && !userInfo.approved_on)
    return <DisabledPage />

  return (
    <Box sx={{ m: '0rem 3rem' }}>
      <TabContext value={settingsTab}>
        <Box className={classes.tabsHeader}>
          {user.role === userRolesConstants.admin && (
            <Box>
              <Tooltip title="Back" placement="left">
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <TabList onChange={handleTabChange} aria-label="Settings tabs">
            <Tab icon={<ScheduleIcon />} label="Working Hours" value="1" />
            <Tab icon={<MoreTimeIcon />} label="Schedule Exception" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0, mt: '1rem' }}>
          <WorkingHours />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0, mt: '1rem' }}>
          <ScheduleExceptions />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Settings
