import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  personIcon: { marginLeft: 'auto', marginRight: '10px', color: '#424242' },
  menuButton: { color: 'black', marginLeft: '-8px', marginRight: '20px' },
  sidebarIcon: { paddingLeft: '8px' },
  activeList: {
    padding: '25px 0 0',
  },
  disabledList: {
    padding: '25px 0 0',
    pointerEvents: 'none',
  },
  logoutList: {
    width: '100%',
    padding: '0',
    paddingLeft: '4px',
  },
  dashboardLink: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  welcomeUsername: {
    lineHeight: '1.6',
    marginRight: '20px',
    color: 'black !important',
  },
  logo: { paddingTop: 5 },
})

const drawerWidth = 235

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  backgroundColor: 'white',
}))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  borderRight: '1px solid rgba(0,0,0,0.1)',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))
