import React from 'react'
import useStyles from 'views/Provider/WeeklySchedule/Schedule/styles'
import { Grid, Typography, Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import FormikTextField from 'components/FormikTextField'
import { Day } from 'utils/constants'

const Schedule = ({ schedule, setFieldValue, index }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={1}>
        <Checkbox
          checked={schedule.is_open}
          onChange={(event) => {
            setFieldValue(`schedule.${index}.is_open`, event.target.checked)
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize={20} align="left">
          {Day[schedule.day]}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          align="left"
          color={schedule.is_open ? 'primary' : 'error'}
          fontSize={18}
        >
          {schedule.is_open ? 'Open' : 'Closed'}
        </Typography>
      </Grid>
      {schedule.is_open ? (
        <>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                openTo="hours"
                label="Open time"
                value={schedule.open_time}
                minutesStep={15}
                inputProps={{ readOnly: true }}
                onChange={(value) => {
                  setFieldValue(`schedule.${index}.open_time`, value)
                }}
                renderInput={(params) => (
                  <FormikTextField
                    className={classes.timePicker}
                    name={`schedule.${index}.open_time`}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'hh:mm a',
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.root,
                      },
                    }}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                openTo="hours"
                label="Close time"
                onChange={(value) => {
                  setFieldValue(`schedule.${index}.close_time`, value)
                }}
                inputProps={{ readOnly: true }}
                value={schedule.close_time}
                minutesStep={15}
                renderInput={(params) => (
                  <FormikTextField
                    name={`schedule.${index}.close_time`}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'hh:mm a',
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.root,
                      },
                    }}
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </>
      )}
    </>
  )
}

Schedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default Schedule
