import React, { useEffect } from 'react'

import { Paper, Box, CardHeader } from '@mui/material'
import Logo from 'assets/images/LOGO.png'
import { refreshStatus } from 'store/PasswordAPIs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const PasswordChangeSuccessPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(refreshStatus())
    setTimeout(() => {
      window.close()
      history.push('/')
    }, 5000)
  })
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginTop: 8, mr: 2 }}>
        <img src={Logo} width="200" alt="HealHT LOGO" />
      </Box>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          '& > :not(style)': {
            mt: 10,
            p: 3,
          },
        }}
      >
        <Paper variant="outlined">
          <CardHeader
            titleTypographyProps={{
              variant: 'overline',
              display: 'block',
              fontSize: 20,
              gutterBottom: true,
            }}
            title="Your password has been changed succesfully."
            subheader="This page will close after 5 seconds"
            subheaderTypographyProps={{ variant: 'overline', fontSize: 15 }}
          />
        </Paper>
      </Box>
    </>
  )
}

export default PasswordChangeSuccessPage
