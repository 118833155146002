import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const baseApiUrl = process.env.REACT_APP_BASE_API_URL

export const fetchUserRegistrationTokensById = createAsyncThunk(
  'registrationTokens/fetchUserRegistrationTokensById',
  async (requestObj, { rejectWithValue }) => {
    try {
      const { userId, token } = requestObj
      const response = await axios.get(`${baseApiUrl}/deviceTokens/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
