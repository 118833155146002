import { makeStyles } from '@mui/styles'
import Type from 'components/TextEditor/typeConstants'

const useStyles = (type) =>
  makeStyles({
    editor: {
      borderRadius: '8px',
      boxShadow: 'rgb(145 158 171 / 24%) 0px 1px 2px 0px',
      '& .ql-editor': {
        fontSize: '20px',
      },
      '& .ql-container': {
        borderTopLeftRadius: `${type === Type.DEFAULT ? '8px' : '0'}`,
        borderTopRightRadius: `${type === Type.DEFAULT ? '8px' : '0'}`,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        height: `${type === Type.DEFAULT ? '417px' : '375px'}`,
        padding: `${
          type === Type.DEFAULT ? '10px 0px 20px 0px' : '0px 0px 20px 0px'
        }`,
        color: `${type === Type.DEFAULT ? 'rgba(0,0,0,0.5)' : null}`,
        background: 'white',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      '& .ql-snow.ql-toolbar': {
        padding: '15px 10px',
        display: 'block',
        background: 'rgba(150,150,150,0.1)',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      '& .ql-bubble .ql-tooltip': {
        display: 'none',
      },
    },
  })

export default useStyles
