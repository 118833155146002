import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  userDetails: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem auto',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiCardContent-root:last-child': {
      paddingBottom: '16px',
    },
  },
  uploadProfile: {
    display: 'flex',
    flexDirection: 'column',
  },
  editIconBtn: {
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  avatar: {
    height: '150px',
    width: '150px',
  },
  profilePicActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    marginTop: '8px',
  },
  reminderBtn: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: '0.25rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'right',
    gap: '0.275rem',
  },
  actionBtn: {
    textTransform: 'none',
  },
  adminActions: {
    flex: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  activeStatus: {
    fontSize: '13px',
    color: 'green',
  },
  inActiveStatus: {
    fontSize: '13px',
    color: 'red',
  },
  switch: {
    margin: '0.25rem 0.25rem 0.25rem 0.75rem',
  },
  questionMark: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '0.25rem',
    fontWeight: 'bold',
    color: 'white',
    fontSize: '14px',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#2196f3',
  },
})

export default useStyles
