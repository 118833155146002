export const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: ' Email',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Phone',
    accessor: 'phone_number',
    disableSortBy: true,
  },
  {
    Header: 'Address',
    accessor: 'address',
    disableSortBy: true,
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
]
