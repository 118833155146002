import { object, string, number } from 'yup'

export const professionalDetailsSchema = object().shape({
  provider_type: string().label('Provider Type').required(),
  npi: number()
    .typeError('You must specify a number')
    .test('len', 'Npi must be 10 characters long', (val) =>
      val ? val.toString().length === 10 : false
    )
    .label('NPI')
    .required(),
  speciality: string().label('Speciality').required(),
  state: string().label('State').required(),
  dea: number()
    .typeError('You must specify a number')
    .nullable(true)
    .test('len', 'Dea must be 9 characters long', (val) =>
      val ? val.toString().length === 9 : true
    )
    .label('Dea'),
  license_number: number()
    .typeError('You must specify a number')
    .label('License Number')
    .required(),
  insurance: string()
    .label('Insurance')
    .oneOf(['Yes', 'No', 'None'])
    .required(),
})
