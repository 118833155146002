import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import {
  fetchCurrentUser,
  resetCurrentUserPassword,
  logoutUser,
} from 'api-client/CurrentUser/thunks'

const initialState = {
  user: null,
  userInfo: null,
  token: '',
  status: constants.idle,
  error: null,
}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    changeUserStatus: (state, newStatus) => {
      state.status = newStatus.payload
    },
  },
  extraReducers: {
    [fetchCurrentUser.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchCurrentUser.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.user = action.payload.data.user
      state.userInfo = action.payload.data[state.user.role]
      state.token = action.payload.data.token
      state.error = null
    },
    [fetchCurrentUser.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [resetCurrentUserPassword.pending]: (state) => {
      state.status = constants.loading
    },
    [resetCurrentUserPassword.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.error = null
      toast.success(action.payload.msg)
    },
    [resetCurrentUserPassword.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      toast.error(state.error)
    },

    [logoutUser.pending]: (state) => {
      state.user = null
      state.userInfo = null
      state.token = ''
      state.status = constants.idle
    },
  },
})

export const { changeUserStatus } = currentUserSlice.actions

export const loggedInUser = (state) => state.currentUserState.user
export const loggedInUserInfo = (state) => state.currentUserState.userInfo
export const loggedInUserToken = (state) => state.currentUserState.token

export default currentUserSlice.reducer
