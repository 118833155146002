import React from 'react'

import useStyles from 'components/NoRecordFound/styles'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const NoRecordFound = ({ msg }) => {
  const classes = useStyles()
  return (
    <Box className={classes.notFoundBox}>
      <Typography className={classes.nothingFound}>{msg}</Typography>
    </Box>
  )
}

export default NoRecordFound

NoRecordFound.propTypes = {
  msg: PropTypes.string,
}

NoRecordFound.defaultProps = {
  msg: 'No record found',
}
