import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { constants } from 'utils/constants'
import { logoutUser } from 'api-client/CurrentUser/thunks'

import {
  fetchPracticeTerms,
  updatePracticeTerms,
} from 'api-client/ProviderPracticeTermsData/thunks'

const initialState = {
  data: '',
  status: constants.idle,
  isSubmitting: false,
  error: null,
}

const providerPracticeTermsDataSlice = createSlice({
  name: 'providerPracticeTermsData',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPracticeTerms.pending]: (state) => {
      state.status = constants.loading
    },
    [fetchPracticeTerms.fulfilled]: (state, action) => {
      state.status = constants.succeeded
      state.data = action.payload.data.practice_terms
      state.error = null
    },
    [fetchPracticeTerms.rejected]: (state, action) => {
      state.status = constants.failed
      state.data = ''
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
        toast.error(state.error)
      } else {
        state.error = action.payload.message
        toast.error(state.error)
      }
    },
    [updatePracticeTerms.pending]: (state) => {
      state.isSubmitting = true
    },
    [updatePracticeTerms.fulfilled]: (state, action) => {
      state.data = action.meta.arg[2]['practice_terms']
      state.status = constants.succeeded
      state.error = null
      state.isSubmitting = false
      toast.success('Practice Terms Updated')
    },
    [updatePracticeTerms.rejected]: (state, action) => {
      state.status = constants.failed
      if (action.payload.response) {
        state.error = action.payload.response.data.msg
      } else {
        state.error = action.payload.message
      }
      state.isSubmitting = false
      toast.error(state.error)
    },
    [logoutUser.pending]: (state) => {
      state.data = ''
      state.status = constants.idle
      state.error = null
    },
  },
})

export default providerPracticeTermsDataSlice.reducer
